import { create } from 'zustand';

export interface BracketRound {
    team: string[];
    name: string[];
    stat: string[];
    image: string[];
}

type RoundName = 'round32' | 'round16' | 'round8' | 'round4' | 'round2' | 'round1';

interface BracketStore {
    round32: BracketRound;
    round16: BracketRound;
    round8: BracketRound;
    round4: BracketRound;
    round2: BracketRound;
    round1: BracketRound;
    setRound: (roundName: RoundName, data: BracketRound) => void;
    reset: () => void;
}

const useBracketStore = create<BracketStore>((set) => ({
    round32: {
        team: [],
        name: [],
        stat: [],
        image: []
    },
    round16: {
        team: [],
        name: [],
        stat: [],
        image: []
    },
    round8: {
        team: [],
        name: [],
        stat: [],
        image: []
    },
    round4: {
        team: [],
        name: [],
        stat: [],
        image: []
    },
    round2: {
        team: [],
        name: [],
        stat: [],
        image: []
    },
    round1: {
        team: [],
        name: [],
        stat: [],
        image: []
    },
    setRound: (roundName, data) => set((state) => ({ ...state, [roundName]: data })),
    reset: () => set(() => ({
        round32: {
            team: [],
            name: [],
            stat: [],
            image: []
        },
        round16: {
            team: [],
            name: [],
            stat: [],
            image: []
        },
        round8: {
            team: [],
            name: [],
            stat: [],
            image: []
        },
        round4: {
            team: [],
            name: [],
            stat: [],
            image: []
        },
        round2: {
            team: [],
            name: [],
            stat: [],
            image: []
        },
        round1: {
            team: [],
            name: [],
            stat: [],
            image: []
        }
    }))
}));

export default useBracketStore;
