import Select from 'react-select';
import {settingsStyles} from '../data/styles/settingsStyles';
import {ncaaAbbrsWConf, soccerAbbrsWConf} from '../data/teams/teamAbbrs';
import {settingsStylesDark} from '../data/styles/settingsStylesDark';
import usePreferencesStore from '../stores/preferences.store';
import useModalStore from '../stores/modal.store';
import useGlobalStore from '../stores/global.store';
import usePostStore from '../stores/posts/base-post.store';
import useUserStore from '../stores/user.store';
import useOptionsStore from '../stores/options.store';

export default function QuickAdd(props) {
	const {
		selectedConference, setSelectedConference,
		selectedConference2, setSelectedConference2
	} = useOptionsStore();
	const { darkMode } = usePreferencesStore();
	const { toggleModal } = useModalStore();
	const { teamAbbrs, setTeamAbbrs, setPendingDelete } = useGlobalStore();
	const { team, setTeam } = usePostStore();
	const { sport } = useUserStore();

	const filterConferences = (selected) => {
		let teams = ncaaAbbrsWConf.filter((t) => t.conference === selected.value);
		let newTeamAbbrs = teams.map((item) => {
			return item['team'];
		});
		setTeamAbbrs(newTeamAbbrs);
		setSelectedConference(selected);
	};

	const filterConferences2 = (selected) => {
		let teams = soccerAbbrsWConf.filter((t) => t.conference === selected.value);
		let newTeamAbbrs = teams.map((item) => {
			return item['team'];
		});
		setTeamAbbrs(newTeamAbbrs);
		setSelectedConference2(selected);
	};

	return (
		<div className='team-quick-add'>
			<h4>Quick Add Teams</h4>
			{sport === 'ncaa' && (
				<span className='horizontal-select'>
					<label className='conference-filter-label'>Conference</label>
					<Select
						value={selectedConference}
						onChange={(selected) => filterConferences(selected)}
						options={[
							{value: '2', label: 'ACC'},
							{value: '1', label: 'America East'},
							{value: '62', label: 'American'},
							{value: '46', label: 'ASUN'},
							{value: '3', label: 'Atlantic 10'},
							{value: '8', label: 'Big 12'},
							{value: '4', label: 'Big East'},
							{value: '5', label: 'Big Sky'},
							{value: '6', label: 'Big South'},
							{value: '7', label: 'Big Ten'},
							{value: '9', label: 'Big West'},
							{value: '10', label: 'Coastal'},
							{value: '11', label: 'C-USA'},
							{value: '45', label: 'Horizon'},
							{value: '100', label: 'Independent'},
							{value: '12', label: 'Ivy'},
							{value: '13', label: 'MAAC'},
							{value: '14', label: 'MAC'},
							{value: '16', label: 'MEAC'},
							{value: '44', label: 'Mtn West'},
							{value: '18', label: 'MVC'},
							{value: '19', label: 'Northeast'},
							{value: '20', label: 'Ohio Valley'},
							{value: '21', label: 'Pac 12'},
							{value: '22', label: 'Patriot'},
							{value: '23', label: 'SEC'},
							{value: '24', label: 'Southern'},
							{value: '25', label: 'Southland'},
							{value: '26', label: 'SWAC'},
							{value: '49', label: 'Summit'},
							{value: '27', label: 'Sun Belt'},
							{value: '30', label: 'WAC'},
							{value: '29', label: 'WCC'},
							{value: '1000', label: 'Conferences'},
						]}
						styles={darkMode ? settingsStylesDark : settingsStyles}
					/>
				</span>
			)}
			{sport === 'soccer' && (
				<span className='horizontal-select'>
					<label className='conference-filter-label'>League/Competition</label>
					<Select
						value={selectedConference2}
						onChange={(selected) => filterConferences2(selected)}
						options={[
							{value: 'ENG.1', label: 'Premier League'},
							{value: 'ESP.1', label: 'Spanish LaLiga'},
							{value: 'GER.1', label: 'German Bundesliga'},
							{value: 'ITA.1', label: 'Italian Serie A'},
							{value: 'USA.1', label: 'Major League Soccer'},
							{value: 'FRA.1', label: 'French Ligue 1'},
							{value: 'MEX.1', label: 'Mexican Liga BBVA MX'},
							{value: 'NED.1', label: 'Dutch Eredivisie'},
							{value: 'ENG.2', label: 'English League Championship'},
							{value: 'ENG.LEAGUE_CUP', label: 'English Carabao Cup'},
							{value: 'USA.NWSL', label: 'United States NWSL'},
							{value: 'UEFA.CHAMPIONS', label: 'UEFA Champions League'},
							{value: 'UEFA.EUROPA', label: 'UEFA Europa League'},
							{value: 'UEFA.EUROPA.CONF', label: 'UEFA Europa Conference League'},
							{value: 'UEFA.WCHAMPIONS', label: "UEFA Women's Champions League"},
							{value: 'UEFA.NATIONS', label: 'UEFA Nations League'},
							{value: 'CAF.NATIONS', label: 'Africa Cup of Nations'},
							{value: 'FIFA.WORLD', label: 'FIFA World Cup'},
							{value: 'FIFA.WORLDQ.AFC', label: 'FIFA World Cup Qualifying - AFC'},
							{value: 'FIFA.WORLDQ.CAF', label: 'FIFA World Cup Qualifying - CAF'},
							{value: 'FIFA.WORLDQ.CONCACAF', label: 'FIFA World Cup Qualifying - CONCACAF'},
							{value: 'FIFA.WORLDQ.CONMEBOL', label: 'FIFA World Cup Qualifying - CONMEBOL'},
							{value: 'FIFA.WORLDQ.UEFA', label: 'FIFA World Cup Qualifying - UEFA'},
						]}
						styles={darkMode ? settingsStylesDark : settingsStyles}
					/>
				</span>
			)}
			<div className='team-quick-add-logos'>
				{teamAbbrs.map((t, i) => {
					const teamObj = props.getTeamObj(t, sport);
					if (!teamObj) return null;
					return (
						<span
							key={i}
							onClick={() => setTeam([...team, t])}
							style={{background: `linear-gradient(to bottom, ${teamObj?.primary} 50%, ${teamObj?.secondary})`}}
							className={`team-quick-add-span` + (teamObj?._id && teamObj?.sport === 'custom' ? ' custom-team' : '')}>
							<span
								className='team-quick-add-logo'
								style={{backgroundImage: teamObj?.logo}}></span>
							{teamObj?._id && teamObj?.sport === 'custom' && (
								<button
									className='deleteButton delete-custom'
									onClick={(e) => {
										e.stopPropagation();
										setPendingDelete({id: teamObj._id, abbreviation: teamObj.abbreviation});
										toggleModal('delete-team');
									}}>
									X
								</button>
							)}
						</span>
					);
				})}
				{sport === 'custom' && (
					<span
						className='team-quick-add-span add-team'
						onClick={() => {
							toggleModal('add-team');
						}}>
						<i className='fa-solid fa-plus'></i>
					</span>
				)}
			</div>
		</div>
	);
}
