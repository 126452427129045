import axios from 'axios';
import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import uploadcare from 'uploadcare-widget/uploadcare.lang.en.min.js';
import BoxPreview from '../BoxPreview';
import { ChromePicker } from '../ChromePicker';
import useSettingsStore from '../../stores/settings.store';
import useModalStore from '../../stores/modal.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';

export default function AddTeamModal(props) {
	const [invalid, setInvalid] = useState([]);
	const [selectedColor, setSelectedColor] = useState('primary');
	const [formErrors, setFormErrors] = useState([]);
	const [newTeam, setNewTeam] = useState({
		primary: '#FF0000',
		secondary: '#000000',
	});
	const { settings } = useSettingsStore();
	const { toggleModal } = useModalStore();
	const { customTeams, setCustomTeams } = useUserStore();
	const { setTeamAbbrs } = useGlobalStore();

	useEffect(() => {
		setTimeout(() => {
			const invalidTeams = [].concat(...customTeams.map(Object.values));
			setInvalid(invalidTeams);
			if (document.getElementById('uploader')) {
				const widget = uploadcare.Widget('#uploader', {
					publicKey: '032723e2579e39ec0e1c',
					imagesOnly: true,
				});
				widget.onUploadComplete((fileInfo) => {
					let newTeamCopy = {...newTeam};
					newTeamCopy.logos = 'https://ucarecdn.com/' + fileInfo.uuid + '/';
					setNewTeam(newTeamCopy);
					let formErrorsCopy = [...formErrors];
					const index = formErrorsCopy.indexOf('url');
					if (index >= 0) {
						formErrorsCopy.splice(index, 1);
						setFormErrors(formErrorsCopy);
					}
				});
			}
		});
	}, [formErrors, newTeam, props]);

	const saveTeam = (e) => {
		e.preventDefault();
		let saveObj = newTeam;
		saveObj.email = props.user.email;
		saveObj.user_id = props.user._id;
		saveObj.logos = ['url(' + saveObj.logos + ')'];
		saveObj.abbreviation = saveObj.abbreviation.toUpperCase();
		axios.post(`/custom/saveCustomTeam`, saveObj).then((response) => {
			if (response.status === 200) {
				toast.success('Team Saved!', {autoClose: 3000});
			}
			setNewTeam({
				primary: '#FF0000',
				secondary: '#000000',
			});
			setSelectedColor('primary');
			const returnTeam = response.data[0];
			returnTeam.sport = 'custom';
			let newCustomTeams = [...customTeams, returnTeam];
			setCustomTeams(newCustomTeams);
			let teamAbbrsArr = newCustomTeams.map((team) => {
				return team['abbreviation'];
			});
			setTeamAbbrs(teamAbbrsArr);
			toggleModal('add-team');
		});
	};

	const getTeamObj = () => {
		let returnTeam = {...newTeam};
		returnTeam.gradientPerc = settings.gradientPerc;
		returnTeam.gradientPerc1 = settings.gradientPerc1;
		returnTeam.gradientPerc2 = settings.gradientPerc2;
		returnTeam.direction = settings.direction;
		returnTeam.logo = 'url(' + newTeam.logos + ')';
		return returnTeam;
	};

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => toggleModal('add-team')}
			shouldCloseOnOverlayClick={true}
			shouldCloseOnEsc={true}>
			<div className='modal add-team-modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Add Custom Team</h5>
							<span
								className='close-modal'
								onClick={() => toggleModal('add-team')}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<h5 style={{marginBottom: '0px'}}>Preview</h5>
							<BoxPreview
								teamObj={getTeamObj()}
							/>
							<form
								onSubmit={saveTeam}
								style={{marginTop: '8px'}}>
								<h5>Team Abbreviation</h5>
								<textarea
									autoFocus
									className='form-control'
									required
									rows='1'
									cols='45'
									onChange={(e) => {
										if (invalid.includes(e.target.value.toUpperCase()) && !formErrors.includes('abbreviation')) {
											let formErrorsCopy = [...formErrors];
											formErrorsCopy.push('abbreviation');
											setFormErrors(formErrorsCopy);
										} else if (!invalid.includes(e.target.value.toUpperCase()) && formErrors.includes('abbreviation')) {
											let formErrorsCopy = [...formErrors];
											const index = formErrorsCopy.indexOf('abbreviation');
											formErrorsCopy.splice(index, 1);
											setFormErrors(formErrorsCopy);
										}
										let newTeamCopy = {...newTeam};
										newTeamCopy.abbreviation = e.target.value;
										setNewTeam(newTeamCopy);
									}}
								/>
								{formErrors.includes('abbreviation') && <span className='form-error'>Cannot use an existing abbreviation</span>}
								<h5>
									Logo URL{' '}
									<Tippy content={<span>Link to team image with transparent background from ESPN or Wikipedia (preferably at least 500px x 500px)</span>}>
									<i className='fa fa-question-circle'></i>
									</Tippy>
								</h5>
								<textarea
									className='form-control'
									required
									rows='1'
									cols='45'
									value={newTeam.logos}
									onChange={(e) => {
										if (/espn|wikipedia|ucarecdn/.test(e.target.value)) {
											let formErrorsCopy = [...formErrors];
											const index = formErrorsCopy.indexOf('url');
											if (index >= 0) {
												formErrorsCopy.splice(index, 1);
												setFormErrors(formErrorsCopy);
											}
										} else {
											let formErrorsCopy = [...formErrors];
											if (!formErrorsCopy.includes('url')) {
												formErrorsCopy.push('url');
												setFormErrors(formErrorsCopy);
											}
										}
										let newTeamCopy = {...newTeam};
										newTeamCopy.logos = e.target.value;
										setNewTeam(newTeamCopy);
									}}
								/>
								{formErrors.includes('url') && <span className='form-error'>Please use a logo from ESPN or Wikipedia or upload one below</span>}
								<h5>Or Upload a Logo</h5>
								<input
									id='uploader'
									type='hidden'
									data-tabs='file'
								/>
								<span className='edit-spacer2'></span>
								<h5>Team Colors</h5>
								<span className='setting-radio'>
									<label>
										<input
											type='radio'
											name='colorradio'
											checked={selectedColor === 'primary'}
											value='primary'
											onChange={() => setSelectedColor('primary')}
										/>
										Primary
									</label>
									<label>
										<input
											type='radio'
											name='colorradio'
											checked={selectedColor === 'secondary'}
											value='secondary'
											onChange={() => setSelectedColor('secondary')}
										/>
										Secondary
									</label>
								</span>
								<ChromePicker
									color={newTeam[selectedColor]}
									onChange={(color) => {
										let newTeamCopy = {...newTeam};
										newTeamCopy[selectedColor] = color;
										setNewTeam(newTeamCopy);
									}}
								/>
								<span className='edit-spacer'></span>
								<button
									type='submit'
									disabled={formErrors.length}
									className='newButton'>
									Save Team
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
