export const posts = [
	// list type
	// passing yards (10 - nfl)
	{
		"_id": "64f7c72e1c39bce7ecfa6270",
		"thumbnail": "/template1.jpeg",
		"title": "Passing Yards",
		"subtitle": "2021 Season",
		"team": [
			"TAM",
			"LAC",
			"LAR",
			"KAN",
			"LVR",
			"CIN",
			"DAL",
			"BUF",
			"MIN",
			"GNB"
		],
		"name": [
			"Tom Brady",
			"Justin Herbert",
			"Matthew Stafford",
			"Patrick Mahomes",
			"Derek Carr",
			"Joe Burrow",
			"Dak Prescott",
			"Josh Allen",
			"Kirk Cousins",
			"Aaron Rodgers"
		],
		"stat": [
			"5316",
			"5014",
			"4886",
			"4839",
			"4804",
			"4611",
			"4449",
			"4407",
			"4221",
			"4115"
		],
		"points": [],
		"image": [
			"2330",
			"4038941",
			"12483",
			"3139477",
			"16757",
			"3915511",
			"2577417",
			"3918298",
			"14880",
			"8439"
		],
		"sport": "nfl",
		"settings": {
			"type": "post",
			"headerSize": 20,
			"gradientPerc": 61,
			"gradientPerc1": 37,
			"gradientPerc2": 100,
			"direction": "to left",
			"nameSize": 18,
			"statSize": 18,
			"logoOrder": 4,
			"logoOpacity": 100,
			"logoSize": 59,
			"headerMargin": 2,
			"headerSpacing": 0,
			"nameMargin": -1,
			"headerFont": "adidas",
			"nameFont": "uniform bold",
			"statFont": "bebas neue",
			"headerColor": "#54cc8f",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 132,
			"headerSizeNews": 20,
			"headerFontNews": "graduate",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 94,
			"gradientPercNews": 70,
			"gradientPerc1News": 53,
			"gradientPerc2News": 93,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 360,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"borderRadius": 14,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": false,
			"logoShadow": 3,
			"nameMargin2": 10,
			"postWidth": 814.44,
			"postHeight": 814.44,
			"postWidthNews": 814.44,
			"postHeightNews": 814.44,
			"tierFont": "metropolis",
			"tierColor": "#ffffff",
			"tierSize": 66,
			"duplicateLogoSize": 514,
			"duplicateLogoPosition": 61,
			"duplicateLogoOpacity": 8,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "league gothic",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "uppercase",
			"backgroundColor": "gradient",
			"backgroundGradient1": "#044571",
			"backgroundGradient2": "#000000",
			"backgroundGradientPerc": 35,
			"backgroundGradientPerc1": 72,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to top",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 21,
			"rankFont": "league gothic",
			"rankFontColor": "#ffffff",
			"rankMargin": 3,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "lemon milk light",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 3,
			"chartLogoSize": 90,
			"playerMargin": 0,
			"dark": false,
			"texture": "grainy",
			"textureSize": 1,
			"textureOpacity": 19,
			"rankBackground": "primary",
			"boxMargin": 12,
			"teamBackground": "gradient",
			"teamColor": "#c99e5c",
			"actualHeaderColor": "#00000000",
			"rankWidth": 35,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 0,
			"headerBorderSize": 0,
			"darker": false,
			"headerBorderColor": "#ffffff",
			"lighterLogos": false,
			"darkMode": null,
			"boxImageBackground": "none"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-09-06T00:26:22.589Z",
		"ranks": [
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10"
		]
	},
	// list type
	// standings (10 - f1)
	{
		"_id": "64ea81ad56eb88fdaef9d6d1",
		"thumbnail": "/template19.jpeg",
		"title": "2022 Team Standings",
		"subtitle": "After Italian Grand Prix",
		"team": [
			"RED",
			"FER",
			"MER",
			"ALPN",
			"MCL",
			"ALF",
			"HAAS",
			"ALPH",
			"AST",
			"WIL"
		],
		"name": [
			"Red Bull Racing",
			"Ferrari",
			"Mercedes-AMG",
			"Alpine",
			"McLaren",
			"Alfa Romeo",
			"Haas",
			"AlphaTauri",
			"Aston Martin",
			"Williams"
		],
		"stat": [
			"545",
			"406",
			"371",
			"125",
			"107",
			"52",
			"34",
			"33",
			"25",
			"6"
		],
		"points": [],
		"image": [],
		"sport": "f1",
		"settings": {
			"type": "post",
			"headerSize": 20,
			"gradientPerc": 46,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to right",
			"nameSize": 23,
			"statSize": 21,
			"logoOrder": 2,
			"logoOpacity": 83,
			"logoSize": 56,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": -5,
			"headerFont": "ostrich sans",
			"nameFont": "exo",
			"statFont": "varsity",
			"headerColor": "#2a1653",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 1,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": true,
			"logoShadow": 5,
			"nameMargin2": 10,
			"postWidth": 574.86,
			"postHeight": 574.86,
			"postWidthNews": 574.86,
			"postHeightNews": 574.86,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 496,
			"duplicateLogoPosition": 195,
			"duplicateLogoOpacity": 12,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "uppercase",
			"statTransform": "uppercase",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "ostrich sans",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "gradient",
			"backgroundGradient1": "#579d74",
			"backgroundGradient2": "#2775a6",
			"backgroundGradientPerc": 31,
			"backgroundGradientPerc1": 96,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to top",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 4,
			"textureOpacity": 11,
			"rankBackground": "#000000",
			"boxMargin": 4,
			"teamBackground": "gradient",
			"teamColor": "#752f67",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 0,
			"headerBorderColor": "#ffffff"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-08-26T22:50:21.276Z",
		"ranks": []
	},
	// list type
	// heisman odds (5 - ncaa)
	{
		"_id": "665f6b08ec2304142a6d7377",
		"thumbnail": "/template22.jpeg",
		"title": "Heisman Odds",
		"subtitle": "2024 Season",
		"team": [
			"uga",
			"tex",
			"ore",
			"osu",
			"tenn"
		],
		"name": [
			"Carson Beck",
			"Quinn Ewers",
			"Dillon Gabriel",
			"Will Howard",
			"Nico Iamaleava"
		],
		"stat": [],
		"points": [
			"+750",
			"+750",
			"+1000",
			"+1300",
			"+1500"
		],
		"image": [
			"4430841",
			"4889929",
			"4427238",
			"4429955",
			"4870799"
		],
		"sport": "ncaa",
		"settings": {
			"type": "post",
			"headerSize": 22,
			"gradientPerc": 17,
			"gradientPerc1": 7,
			"gradientPerc2": 65,
			"direction": "to right",
			"nameSize": 48,
			"statSize": 12,
			"logoOrder": -2,
			"logoOpacity": 87,
			"logoSize": 30,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "new athletic",
			"nameFont": "uniform bold",
			"statFont": "league gothic",
			"headerColor": "#32f5f2",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": -2,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 1,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": -20,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "topbottom",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 29,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 10,
			"nameMargin2": 10,
			"postWidth": 779,
			"postHeight": 779,
			"postWidthNews": 779,
			"postHeightNews": 779,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 800,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 11,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "uppercase",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background22.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291",
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#6aa626",
			"backgroundGradient2": "#94631d",
			"backgroundGradientPerc": 25,
			"backgroundGradientPerc1": 77,
			"backgroundGradientPerc2": 98,
			"backgroundGradientDirection": "to top",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 24,
			"pointsFont": "octin",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1.5,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": "grainy",
			"textureSize": 3,
			"textureOpacity": 9,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 14,
			"teamBackground": "primary",
			"teamColor": "#06ebf0",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 0,
			"headerBorderSize": 0,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 0,
			"boxGlowColor": "#ffffff",
			"lineHeightNews": 0
		},
		"teamoverrides": [
			{
				"_id": "64c5e1f8bdf338a63e5f9660",
				"abbreviation": "TEX",
				"sport": "ncaa",
				"direction": "to left",
				"gradientPerc": 32,
				"gradientPerc1": 0,
				"gradientPerc2": 100,
				"isGlobal": true,
				"logo": "url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/251.png)",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/ncaa/500/251.png)",
					"url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/251.png)",
					"url(https://cdn.bleacherreport.net/images/team_logos/328x328/texas_longhorns_football.png)"
				],
				"primary": "#333f48",
				"secondary": "#bf5700"
			},
			{
				"_id": "64f3ef146dff16719a4d0dc8",
				"abbreviation": "OSU",
				"sport": "ncaa",
				"direction": "to left",
				"gradientPerc": 49,
				"gradientPerc1": 0,
				"gradientPerc2": 100,
				"isGlobal": true,
				"logo": "url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/194.png)",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/ncaa/500/194.png)",
					"url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/194.png)"
				],
				"primary": "#bb0000",
				"secondary": "#666666"
			}
		],
		"draggables": [],
		"floatingTexts": [],
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"date": "2024-06-04T19:29:12.269Z",
		"savedTitle": "",
		"ranks": []
	},
	// list type (with image)
	// madden ratings (10 - nfl)
	{
		"_id": "64f643f993b1bcdf0b67abbc",
		"thumbnail": "/template2.png",
		"title": "TOP 10 PLAYERS",
		"subtitle": "madden 24",
		"team": [
			"lar",
			"min",
			"kc",
			"kc",
			"dal",
			"phi",
			"cle",
			"sf",
			"sf",
			"mia"
		],
		"name": [
			"Aaron Donald",
			"Justin Jefferson",
			"Patrick Mahomes",
			"Travis Kelce",
			"Zack Martin",
			"Lane Johnson",
			"Myles Garrett",
			"Nick Bosa",
			"Trent Williams",
			"Tyreek Hill"
		],
		"stat": [
			"99",
			"99",
			"99",
			"99",
			"99",
			"98",
			"98",
			"98",
			"98",
			"98"
		],
		"points": [],
		"image": [],
		"sport": "nfl",
		"settings": {
			"type": "postimage",
			"headerSize": 20,
			"gradientPerc": 16,
			"gradientPerc1": 10,
			"gradientPerc2": 66,
			"direction": "to top",
			"nameSize": 22,
			"statSize": 26,
			"logoOrder": -2,
			"logoOpacity": 100,
			"logoSize": 35,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "adidas",
			"nameFont": "adidas",
			"statFont": "bebas neue",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 1,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 132,
			"headerSizeNews": 20,
			"headerFontNews": "graduate",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 94,
			"gradientPercNews": 70,
			"gradientPerc1News": 53,
			"gradientPerc2News": 93,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 360,
			"orientation": "rightleft",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"borderRadius": 8,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": false,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 691.02,
			"postHeight": 691.02,
			"postWidthNews": 691.02,
			"postHeightNews": 691.02,
			"tierFont": "metropolis",
			"tierColor": "#ffffff",
			"tierSize": 66,
			"duplicateLogoSize": 631,
			"duplicateLogoPosition": 6,
			"duplicateLogoOpacity": 8,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "league gothic",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "uppercase",
			"backgroundColor": "gradient",
			"backgroundGradient1": "#a406c3",
			"backgroundGradient2": "#3523df",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 11,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 42,
			"rankFont": "roboto bold",
			"rankFontColor": "#ffffff",
			"rankMargin": 3,
			"rankSpacing": -3,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "lemon milk light",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 3,
			"chartLogoSize": 90,
			"playerMargin": 0,
			"lighterLogos": false,
			"texture": null,
			"textureSize": 4,
			"textureOpacity": 14,
			"rankBackground": "#520068",
			"boxMargin": 15,
			"teamBackground": "same",
			"teamColor": "#9d00d7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 49,
			"rankMargin2": 6,
			"boxRankOutlineSize": 0,
			"borderSize": 0,
			"headerBorderSize": 0,
			"darkMode": "dark1",
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none"
		},
		"teamoverrides": [
			{
				"abbreviation": "MIL",
				"full": "BUCKS",
				"primary": "#00471b",
				"secondary": "#00471b",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/mil.png)",
					"url(https://cdn.bleacherreport.net/images/team_logos/328x328/milwaukee_bucks.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/mil.png)",
				"sport": "nba",
				"isGlobal": false
			},
			{
				"abbreviation": "SA",
				"abbreviation2": "SAS",
				"full": "SPURS",
				"primary": "#565656",
				"secondary": "#000000",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
				"sport": "nba",
				"isGlobal": false
			}
		],
		"teamsplits": [],
		"draggables": [
			{
				"boxWidth": 341,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"date": "2023-09-04T20:54:17.868Z",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccusatsi_19269368.jpg?alt=media&token=0f6b0b04-000f-4f41-bbd5-c3236242021a"
	},
	// list type
	// team home runs (30 - mlb)
	{
		"_id": "64ea772e86646e1c37c23448",
		"thumbnail": "/template30.jpeg",
		"title": "TEAM HOME RUNS",
		"subtitle": "Through 8/26",
		"team": [
			"ATL",
			"LAD",
			"TB",
			"LAA",
			"MIN",
			"NYY",
			"TEX",
			"STL",
			"HOU",
			"NYM",
			"SD",
			"SEA",
			"PHI",
			"CHC",
			"BAL",
			"CIN",
			"BOS",
			"TOR",
			"CHW",
			"ARI",
			"SF",
			"MIL",
			"OAK",
			"DET",
			"COL",
			"PIT",
			"KC",
			"MIA",
			"WSH",
			"CLE"
		],
		"name": [
			"Braves",
			"Dodgers",
			"Rays",
			"Angels",
			"Twins",
			"Yankees",
			"Rangers",
			"Cardinals",
			"Astros",
			"Mets",
			"Padres",
			"Mariners",
			"Phillies",
			"Cubs",
			"Orioles",
			"Reds",
			"Red Sox",
			"Blue Jays",
			"White Sox",
			"Diamondbacks",
			"Giants",
			"Brewers",
			"Athletics",
			"Tigers",
			"Rockies",
			"Pirates",
			"Royals",
			"Marlins",
			"Nationals",
			"Guardians"
		],
		"stat": [
			"241",
			"197",
			"190",
			"188",
			"180",
			"178",
			"178",
			"175",
			"167",
			"166",
			"163",
			"161",
			"158",
			"155",
			"152",
			"152",
			"151",
			"150",
			"142",
			"141",
			"140",
			"135",
			"134",
			"131",
			"130",
			"126",
			"125",
			"123",
			"119",
			"96"
		],
		"points": [],
		"image": [],
		"sport": "mlb",
		"settings": {
			"direction": "to right",
			"headerSize": 20,
			"gradientPerc": 0,
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": -2,
			"logoOpacity": 98,
			"logoSize": 51,
			"duplicateLogoSize": 364,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "league gothic",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#6cb96b",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 82,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"type": "post",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "leftright",
			"borderRadius": 13,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 574.86,
			"postHeight": 574.86,
			"postWidthNews": 574.86,
			"postHeightNews": 574.86,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"dark": true,
			"texture": "polka",
			"textureSize": 1,
			"textureOpacity": 30,
			"orientation": "topbottom",
			"orientationMatchup": "topbottom",
			"duplicateLogoPosition": 23,
			"duplicateLogoOpacity": 9,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background11.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291",
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleFont": "roboto bold",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "uppercase",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "gradient",
			"backgroundGradient1": "#693d27",
			"backgroundGradient2": "#6c0314",
			"backgroundGradientPerc": 35,
			"backgroundGradientPerc1": 76,
			"backgroundGradientPerc2": 98,
			"backgroundGradientDirection": "to right",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"rankBackground": "#000000",
			"boxMargin": 11,
			"teamBackground": "gradient",
			"teamColor": "#7413e8",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 0,
			"headerBorderColor": "#ffffff",
			"darker": true,
			"lighterLogos": true,
			"darkMode": "dark2"
		},
		"teamoverrides": [],
		"teamsplits": [
			{
				"team": "SF/CAR",
				"splitDirection": "cliptopleft/clipbottomright"
			}
		],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-08-26T22:05:34.102Z",
		"ranks": []
	},
	// list type
	// draft order (18 - nfl)
	{
		"_id": "659b8320ea97310b43f4d2aa",
		"thumbnail": "/template3.jpeg",
		"title": "Draft Order",
		"subtitle": "2024",
		"team": [
			"Chi",
			"Commanders",
			"Patriots",
			"Cardinals",
			"Chargers",
			"Giants",
			"Titans",
			"Falcons",
			"Bears",
			"Jets",
			"Vikings",
			"Broncos",
			"Raiders",
			"Saints",
			"Colts",
			"Seahawks",
			"Jaguars",
			"Bengals"
		],
		"name": [
			"1. Bears",
			"2. Commanders",
			"3. Patriots",
			"4. Cardinals",
			"5. Chargers",
			"6. Giants",
			"7. Titans",
			"8. Falcons",
			"9. Bears",
			"10. Jets",
			"11. Vikings",
			"12. Broncos",
			"13. Raiders",
			"14. Saints",
			"15. Colts",
			"16. Seahawks",
			"17. Jaguars",
			"18. Bengals"
		],
		"stat": [],
		"points": [],
		"image": [],
		"sport": "nfl",
		"settings": {
			"direction": "to right",
			"headerSize": 19,
			"gradientPerc": 39,
			"nameSize": 18,
			"statSize": 19,
			"logoOrder": -2,
			"logoOpacity": 91,
			"logoSize": 50,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto bold",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": -1,
			"statSpacing": -2,
			"statShadow": 0,
			"type": "post",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "leftright",
			"borderRadius": 23,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 1,
			"nameMargin2": 10,
			"postWidth": 430,
			"postHeight": 430,
			"postWidthNews": 430,
			"postHeightNews": 430,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 213,
			"duplicateLogoPosition": 79,
			"duplicateLogoOpacity": 5,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"orientation": "leftright",
			"orientationHighlight": "leftright",
			"orientationMatchup": "topbottom",
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 12,
			"gradientPerc2": 68,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "none",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": 2,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 28,
			"rankFont": "roboto bold",
			"rankFontColor": "#ffffff",
			"rankMargin": 5,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark1",
			"texture": "grainy",
			"textureSize": 2,
			"textureOpacity": 19,
			"matchupRankBackground": "#00000000",
			"rankBackground": "primary",
			"boxMargin": 16,
			"teamBackground": "primary",
			"teamColor": "#72e791",
			"actualHeaderColor": "#00000000",
			"rankWidth": 59,
			"rankMargin2": -6,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"pointsMargin2": 0,
			"descriptionSize": 25,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionTransform": "none",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsTransform": "none",
			"labelsBackground": "#000000"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"date": "2024-01-08T05:07:44.501Z",
		"savedTitle": "",
		"ranks": []
	},
	// score type
	// scoreboard (nba)
	{
		"title": "NBA SCOREBOARD",
		"subtitle": "04/14/2024",
		"team": [
			"WSH",
			"BOS",
			"CHA",
			"CLE",
			"ATL",
			"IND",
			"TOR",
			"MIA",
			"CHI",
			"NY",
			"MIL",
			"ORL",
			"BKN",
			"PHI",
			"DEN",
			"MEM",
			"PHX",
			"MIN",
			"LAL",
			"NO",
			"DAL",
			"OKC",
			"DET",
			"SA",
			"UTAH",
			"GS",
			"HOU",
			"LAC",
			"POR",
			"SAC"
		],
		"name": [
			"122",
			"132",
			"120",
			"110",
			"115",
			"157",
			"103",
			"118",
			"119",
			"120",
			"88",
			"113",
			"86",
			"107",
			"126",
			"111",
			"125",
			"106",
			"124",
			"108",
			"86",
			"135",
			"95",
			"123",
			"116",
			"123",
			"116",
			"105",
			"82",
			"121"
		],
		"stat": [],
		"points": [],
		"image": [],
		"sport": "nba",
		"thumbnail": "/template16.jpeg",
		"settings": {
			"type": "scores",
			"headerSize": 20,
			"gradientPerc": 55,
			"gradientPerc1": 32,
			"gradientPerc2": 84,
			"direction": "to left",
			"nameSize": 22,
			"statSize": 20,
			"logoOrder": 1,
			"logoOpacity": 79,
			"logoSize": 55,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "exo",
			"nameFont": "anton",
			"statFont": "roboto condensed",
			"headerColor": "#52e46c",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 82,
			"statMargin": 2,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -1,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 19,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "rightleft",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 25,
			"scoreMargin": 9,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 779,
			"postHeight": 779,
			"postWidthNews": 779,
			"postHeightNews": 779,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 340,
			"duplicateLogoPosition": 252,
			"duplicateLogoOpacity": 6,
			"gridMargin": 1,
			"logoFlip": false,
			"scoreMinHeight": 93,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background17.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291",
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "ostrich sans",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 1,
			"subtitleTransform": "uppercase",
			"backgroundColor": "gradient",
			"backgroundGradient1": "#576ac6",
			"backgroundGradient2": "#39d728",
			"backgroundGradientPerc": 14,
			"backgroundGradientPerc1": 83,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to left",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 1,
			"textureOpacity": 23,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 10,
			"teamBackground": "primary",
			"teamColor": "#c26bd8",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 0,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 0,
			"boxGlowColor": "#ffffff",
			"lineHeightNews": 0
		},
		"teamoverrides": [],
		"draggables": [],
		"floatingTexts": [],
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"date": "2024-06-04T22:10:31.372Z",
		"savedTitle": "",
		"ranks": [],
		"_id": "665f90d888945da491e1be81"
	},
	// score type
	// scoreboard (soccer)
	{
		"_id": "64ea7fa056eb88fdaef9d6cf",
		"thumbnail": "/template28.jpeg",
		"title": "Scoreboard",
		"subtitle": "6/1",
		"team": [
			"ARS",
			"AVL",
			"BHA",
			"BRE",
			"BUR",
			"CHE",
			"CRY",
			"EVE",
			"LEE",
			"LEI",
			"LIV",
			"MAN",
			"MNC",
			"NEW",
			"NOR",
			"SOU",
			"TOT",
			"WAT",
			"WHU",
			"WOL"
		],
		"name": [
			"2",
			"0",
			"1",
			"3",
			"1",
			"0",
			"2",
			"1",
			"0",
			"3",
			"2",
			"1",
			"0",
			"1",
			"1",
			"2",
			"4",
			"1",
			"2",
			"0"
		],
		"stat": [],
		"points": [],
		"image": [],
		"sport": "soccer",
		"settings": {
			"direction": "to right",
			"headerSize": 20,
			"gradientPerc": 54,
			"nameSize": 30,
			"statSize": 20,
			"logoOrder": 0,
			"logoOpacity": 88,
			"logoSize": 81,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 82,
			"statMargin": 2,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -1,
			"statShadow": 0,
			"postWidth": 574.86,
			"postHeight": 574.86,
			"type": "scores",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 19,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "leftright",
			"borderRadius": 9,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 3,
			"lighterLogos": false,
			"orientation": "leftright",
			"orientationMatchup": "topbottom",
			"paneSplitMatchup": [
				80,
				20
			],
			"nameMargin2": 10,
			"postWidthNews": 574.86,
			"postHeightNews": 574.86,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 200,
			"duplicateLogoPosition": 22,
			"duplicateLogoOpacity": 12,
			"gridMargin": 1,
			"logoFlip": false,
			"scoreMinHeight": 102,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "none",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"darkMode": null,
			"texture": "grainy",
			"textureSize": 3,
			"textureOpacity": 8,
			"rankBackground": "#000000",
			"boxMargin": 11,
			"teamBackground": "gradient",
			"teamColor": "#958a88",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-08-26T22:41:36.851Z",
		"ranks": []
	},
	// matchup type
	// score (nhl)
	{
		"_id": "64ea80f256eb88fdaef9d6d0",
		"title": "Stanley Cup Finals",
		"thumbnail": "/template29.jpeg",
		"subtitle": "",
		"team": [
			"Avalanche",
			"Lightning"
		],
		"name": [
			'2',
			'1'
		],
		"stat": [
			"FINAL"
		],
		"points": [],
		"image": [],
		"sport": "nhl",
		"settings": {
			"direction": "to bottom",
			"headerSize": 20,
			"gradientPerc": 73,
			"nameSize": 40,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 86,
			"logoSize": 159,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 7,
			"headerFont": "roboto bold",
			"nameFont": "adidas",
			"statFont": "hemi",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 82,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 1,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"postWidth": 574.86,
			"postHeight": 574.86,
			"type": "matchupimage",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 132,
			"headerSizeNews": 19,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "leftright",
			"borderRadius": 3,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 1,
			"lighterLogos": false,
			"orientation": "leftright",
			"orientationMatchup": "topbottom",
			"paneSplitMatchup": [
				80,
				20
			],
			"nameMargin2": 46,
			"postWidthNews": 574.86,
			"postHeightNews": 574.86,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 277,
			"duplicateLogoPosition": 113,
			"duplicateLogoOpacity": 12,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 102,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "uppercase",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 51,
			"gradientPerc2": 94,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "none",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"darkMode": null,
			"texture": "grainy",
			"textureSize": 6,
			"textureOpacity": 9,
			"rankBackground": "#000000",
			"boxMargin": 5,
			"teamBackground": "gradient",
			"teamColor": "#2f66a7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [
			{
				"boxWidth": 575,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"date": "2023-08-26T22:47:14.089Z",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccdownload.jpeg?alt=media&token=63766287-c7f0-420f-b766-67c9302c3215"
	},
	// matchup type
	// gameday (nfl)
	{
		"_id": "65569c3ddc01f7c2d03db641",
		"thumbnail": "/template4.jpeg",
		"title": "Gameday",
		"subtitle": "",
		"team": [
			"min",
			"den"
		],
		"name": [
			""
		],
		"stat": [
			"GAMEDAY"
		],
		"points": [],
		"image": [],
		"sport": "nfl",
		"settings": {
			"type": "matchupimage",
			"headerSize": 20,
			"gradientPerc": 12,
			"gradientPerc1": 9,
			"gradientPerc2": 47,
			"direction": "to bottom",
			"nameSize": 24,
			"statSize": 35,
			"logoOrder": 1,
			"logoOpacity": 94,
			"logoSize": 200,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "roboto condensed",
			"nameFont": "roboto condensed",
			"statFont": "league spartan",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": -1,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 2,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": -3,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 132,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"borderRadius": 3,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 1,
			"nameMargin2": 10,
			"postWidth": 735.24,
			"postHeight": 735.24,
			"postWidthNews": 735.24,
			"postHeightNews": 735.24,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 524,
			"duplicateLogoPosition": 105,
			"duplicateLogoOpacity": 15,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark3",
			"texture": "grainy",
			"textureSize": 3,
			"textureOpacity": 14,
			"rankBackground": "#000000",
			"boxMargin": 14,
			"teamBackground": "primary",
			"teamColor": "#3568d8",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-2",
				"label": "Style 2"
			}
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [
			{
				"boxWidth": 735,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"date": "2023-11-16T22:48:29.536Z",
		"newsimage": "https://static01.nyt.com/images/2022/09/10/multimedia/10wilson/10wilson-videoSixteenByNine3000.jpg"
	},
	// matchup type
	// score (nba)
	{
		"_id": "655b9d8d467e26be0c336646",
		"title": "Knicks V Spurs",
		"subtitle": "11/19",
		"team": [
			"SA",
			"NY"
		],
		"name": [
			"91",
			"108"
		],
		"stat": [
			"final"
		],
		"points": [
			"10-0",
			"9-1"
		],
		"image": [],
		"sport": "nba",
		"thumbnail": "/template11.jpeg",
		"settings": {
			"type": "matchupimage",
			"headerSize": 20,
			"gradientPerc": 27,
			"gradientPerc1": 14,
			"gradientPerc2": 55,
			"direction": "to top",
			"nameSize": 22,
			"statSize": 17,
			"logoOrder": 0,
			"logoOpacity": 84,
			"logoSize": 143,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "roboto condensed",
			"nameFont": "lemon milk light",
			"statFont": "exo",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 1,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 132,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"borderRadius": 47,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 2,
			"nameMargin2": 10,
			"postWidth": 839,
			"postHeight": 839,
			"postWidthNews": 839,
			"postHeightNews": 839,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 583,
			"duplicateLogoPosition": 218,
			"duplicateLogoOpacity": 12,
			"gridMargin": 1,
			"logoFlip": false,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "uppercase",
			"statTransform": "uppercase",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 22,
			"rankFont": "uniform reg",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 0,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 17,
			"pointsFont": "graduate",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "primary",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark2",
			"texture": "grainy",
			"textureSize": 6,
			"textureOpacity": 9,
			"matchupRankBackground": "secondary",
			"rankBackground": "primary",
			"boxMargin": 15,
			"teamBackground": "primary",
			"teamColor": "#10de7c",
			"actualHeaderColor": "#00000000",
			"rankWidth": 39,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-2",
				"label": "Style 2"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [
			{
				"boxWidth": 839,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"date": "2023-11-20T17:55:24.644Z",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccVZ43H2AHIFHUJOUKWRTVMVPT7Q.avif?alt=media&token=96b098d3-27c1-4cc4-8f00-456f90903170",
		"ranks": [
			"1",
			"2"
		]
	},
	// chart type
	// alignment (nfl)
	{
		"_id": "64f55a7b840b5d406d433e12",
		"thumbnail": "/template5.jpeg",
		"title": "Alignment Chart",
		"subtitle": "Team Efficiency",
		"team": [
			"ARI",
			"ATL",
			"BAL",
			"BUF",
			"CAR",
			"CHI",
			"CIN",
			"CLE",
			"DAL",
			"DEN",
			"DET",
			"GB",
			"HOU",
			"IND",
			"JAX",
			"KC",
			"LV",
			"LAC",
			"LAR",
			"MIA",
			"MIN",
			"NE",
			"NO",
			"NYG",
			"NYJ",
			"PHI",
			"PIT",
			"SF",
			"SEA",
			"TB",
			"TEN",
			"WAS"
		],
		"name": [
			"-15",
			"-1",
			"8",
			"2",
			"13",
			"-7",
			"4",
			"-11",
			"-3",
			"7",
			"12",
			"-6",
			"-16",
			"-13",
			"14",
			"9",
			"-12",
			"3",
			"-10",
			"0",
			"15",
			"-2",
			"5",
			"-8",
			"-4",
			"1",
			"-9",
			"10",
			"6",
			"-5",
			"11",
			"16"
		],
		"stat": [
			"-4",
			"4",
			"0",
			"-2",
			"9",
			"-5",
			"-7",
			"-15",
			"2",
			"6",
			"10",
			"-4",
			"-12",
			"-8",
			"13",
			"1",
			"-9",
			"5",
			"-13",
			"-1",
			"11",
			"7",
			"-6",
			"-10",
			"-3",
			"8",
			"-11",
			"3",
			"12",
			"4",
			"14",
			"15"
		],
		"points": [],
		"image": [
			""
		],
		"sport": "nfl",
		"settings": {
			"direction": "to left",
			"headerSize": 19,
			"gradientPerc": 32,
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 110,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"type": "chart",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 797.2800000000001,
			"postHeight": 797.2800000000001,
			"postWidthNews": 797.2800000000001,
			"postHeightNews": 797.2800000000001,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"orientation": "leftright",
			"orientationMatchup": "topbottom",
			"paneSplitMatchup": [
				80,
				20
			],
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "none",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 43,
			"backgroundGradientPerc1": 63,
			"backgroundGradientPerc2": 92,
			"backgroundGradientDirection": "to left",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "x",
			"chartType": {
				"value": "scatter",
				"label": "Scatter Chart"
			},
			"labelSize": 17,
			"labelColor": "#ffffff",
			"labelFont": "exo",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 1,
			"chartLogoSize": 60,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 5,
			"textureOpacity": 10,
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#1590f7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-09-04T04:18:03.038Z",
		"xLabel": "Defensive Efficiency",
		"yLabel": "Offensive Efficiency"
	},
	// chart type
	// broncos wins (nfl)
	{
		"_id": "63770852014e03612f6ae65c",
		"thumbnail": "/template6.jpeg",
		"title": "Broncos Wins",
		"subtitle": "Each Season",
		"team": [
			"DEN"
		],
		"name": [
			"00'",
			"01'",
			"02'",
			"03'",
			"04'",
			"05'",
			"06'",
			"07'",
			"08'",
			"09'",
			"10'",
			"11'",
			"12'",
			"13'",
			"14'",
			"15'",
			"16'",
			"17'",
			"18'",
			"19'",
			"20'",
			"21'"
		],
		"stat": [
			"11",
			"8",
			"9",
			"10",
			"10",
			"13",
			"9",
			"7",
			"8",
			"8",
			"4",
			"8",
			"13",
			"13",
			"12",
			"12",
			"9",
			"5",
			"6",
			"7",
			"5",
			"7"
		],
		"points": [],
		"image": [
			""
		],
		"sport": "nfl",
		"settings": {
			"direction": "to left",
			"headerSize": 19,
			"gradientPerc": 32,
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 110,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"type": "chart",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 674,
			"postHeight": 674,
			"postWidthNews": 674,
			"postHeightNews": 674,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"orientation": "leftright",
			"orientationMatchup": "topbottom",
			"paneSplitMatchup": [
				80,
				20
			],
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "none",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": []
	},
	// chart type
	// efficiency (nba)
	{
		"_id": "64f64cc075eab4fbe6f48c56",
		"title": "Team Efficiency",
		"subtitle": "2022 Season",
		"team": [
			"sac",
			"bos",
			"Phi",
			"den",
			"Nyk",
			"dal",
			"atl",
			"cle",
			"Gsw",
			"bkn",
			"mil",
			"Uta",
			"LAc",
			"Mem",
			"Okc",
			"Tor",
			"Phx",
			"Por",
			"Lal",
			"No",
			"Chi",
			"Was",
			"Min",
			"Ind",
			"Mia",
			"Orl",
			"Det",
			"Hou",
			"Sas",
			"Cha"
		],
		"name": [
			"117",
			"115.7",
			"115.5",
			"115",
			"114.9",
			"114.3",
			"113.9",
			"113.7",
			"113.5",
			"113",
			"112.8",
			"112.7",
			"112.4",
			"112.3",
			"112.2",
			"112.2",
			"112.1",
			"112",
			"111.8",
			"111.8",
			"111.7",
			"111.5",
			"111.3",
			"111",
			"110.4",
			"109",
			"107.8",
			"107.7",
			"107.2",
			"106.7"
		],
		"stat": [
			"114.2",
			"108.7",
			"110.4",
			"111.8",
			"111.6",
			"113.9",
			"113.5",
			"107.7",
			"111.7",
			"110.7",
			"109.3",
			"113.8",
			"112.1",
			"108.3",
			"111.6",
			"111.5",
			"110.1",
			"115.7",
			"111.5",
			"109.9",
			"109.8",
			"113.3",
			"111.2",
			"115.3",
			"110.6",
			"111.4",
			"116.2",
			"117.1",
			"117.9",
			"112.8"
		],
		"points": [],
		"image": [],
		"sport": "nba",
		"thumbnail": "/template17.jpeg",
		"settings": {
			"type": "chart",
			"headerSize": 20,
			"gradientPerc": 50,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to right",
			"nameSize": 24,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 60,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "roboto condensed",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 39,
			"watermarkWidth": 104,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "rightleft",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": false,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 889,
			"postHeight": 889,
			"postWidthNews": 889,
			"postHeightNews": 889,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#000000",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "scatter",
				"label": "Scatter Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 5,
			"textureOpacity": 10,
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#1590f7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-09-04T21:31:43.654Z",
		"xLabel": "Defensive Rating",
		"yLabel": "Offensive Rating"
	},
	// grid type
	// all-nba (nba)
	{
		"title": "23-24 All-NBA First Team",
		"subtitle": "",
		"team": [
			"dal",
			"okc",
			"bos",
			"mil",
			"den"
		],
		"name": [
			""
		],
		"stat": [
			"Luka##Doncic",
			"Shai##Gilgeous-Alexander",
			"Jayson##Tatum",
			"Giannis##Antetokounmpo",
			"Nikola##Jokic"
		],
		"points": [],
		"image": [
			"https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fplayer-image-605bf7c0c93d665874e4d6cc16674564180087.jpg?alt=media&token=167f0709-1967-4f10-bc86-cb6320dd5b23",
			"https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fplayer-image-605bf7c0c93d665874e4d6ccYTE2ORHGVNM4ZDVZFGBSVFHR5A%20(1).jpg?alt=media&token=7edd9d8a-0370-496f-b57b-8b877b9a1abd",
			"https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fplayer-image-605bf7c0c93d665874e4d6ccJayson_Tatum_(51688503300)_(cropped).jpg?alt=media&token=1ac53d40-f4ba-43f1-bcbf-5923cdd5f8e5",
			"https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fplayer-image-605bf7c0c93d665874e4d6ccRQTKSXPXDJBV7JY7HL3WOBZ6B4%20(1).jpg?alt=media&token=08085ab6-4159-4d33-9dde-618760261bb5",
			"https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fplayer-image-605bf7c0c93d665874e4d6ccNikola_Jokic_free_throw_(cropped).jpg?alt=media&token=fc6c0e3b-a528-4614-8cbb-3951efb4343d"
		],
		"sport": "nba",
		"thumbnail": "/template18.jpeg",
		"settings": {
			"type": "grid",
			"headerSize": 20,
			"gradientPerc": 50,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to right",
			"nameSize": 24,
			"statSize": 12,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 60,
			"headerMargin": 15,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "hemi",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 4,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "secondary",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 31,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 779,
			"postHeight": 779,
			"postWidthNews": 779,
			"postHeightNews": 779,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 9,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": 5,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 5,
			"textureOpacity": 10,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#1590f7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 3,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 15,
			"boxGlowColor": "secondary",
			"lineHeightNews": 0,
			"subtitleSize": 10,
			"statMultiLine": true
		},
		"teamoverrides": [
			{
				"abbreviation": "SA",
				"abbreviation2": "SAS",
				"full": "SPURS",
				"primary": "#565656",
				"secondary": "#000000",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
				"sport": "nba",
				"isGlobal": false
			},
			{
				"abbreviation": "SA",
				"abbreviation2": "SAS",
				"full": "SPURS",
				"primary": "#565656",
				"secondary": "#000000",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
				"sport": "nba",
				"isGlobal": false
			},
			{
				"abbreviation": "SA",
				"abbreviation2": "SAS",
				"full": "SPURS",
				"primary": "#565656",
				"secondary": "#000000",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
				"sport": "nba",
				"isGlobal": false
			},
			{
				"abbreviation": "SA",
				"abbreviation2": "SAS",
				"full": "SPURS",
				"primary": "#565656",
				"secondary": "#000000",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
				"sport": "nba",
				"isGlobal": false
			},
			{
				"abbreviation": "SA",
				"abbreviation2": "SAS",
				"full": "SPURS",
				"primary": "#565656",
				"secondary": "#000000",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
				"sport": "nba",
				"isGlobal": false
			},
			{
				"abbreviation": "SA",
				"abbreviation2": "SAS",
				"full": "SPURS",
				"primary": "#565656",
				"secondary": "#000000",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
					"url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)"
				],
				"gradientPerc": 0,
				"gradientPerc1": 0,
				"gradientPerc2": 0,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)",
				"sport": "nba",
				"isGlobal": false
			}
		],
		"draggables": [
			{
				"boxWidth": 128,
				"xvalue": "-16",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 128,
				"xvalue": "-22",
				"yvalue": "-10",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 128,
				"xvalue": "-24",
				"yvalue": "2",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 128,
				"xvalue": "-41",
				"yvalue": "-8",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 128,
				"xvalue": "-24",
				"yvalue": "-14",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"date": "2024-06-04T20:19:24.986Z",
		"savedTitle": "",
		"_id": "665f76cd98e10b59a63218e8"
	},
	// grid type
	// nfl top 100 (nfl)
	{
		"title": "NFL Top 100 Players",
		"thumbnail": "/template7.jpeg",
		"subtitle": "2023",
		"team": [
			"KC",
			"min",
			"phi",
			"sf",
			"kc",
			"cin",
			"mia",
			"buf",
			"dal"
		],
		"name": [],
		"stat": [],
		"points": [],
		"image": [
			"3139477",
			"4262921",
			"4040715",
			"4040605",
			"15847",
			"3915511",
			"3116406",
			"3918298",
			"4361423"
		],
		"sport": "nfl",
		"settings": {
			"type": "grid",
			"headerSize": 20,
			"gradientPerc": 61,
			"gradientPerc1": 30,
			"gradientPerc2": 84,
			"direction": "to left",
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 90,
			"logoSize": 79,
			"headerMargin": 5,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "edo",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "secondary",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 14,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 4,
			"nameMargin2": 10,
			"postWidth": 779,
			"postHeight": 779,
			"postWidthNews": 779,
			"postHeightNews": 779,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 643,
			"duplicateLogoPosition": 116,
			"duplicateLogoOpacity": 13,
			"gridMargin": 6,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 66,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark3",
			"texture": null,
			"textureSize": 5,
			"textureOpacity": 5,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 9,
			"teamBackground": "primary",
			"teamColor": "#f646e7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 25,
			"boxGlowColor": "secondary",
			"lineHeightNews": 0
		},
		"teamoverrides": [
			{
				"abbreviation": "CIN",
				"full": "BENGALS",
				"primary": "#fb4f14",
				"secondary": "#fb4f14",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nfl/500/cin.png)"
				],
				"helmet": "url(/logos/helmets/cin.png)",
				"gradientPerc": 61,
				"gradientPerc1": 30,
				"gradientPerc2": 84,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nfl/500/cin.png)",
				"sport": "nfl",
				"isGlobal": false
			}
		],
		"draggables": [
			{
				"boxWidth": 137,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 137,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 137,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 137,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			},
			{
				"boxWidth": 137,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"date": "2024-06-04T20:51:46.795Z",
		"savedTitle": "",
		"_id": "665f7e63b98aa5bba4d219ec"
	},
	// grid type
	// pts per game (ncaa)
	{
		"_id": "64f644a893b1bcdf0b67abbd",
		"title": "POINTS PER GAME",
		"thumbnail": "/template27.jpeg",
		"subtitle": "2022 Season",
		"team": [
			"GONZ",
			"TOL",
			"ORU",
			"SUU",
			"YSU",
			"ARIZ",
			"ALA",
			"MRSH",
			"COR",
			"XAV",
			"FUR",
			"UNM",
			"UAB",
			"JMU",
			"UTM",
			"COFC",
			"AMCC",
			"IOWA",
			"TULN",
			"WRST",
			"MEM",
			"BRY",
			"MARQ",
			"INST",
			"MIA"
		],
		"name": [
			"Gonzaga",
			"Toledo",
			"Oral Roberts",
			"Southern Utah",
			"Youngstown St",
			"Arizona",
			"Alabama",
			"Marshall",
			"Cornell",
			"Xavier",
			"Furman",
			"New Mexico",
			"UAB",
			"James Madison",
			"UT Martin",
			"Charleston",
			"Texas A&M-CC",
			"Iowa",
			"Tulane",
			"Wright St",
			"Memphis",
			"Bryant",
			"Marquette",
			"Indiana St",
			"Miami"
		],
		"stat": [
			"86.1",
			"85.4",
			"83.3",
			"82.8",
			"81.9",
			"81.9",
			"81.8",
			"81.8",
			"81.7",
			"80.9",
			"80.9",
			"80.9",
			"80.7",
			"80.5",
			"80.5",
			"80.1",
			"80.1",
			"80.1",
			"79.9",
			"79.9",
			"79.4",
			"79.4",
			"79.3",
			"79.3",
			"79.1"
		],
		"points": [],
		"image": [],
		"sport": "ncaa",
		"settings": {
			"type": "grid",
			"headerSize": 20,
			"gradientPerc": 87,
			"gradientPerc1": 30,
			"gradientPerc2": 100,
			"direction": "to bottom",
			"nameSize": 24,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 35,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "jersey",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#55e360",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"borderRadius": 11,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 889,
			"postHeight": 889,
			"postWidthNews": 889,
			"postHeightNews": 889,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 695,
			"duplicateLogoPosition": 13,
			"duplicateLogoOpacity": 5,
			"gridMargin": 4,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background13.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291",
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 1,
			"subtitleTransform": "uppercase",
			"backgroundColor": "single",
			"backgroundGradient1": "#b1d684",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 41,
			"backgroundGradientPerc1": 60,
			"backgroundGradientPerc2": 86,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"texture": null,
			"textureSize": 7,
			"textureOpacity": 20,
			"rankBackground": "#000000",
			"boxMargin": 4,
			"teamBackground": "gradient",
			"teamColor": "#92a843",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"boxRankOutlineSize": 0,
			"darkMode": null,
			"rankMargin2": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none"
		},
		"teamoverrides": [
			{
				"_id": "64da58bb0bb2a3a99fe15f1a",
				"abbreviation": "IOWA",
				"sport": "ncaa",
				"direction": "to left",
				"gradientPerc": 32,
				"gradientPerc1": 0,
				"gradientPerc2": 100,
				"isGlobal": true,
				"logo": "url(https://cdn.bleacherreport.net/images/team_logos/328x328/iowa_hawkeyes_football.png)",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/ncaa/500/2294.png)",
					"url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2294.png)",
					"url(https://cdn.bleacherreport.net/images/team_logos/328x328/iowa_hawkeyes_football.png)"
				],
				"primary": "#ffe100",
				"secondary": "#000000"
			}
		],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-09-04T20:57:12.424Z"
	},
	// grid type (with image)
	// albert pujols (mlb)
	{
		"_id": "64f6461e93b1bcdf0b67abbe",
		"thumbnail": "/template21.jpeg",
		"title": "Albert Pujols",
		"subtitle": "Home Runs Against Each Team",
		"team": [
			"Astros",
			"Cubs",
			"Pirates",
			"Reds",
			"Brewers",
			"Mariners",
			"Rangers",
			"Royals",
			"Diamondbacks",
			"Rockies",
			"Nationals",
			"Mets",
			"Athletics",
			"Braves",
			"Dodgers",
			"Padres",
			"Giants",
			"Tigers",
			"Marlins",
			"Phillies",
			"Rays",
			"White Sox",
			"Red Sox",
			"Orioles",
			"Blue Jays",
			"Guardians",
			"Twins",
			"Yankees",
			"Angels",
			"Cardinals"
		],
		"name": [],
		"stat": [
			"62",
			"59",
			"57",
			"49",
			"45",
			"31",
			"30",
			"26",
			"25",
			"24",
			"24",
			"23",
			"23",
			"22",
			"22",
			"20",
			"20",
			"15",
			"15",
			"15",
			"15",
			"13",
			"12",
			"11",
			"11",
			"9",
			"9",
			"8",
			"5",
			"3"
		],
		"points": [],
		"image": [],
		"sport": "mlb",
		"settings": {
			"direction": "to left",
			"headerSize": 20,
			"gradientPerc": 71,
			"nameSize": 23,
			"statSize": 27,
			"logoOrder": 0,
			"logoOpacity": 86,
			"logoSize": 90,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "hemi",
			"nameFont": "roboto bold",
			"statFont": "roboto bold",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 39,
			"watermarkWidth": 104,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 1,
			"statShadow": 0,
			"type": "gridimage",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "rightleft",
			"borderRadius": 6,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 2,
			"nameMargin2": 10,
			"postWidth": 889,
			"postHeight": 889,
			"postWidthNews": 889,
			"postHeightNews": 889,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 217,
			"duplicateLogoPosition": 301,
			"duplicateLogoOpacity": 5,
			"withImage": true,
			"orientation": "rightleft",
			"orientationMatchup": "topbottom",
			"paneSplitMatchup": [
				80,
				20
			],
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 0,
			"gradientPerc2": 94,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleFont": "roboto bold",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "none",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 116,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": true,
			"darkMode": "dark3",
			"texture": null,
			"textureSize": 7,
			"textureOpacity": 13,
			"rankBackground": "#000000",
			"boxMargin": 3,
			"teamBackground": "gradient",
			"teamColor": "#593102",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none"
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [
			{
				"boxWidth": 440,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"date": "2023-09-04T21:03:26.447Z",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccusa_today_16014012.jpg?alt=media&token=23c13480-04ac-4c36-be5d-de8144e8535e"
	},
	// tier type
	// fantasy rbs (nfl)
	{
		"_id": "64f647e293b1bcdf0b67abbf",
		"thumbnail": "/template8.jpeg",
		"title": "Fantasy RB Tiers",
		"subtitle": "This Season",
		"team": [
			"SF",
			"LAC",
			"ATL",
			"CLE",
			"#",
			"NYG",
			"DAL",
			"TEN",
			"LV",
			"#",
			"CIN",
			"NE",
			"PIT",
			"DET",
			"JAC",
			"GB",
			"SEA",
			"#",
			"NYJ",
			"HOU",
			"CAR",
			"MIN",
			"BAL",
			"LAR",
			"ARI",
			"BUF",
			"TB",
			"DET",
			"DEN",
			"IND",
			"NO",
			"#",
			"KC",
			"NYJ",
			"PHI",
			"CHI",
			"WAS",
			"WAS",
			"GB",
			"DEN",
			"SEA",
			"NO"
		],
		"name": [],
		"stat": [
			"Christian McCaffrey",
			"Austin Ekeler",
			"Bijan Robinson",
			"Nick Chubb",
			"Saquon Barkley",
			"Tony Pollard",
			"Derrick Henry",
			"Josh Jacobs",
			"Joe Mixon",
			"Rhamondre Stevenson",
			"Najee Harris",
			"Jahmyr Gibbs",
			"Travis Etienne Jr.",
			"Aaron Jones",
			"Kenneth Walker III",
			"Breece Hall",
			"Dameon Pierce",
			"Miles Sanders",
			"Alexander Mattison",
			"J.K. Dobbins",
			"Cam Akers",
			"James Conner",
			"James Cook",
			"Rachaad White",
			"David Montgomery",
			"Javonte Williams",
			"Jonathan Taylor",
			"Alvin Kamara",
			"Isiah Pacheco",
			"Dalvin Cook",
			"D'Andre Swift",
			"Khalil Herbert",
			"Antonio Gibson",
			"Brian Robinson Jr.",
			"AJ Dillon",
			"Samaje Perine",
			"Zach Charbonnet",
			"Jamaal Williams"
		],
		"image": [
			"3117251",
			"3068267",
			"4430807",
			"3128720",
			"3929630",
			"3916148",
			"3043078",
			"4047365",
			"3116385",
			"4569173",
			"4241457",
			"4429795",
			"4239996",
			"3042519",
			"4567048",
			"4427366",
			"4360238",
			"4045163",
			"4048244",
			"4241985",
			"4240021",
			"3045147",
			"4379399",
			"4697815",
			"4035538",
			"4361579",
			"4242335",
			"3054850",
			"4361529",
			"3116593",
			"4259545",
			"4035886",
			"4360294",
			"4241474",
			"4239934",
			"3116389",
			"4426385",
			"2980453"
		],
		"sport": "nfl",
		"settings": {
			"type": "tier",
			"headerSize": 20,
			"gradientPerc": 92,
			"gradientPerc1": 16,
			"gradientPerc2": 100,
			"direction": "to left",
			"nameSize": 24,
			"statSize": 17,
			"logoOrder": 1,
			"logoOpacity": 78,
			"logoSize": 76,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "league gothic",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#c5fb63",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 39,
			"watermarkWidth": 104,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "rightleft",
			"orientationNews": "rightleft",
			"orientationMatchup": "topbottom",
			"borderRadius": 6,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": false,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 889,
			"postHeight": 889,
			"postWidthNews": 889,
			"postHeightNews": 889,
			"tierFont": "anton",
			"tierColor": "#ffffff",
			"tierSize": 74,
			"duplicateLogoSize": 669,
			"duplicateLogoPosition": 29,
			"duplicateLogoOpacity": 8,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background2.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291",
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "pop warner",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "uppercase",
			"backgroundColor": "single",
			"backgroundGradient1": "#8713e0",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 14,
			"backgroundGradientPerc1": 40,
			"backgroundGradientPerc2": 57,
			"backgroundGradientDirection": "to top",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark1",
			"texture": null,
			"textureSize": 7,
			"textureOpacity": 24,
			"rankBackground": "#000000",
			"boxMargin": 15,
			"teamBackground": "gradient",
			"teamColor": "#0c7399",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none"
		},
		"tiers": {
			"0": 1,
			"1": 2,
			"2": 3,
			"3": 4,
			"4": 5,
			"5": 6,
			"6": 7,
			"7": 8,
			"8": 9,
			"9": 10
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-09-04T21:10:58.757Z"
	},
	// tier type
	// player pyramid (nba)
	{
		"_id": "6222dee81fc0fd578591d85e",
		"title": "NBA PLAYER PYRAMID",
		"subtitle": "ACCORDING TO FOX SPORTS",
		"team": [
			"mil",
			"#",
			"lal",
			"brk",
			"#",
			"phi",
			"gsw",
			"phx",
			"#",
			"den",
			"lac",
			"mem",
			"dal"
		],
		"name": [
			""
		],
		"stat": [
			"Giannis Antetokounmpo",
			"LeBron James",
			"Kevin Durant",
			"Joel Embiid",
			"Stephen Curry",
			"Chris Paul",
			"Nikola Jokic",
			"Kawhi Leonard",
			"Ja Morant",
			"Luka Doncic"
		],
		"image": [
			"3032977",
			"1966",
			"3202",
			"3059318",
			"3975",
			"2779",
			"3112335",
			"6450",
			"4279888",
			"3945274"
		],
		"sport": "nba",
		"thumbnail": "/template15.jpeg",
		"settings": {
			"direction": "to bottom",
			"headerSize": 19,
			"gradientPerc": 49,
			"nameSize": 24,
			"statSize": 22,
			"logoOrder": 0,
			"logoOpacity": 83,
			"logoSize": 60,
			"headerMargin": 1,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "lemon milk reg",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#000138",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#000138",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"type": "tier",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#ffffff",
			"nameColor": "#ffffff",
			"statColor": "#000138",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientationNews": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 7,
			"nameMargin2": 10,
			"postWidth": 745.14,
			"postHeight": 745.14,
			"postWidthNews": 745.14,
			"postHeightNews": 745.14,
			"tierFont": "roboto condensed",
			"tierColor": "#dbdbdb",
			"tierSize": 50,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 34,
			"gradientPerc2": 63,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "top",
			"pyramid": true
		},
		"tiers": {
			"0": "Tier 1",
			"1": "Tier 2",
			"2": "Tier 3",
			"3": "Tier 4",
			"4": 5,
			"5": 6,
			"6": 7,
			"7": 8,
			"8": 9,
			"9": 10
		}
	},
	// tier type
	// mvp voting (nba)
	{
		"_id": "663c311449f563f8142716c8",
		"title": "MVP Voting",
		"subtitle": "Each Year",
		"team": [
			"DEN",
			"OKC",
			"DAL",
			"#",
			"phi",
			"den",
			"mil",
			"#",
			"den",
			"phi",
			"mil",
			"#",
			"den",
			"phi",
			"gsw",
			"#",
			"mil",
			"lal",
			"hou"
		],
		"name": [
			""
		],
		"stat": [
			"Nikola Jokić",
			"Shai Gilgeous-Alexander",
			"Luka Dončić",
			"Joel Embiid",
			"Nikola Jokić\t",
			"Giannis Antetokounmpo",
			"Nikola Jokić\t",
			"Joel Embiid",
			"Giannis Antetokounmpo",
			"Nikola Jokić\t",
			"Joel Embiid",
			"Stephen Curry",
			"Giannis Antetokounmpo\t",
			"LeBron James\t",
			"James Harden\t"
		],
		"image": [
			"3112335",
			"4278073",
			"3945274",
			"3059318",
			"3112335",
			"3032977",
			"3112335",
			"3059318",
			"3032977",
			"3112335",
			"3059318",
			"3975",
			"3032977",
			"1966",
			"3992"
		],
		"sport": "nba",
		"thumbnail": "/template14.jpeg",
		"settings": {
			"type": "tier",
			"headerSize": 20,
			"gradientPerc": 41,
			"gradientPerc1": 6,
			"gradientPerc2": 85,
			"direction": "to bottom",
			"nameSize": 24,
			"statSize": 20,
			"logoOrder": 1,
			"logoOpacity": 85,
			"logoSize": 74,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "roboto bold",
			"nameFont": "roboto condensed",
			"statFont": "roboto bold",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 75,
			"statMargin": 1,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 1,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 32,
			"headerFontNews": "hemi",
			"headerMarginNews": -100,
			"headerSpacingNews": -1,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 8,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 4,
			"nameMargin2": 10,
			"postWidth": 610,
			"postHeight": 610,
			"postWidthNews": 610,
			"postHeightNews": 610,
			"tierFont": "roboto bold",
			"tierColor": "#ffffff",
			"tierSize": 36,
			"duplicateLogoSize": 580,
			"duplicateLogoPosition": 1,
			"duplicateLogoOpacity": 6,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "uppercase",
			"nameTransform": "none",
			"statTransform": "uppercase",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 8,
			"subtitleMargin": 2,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 86,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark1",
			"texture": "grainy",
			"textureSize": 3,
			"textureOpacity": 22,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 12,
			"teamBackground": "gradient",
			"teamColor": "#1244c7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 0,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none"
		},
		"tiers": {
			"0": "'24",
			"1": "'23",
			"2": "'22",
			"3": "'21",
			"4": "'20",
			"5": 6,
			"6": 7,
			"7": 8,
			"8": 9,
			"9": 10
		},
		"teamoverrides": [
			{
				"abbreviation": "GB",
				"abbreviation2": "GNB",
				"full": "PACKERS",
				"primary": "#005f45",
				"secondary": "#ffb612",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nfl/500/gb.png)"
				],
				"helmet": "url(/logos/helmets/gb.png)",
				"gradientPerc": 32,
				"gradientPerc1": 0,
				"gradientPerc2": 100,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nfl/500/gb.png)",
				"sport": "nfl",
				"isGlobal": false
			}
		],
		"draggables": [],
		"floatingTexts": [],
		"date": "2024-05-09T02:12:36.075Z",
		"nameOverrides": [],
		"statOverrides": [],
		"nameHighlights": [],
		"statHighlights": [],
		"savedTitle": ""
	},
	// bracket type
	// playoffs (nhl)
	{
		"title": "NHL Playoffs",
		"thumbnail": "/template26.jpeg",
		"subtitle": "2024",
		"team": [
			"dal",
			"vgs",
			"WPG",
			"col",
			"VAN",
			"NSH",
			"EDM",
			"LA",
			"FLA",
			"TB",
			"BOS",
			"TOR",
			"NYR",
			"WSH",
			"CAR",
			"NYI"
		],
		"name": [],
		"stat": [],
		"image": [
			""
		],
		"sport": "nhl",
		"settings": {
			"type": "bracket",
			"headerSize": 30,
			"gradientPerc": 64,
			"gradientPerc1": 6,
			"gradientPerc2": 95,
			"direction": "to top",
			"nameSize": 24,
			"statSize": 23,
			"logoOrder": 1,
			"logoOpacity": 93,
			"logoSize": 66,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "jersey",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 7,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 1,
			"nameMargin2": 10,
			"postWidth": 779,
			"postHeight": 779,
			"postWidthNews": 779,
			"postHeightNews": 779,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 231,
			"duplicateLogoPosition": 124,
			"duplicateLogoOpacity": 8,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background23.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291",
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto bold",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "uppercase",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark2",
			"texture": null,
			"textureSize": 1,
			"textureOpacity": 20,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 9,
			"teamBackground": "gradient",
			"teamColor": "#2e02e9",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 1,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 10,
			"boxGlowColor": "#3a87ff",
			"lineHeightNews": 0,
			"subtitleSize": 10
		},
		"round32": {
			"team": [],
			"name": [],
			"stat": [],
			"image": []
		},
		"round16": {
			"team": [],
			"name": [],
			"stat": [],
			"image": []
		},
		"round8": {
			"team": [
				"dal",
				"col",
				"VAN",
				"EDM",
				"FLA",
				"BOS",
				"NYR",
				"CAR"
			],
			"name": [],
			"stat": [],
			"image": []
		},
		"round4": {
			"team": [
				"dal",
				"EDM",
				"FLA",
				"NYR"
			],
			"name": [],
			"stat": [],
			"image": []
		},
		"round2": {
			"team": [
				"EDM",
				"FLA"
			],
			"name": [],
			"stat": [],
			"image": []
		},
		"round1": {
			"team": [
				"FLA"
			],
			"name": [],
			"stat": [],
			"image": []
		},
		"teamoverrides": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2024-06-04T21:51:16.593Z",
		"savedTitle": "",
		"_id": "665f8c555dcab37c8aea96db"
	},
	// bracket type
	// playoffs (wnba)
	{
		"_id": "64f6492e93b1bcdf0b67abc0",
		"thumbnail": "/template31.jpeg",
		"title": "WNBA Playoffs",
		"subtitle": "2022 Season",
		"team": [
			"lv",
			"phx",
			"sea",
			"wsh",
			"chi",
			"ny",
			"conn",
			"dal"
		],
		"name": [],
		"stat": [],
		"image": [],
		"sport": "wnba",
		"settings": {
			"type": "bracket",
			"headerSize": 24,
			"gradientPerc": 41,
			"gradientPerc1": 14,
			"gradientPerc2": 100,
			"direction": "to left",
			"nameSize": 24,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 90,
			"logoSize": 80,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "edo",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#5b4623",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 39,
			"watermarkWidth": 104,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "rightleft",
			"orientationNews": "rightleft",
			"orientationMatchup": "topbottom",
			"borderRadius": 0,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"duplicate": false,
			"logoShadow": 5,
			"nameMargin2": 10,
			"postWidth": 889,
			"postHeight": 889,
			"postWidthNews": 889,
			"postHeightNews": 889,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 565,
			"duplicateLogoPosition": 170,
			"duplicateLogoOpacity": 13,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 12,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "edo",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "gradient",
			"backgroundGradient1": "#fa571b",
			"backgroundGradient2": "#000000",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 47,
			"backgroundGradientPerc2": 75,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 10,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": "dark1",
			"texture": "isometric",
			"textureSize": 3,
			"textureOpacity": 24,
			"rankBackground": "#000000",
			"boxMargin": 13,
			"teamBackground": "gradient",
			"teamColor": "#c909e3",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none"
		},
		"round32": {
			"team": [],
			"name": [],
			"stat": [],
			"image": []
		},
		"round16": {
			"team": [],
			"name": [],
			"stat": [],
			"image": []
		},
		"round8": {
			"team": [],
			"name": [],
			"stat": [],
			"image": []
		},
		"round4": {
			"team": [
				"lv",
				"sea",
				"chi",
				"conn"
			],
			"name": [],
			"stat": [],
			"image": []
		},
		"round2": {
			"team": [
				"lv",
				"conn"
			],
			"name": [],
			"stat": [],
			"image": []
		},
		"round1": {
			"team": [
				"lv"
			],
			"name": [],
			"stat": [],
			"image": []
		},
		"teamoverrides": [],
		"teamsplits": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2023-09-04T21:16:30.017Z"
	},
	// highlight type
	// paulo banchero (nba)
	{
		"_id": "654abf94ebb2ae39ea031060",
		"thumbnail": "/template12.jpeg",
		"sport": "nba",
		"title": "Paulo Banchero",
		"newstitle": "Paulo Banchero",
		"newssubtitle": "27 Pts\n9 REB\n5 AST\n61 FG%",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccD7MVWGTGXZAVXPAHH3AWUILIU4.webp?alt=media&token=ccb85e68-9246-44c8-a9f2-1034e0152b11",
		"newsteam": "ORL",
		"settings": {
			"direction": "to left",
			"headerSize": 19,
			"gradientPerc": 32,
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 110,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"type": "highlightimage",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 27,
			"headerFontNews": "uniform reg",
			"headerMarginNews": -100,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#ffffff",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 271,
			"orientationNews": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 846,
			"postHeight": 846,
			"postWidthNews": 846,
			"postHeightNews": 846,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"orientation": "leftright",
			"orientationHighlight": "rightleft",
			"orientationMatchup": "topbottom",
			"paneSplitHighlight": [
				38.061465721040186,
				61.938534278959814
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-3",
				"label": "Style 3"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleTransform": "none",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 5,
			"textureOpacity": 10,
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#1590f7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000"
		},
		"teamoverrides": [
			{
				"abbreviation": "GB",
				"abbreviation2": "GNB",
				"full": "PACKERS",
				"primary": "#005f45",
				"secondary": "#ffb612",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nfl/500/gb.png)"
				],
				"helmet": "url(/logos/helmets/gb.png)",
				"gradientPerc": 32,
				"gradientPerc1": 0,
				"gradientPerc2": 100,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nfl/500/gb.png)",
				"sport": "nfl",
				"isGlobal": false
			}
		],
		"teamsplits": [],
		"draggables": [
			{
				"boxWidth": 519,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"date": "2023-11-07T22:52:04.027Z"
	},
	// highlight type
	// luis castillo (mlb)
	{
		"title": "Luis Castillo",
		"thumbnail": "/template20.jpeg",
		"sport": "mlb",
		"newstitle": "Luis Castillo",
		"newssubtitle": "7 IP\n2 H\n0 R\n3 BB\n6 K",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccPWAEJEAOMBJWRFGGIVQ7MJF2LU%20(1).jpg?alt=media&token=28b85332-49bf-435b-bc04-56d18b0da866",
		"newsteam": "sea",
		"settings": {
			"type": "highlightimage",
			"headerSize": 19,
			"gradientPerc": 32,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to left",
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 110,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 23,
			"headerFontNews": "bebas neue",
			"headerMarginNews": 12,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#ffffff",
			"logoOpacityNews": 96,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 344,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				58.71741186268521,
				41.28258813731479
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 803,
			"postHeight": 803,
			"postWidthNews": 803,
			"postHeightNews": 803,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-2",
				"label": "Style 2"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": "isometric",
			"textureSize": 5,
			"textureOpacity": 10,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#1590f7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 24,
			"subtitleMarginNews": 9,
			"subtitleSpacingNews": 1,
			"subtitleFontNews": "uniform bold",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 0,
			"boxGlowColor": "#ffffff",
			"lineHeightNews": 0,
			"lineHeightTextNews": 1.3
		},
		"teamoverrides": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2024-06-05T03:36:59.685Z",
		"name": [],
		"stat": [],
		"savedTitle": "",
		"_id": "665fdd5c0563022e55076e46"
	},
	// highlight type
	// jimmy butler (nba)
	{
		"_id": "654ef22048f4d0813b5b5ee5",
		"sport": "nba",
		"thumbnail": "/template13.jpeg",
		"title": "Jimmy Butler",
		"newssubtitle": "50 PTS\n20 AST\n10 REB",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccJimmy-Butler-Miami-Heat-playoffs.webp?alt=media&token=c495d6fe-8c84-467b-876c-2232b87448d4",
		"newsteam": "MIA",
		"settings": {
			"type": "highlightimage",
			"headerSize": 20,
			"gradientPerc": 50,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to right",
			"nameSize": 24,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 60,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "roboto condensed",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 32,
			"headerFontNews": "hemi",
			"headerMarginNews": -100,
			"headerSpacingNews": -1,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 882,
			"postHeight": 882,
			"postWidthNews": 882,
			"postHeightNews": 882,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 5,
			"textureOpacity": 10,
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#1590f7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000"
		},
		"teamoverrides": [
			{
				"abbreviation": "GB",
				"abbreviation2": "GNB",
				"full": "PACKERS",
				"primary": "#005f45",
				"secondary": "#ffb612",
				"logos": [
					"url(https://a.espncdn.com/i/teamlogos/nfl/500/gb.png)"
				],
				"helmet": "url(/logos/helmets/gb.png)",
				"gradientPerc": 32,
				"gradientPerc1": 0,
				"gradientPerc2": 100,
				"direction": "to left",
				"logo": "url(https://a.espncdn.com/i/teamlogos/nfl/500/gb.png)",
				"sport": "nfl",
				"isGlobal": false
			}
		],
		"teamsplits": [],
		"draggables": [
			{
				"boxWidth": 435,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"date": "2023-11-11T03:16:47.907Z"
	},
	// news type
	// maccaffrey (nfl)
	{
		"sport": "nfl",
		"thumbnail": "/template9.jpeg",
		"title": "NFL Breaking News",
		"newstitle": "BREAKING",
		"newssubtitle": "49ers\nExtending\nChristian\nMcCaffrey",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6ccrawImage.jpg?alt=media&token=e0b610c9-17fb-494b-acd5-0c2cf086d238",
		"newsteam": "SF",
		"settings": {
			"type": "news",
			"headerSize": 30,
			"gradientPerc": 64,
			"gradientPerc1": 6,
			"gradientPerc2": 95,
			"direction": "to top",
			"nameSize": 24,
			"statSize": 23,
			"logoOrder": 1,
			"logoOpacity": 93,
			"logoSize": 66,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "jersey",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "league spartan",
			"headerMarginNews": -5,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 75,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 530,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 7,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 1,
			"nameMargin2": 10,
			"postWidth": 779,
			"postHeight": 779,
			"postWidthNews": 779,
			"postHeightNews": 779,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 231,
			"duplicateLogoPosition": 124,
			"duplicateLogoOpacity": 8,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 1,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-5",
				"label": "Style 5"
			},
			"postBackground": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/background23.png?alt=media&token=45296fd8-793a-4f30-af27-3a7dd2872291",
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto bold",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "uppercase",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": "splotchy",
			"textureSize": 1,
			"textureOpacity": 11,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 9,
			"teamBackground": "gradient",
			"teamColor": "#6bfeab",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 1,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 25,
			"subtitleMarginNews": 26,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#aa0000",
			"imageShadowSize": 0,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 10,
			"boxGlowColor": "#3a87ff",
			"lineHeightNews": 5,
			"subtitleSize": 10
		},
		"teamoverrides": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2024-06-04T22:01:34.625Z",
		"name": [],
		"stat": [],
		"savedTitle": "",
		"_id": "665f8ebfc8786802144ccbbc"
	},
	// news type
	// quotes (ncaa)
	{
		"_id": "622d6fb30d29313b8bd10504",
		"thumbnail": "/template23.jpeg",
		"sport": "ncaa",
		"title": "Reed Rohlman Quote",
		"newstitle": "REED ROHLMAN, OF",
		"newssubtitle": "WE KNOW WHAT SOUTH CAROLINA HAS TO OFFER, AND WE'RE GOING TO GO UP WITH THEM. WE'RE GOING TO GIVE IT OUR BEST, BECAUSE WE KNOW THEY'RE GOING TO GIVE IT THEIR BEST.",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6cc0027607-zgor-1280x720.jpg?alt=media&token=7fe8a663-754e-4138-9a95-1e6519ed5128",
		"newsteam": "Clem",
		"settings": {
			"direction": "to left",
			"headerSize": 19,
			"gradientPerc": 32,
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 110,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"type": "news",
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": -94.46999999999997,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 22,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": -20,
			"headerSpacingNews": -1,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#ffffff",
			"logoOpacityNews": 100,
			"gradientPercNews": 81,
			"directionNews": "to top",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 371,
			"orientationNews": "rightleft",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 687.0600000000001,
			"postHeight": 687.0600000000001,
			"postWidthNews": 687.0600000000001,
			"postHeightNews": 687.0600000000001,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"gradientPerc1News": 59,
			"gradientPerc2News": 100,
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-2",
				"label": "Style 2"
			}
		}
	},
	// news type
	// harden (nba)
	{
		"sport": "nba",
		"thumbnail": "/template10.jpeg",
		"title": "NBA Breaking",
		"newstitle": "BREAKING",
		"newssubtitle": "James Harden\ntraded to\nClippers",
		"newsimage": "https://www.usatoday.com/gcdn/authoring/authoring-images/2023/11/07/USAT/71484421007-usatsi-21836748.jpg?crop=2318,2318,x369,y0",
		"newsteam": "LAC",
		"settings": {
			"type": "news",
			"headerSize": 19,
			"gradientPerc": 32,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to left",
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 110,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "the bold one",
			"headerMarginNews": -12,
			"headerSpacingNews": 0,
			"headerColorNews": "#1d428a",
			"headerFontColorNews": "#ffffff",
			"logoOpacityNews": 100,
			"gradientPercNews": 98,
			"gradientPerc1News": 33,
			"gradientPerc2News": 100,
			"directionNews": "to right",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 441,
			"orientation": "leftright",
			"orientationNews": "leftright",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 803,
			"postHeight": 803,
			"postWidthNews": 803,
			"postHeightNews": 803,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": "null",
			"textureSize": 4,
			"textureOpacity": 11,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "primary",
			"teamColor": "#93d4ab",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 26,
			"subtitleMarginNews": 21,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto bold",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 0,
			"boxGlowColor": "#ffffff",
			"lineHeightNews": 5,
			"lineHeightTextNews": 0.9
		},
		"teamoverrides": [],
		"draggables": [],
		"floatingTexts": [],
		"date": "2024-06-05T03:12:28.017Z",
		"name": [],
		"stat": [],
		"savedTitle": "",
		"_id": "665fd79cb44e286e99fa5b85"
	},
	// news type
	// glasnow (mlb)
	{
		"title": "MLB Breaking",
		"sport": "mlb",
		"newstitle": "",
		"thumbnail": "/template25.jpeg",
		"newssubtitle": "Tyler Glasnow is the first to 100 strikeouts this season",
		"newsimage": "https://firebasestorage.googleapis.com/v0/b/statlist-images.appspot.com/o/images%2Fmain-605bf7c0c93d665874e4d6cc2HGIPATGBBNPLMVKH7BCCQVZKE%20(1).jpg?alt=media&token=aadb3e09-48fa-4f80-b915-16d12608b94d",
		"newsteam": "lad",
		"settings": {
			"type": "news",
			"headerSize": 20,
			"gradientPerc": 50,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to right",
			"nameSize": 24,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 60,
			"headerMargin": 0,
			"headerSpacing": 0,
			"nameMargin": 0,
			"headerFont": "roboto condensed",
			"nameFont": "roboto condensed",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "roboto condensed",
			"watermarkSize": 30,
			"watermarkWidth": 75,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": 0,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto condensed",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 50,
			"gradientPerc1News": 0,
			"gradientPerc2News": 100,
			"directionNews": "to bottom",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 500,
			"orientation": "leftright",
			"orientationNews": "topbottom",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				50,
				50
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": false,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 803,
			"postHeight": 803,
			"postWidthNews": 803,
			"postHeightNews": 803,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-4",
				"label": "Style 4"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 10,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 5,
			"textureOpacity": 10,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#1590f7",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 20,
			"subtitleMarginNews": 20,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 0,
			"boxGlowColor": "#ffffff",
			"lineHeightNews": 0,
			"lineHeightTextNews": 1.3
		},
		"teamoverrides": [],
		"draggables": [
			{
				"boxWidth": 396,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"date": "2024-06-05T03:17:24.168Z",
		"name": [],
		"stat": [],
		"savedTitle": "",
		"_id": "665fd8c432e615fb67bf7cdf"
	},
	// news type
	// mbappe (soccer)
	{
		"title": "Breaking La Liga",
		"sport": "soccer",
		"newstitle": "BREAKING",
		"thumbnail": "/template24.jpeg",
		"newssubtitle": "Real Madrid sign Kylian Mbappe",
		"newsimage": "https://media.cnn.com/api/v1/images/stellar/prod/gettyimages-2151947579.jpg?c=16x9&q=h_833,w_1480,c_fill",
		"newsteam": "mad",
		"settings": {
			"type": "news",
			"headerSize": 19,
			"gradientPerc": 32,
			"gradientPerc1": 0,
			"gradientPerc2": 100,
			"direction": "to left",
			"nameSize": 23,
			"statSize": 23,
			"logoOrder": 0,
			"logoOpacity": 100,
			"logoSize": 110,
			"headerMargin": -1,
			"headerSpacing": 0,
			"nameMargin": 3,
			"headerFont": "roboto bold",
			"nameFont": "roboto bold",
			"statFont": "roboto condensed",
			"headerColor": "#000000",
			"headerFontColor": "#ffffff",
			"watermark": "",
			"watermarkFont": "exo",
			"watermarkSize": 28,
			"watermarkWidth": 80,
			"statMargin": 0,
			"watermarkColor": "#ffffff",
			"nameShadow": 0,
			"borderColor": "#ffffff",
			"nameSpacing": 0,
			"statSpacing": -2,
			"statShadow": 0,
			"logoPosition": 292,
			"overlap": false,
			"statMargin2": 0,
			"lineHeight": 0.9,
			"nameBackground": "#000000",
			"statBackground": "#000000",
			"nameColor": "#ffffff",
			"statColor": "#ffffff",
			"imageHorizontalNews": 0,
			"imageVerticalNews": 0,
			"imageZoomNews": 100,
			"headerSizeNews": 20,
			"headerFontNews": "roboto bold",
			"headerMarginNews": 0,
			"headerSpacingNews": 0,
			"headerColorNews": "#ffffff",
			"headerFontColorNews": "#000000",
			"logoOpacityNews": 100,
			"gradientPercNews": 61,
			"gradientPerc1News": 41,
			"gradientPerc2News": 100,
			"directionNews": "to right",
			"nameColorNews": "#ffffff",
			"logoSizeNews": 537,
			"orientation": "leftright",
			"orientationNews": "rightleft",
			"orientationMatchup": "topbottom",
			"orientationHighlight": "leftright",
			"orientationLineup": "leftright",
			"borderRadius": 5,
			"scoreMargin": 5,
			"paneSplit": [
				50,
				50
			],
			"paneSplitNews": [
				32.689912826899125,
				67.31008717310087
			],
			"paneSplitMatchup": [
				80,
				20
			],
			"paneSplitHighlight": [
				50,
				50
			],
			"paneSplitLineup": [
				50,
				50
			],
			"duplicate": true,
			"logoShadow": 0,
			"nameMargin2": 10,
			"postWidth": 803,
			"postHeight": 803,
			"postWidthNews": 803,
			"postHeightNews": 803,
			"tierFont": "roboto condensed",
			"tierColor": "#ffffff",
			"tierSize": 75,
			"duplicateLogoSize": 500,
			"duplicateLogoPosition": 0,
			"duplicateLogoOpacity": 10,
			"gridMargin": 1,
			"logoFlip": true,
			"scoreMinHeight": 100,
			"videoDirection": "fromright",
			"videoReverse": false,
			"headerOutlineColor": "#ffffff",
			"nameOutlineColor": "#ffffff",
			"statOutlineColor": "#ffffff",
			"headerOutlineSize": 0,
			"nameOutlineSize": 0,
			"statOutlineSize": 0,
			"headerTransform": "none",
			"nameTransform": "none",
			"statTransform": "none",
			"nameShadowColor": "#000000",
			"statShadowColor": "#000000",
			"tierTitle": "left",
			"pyramid": false,
			"newsStyle": {
				"value": "style-3",
				"label": "Style 3"
			},
			"postBackground": null,
			"titleHeight": 10,
			"subtitleFontSize": 9.5,
			"subtitleMargin": 0,
			"subtitleSpacing": 0,
			"subtitleFont": "roboto condensed",
			"subtitleFontColor": "#ffffff",
			"subtitleOutlineColor": "#ffffff",
			"subtitleOutlineSize": 0,
			"subtitleTransform": "none",
			"backgroundColor": "single",
			"backgroundGradient1": "#013369",
			"backgroundGradient2": "#D50A0A",
			"backgroundGradientPerc": 0,
			"backgroundGradientPerc1": 50,
			"backgroundGradientPerc2": 100,
			"backgroundGradientDirection": "to bottom",
			"postColumns": null,
			"gridColumns": null,
			"tierSplit": 8,
			"fivelessBreak": 8,
			"helmets": false,
			"rankSize": 23,
			"rankFont": "roboto condensed",
			"rankFontColor": "#ffffff",
			"rankMargin": 0,
			"rankSpacing": 0,
			"rankOutlineSize": 2,
			"rankOutlineColor": "#000000",
			"logoSizeGrid": 100,
			"logoSizeTier": 100,
			"pointsSize": 20,
			"pointsFont": "roboto condensed",
			"pointsFontColor": "#ffffff",
			"pointsMargin": 0,
			"pointsMargin2": 0,
			"pointsSpacing": -1.2,
			"pointsOutlineSize": 0,
			"pointsOutlineColor": "#000000",
			"matchupPointsBackground": "#00000000",
			"pointsBackground": "#000000",
			"playerHeight": 1,
			"statLineHeight": 1,
			"indexAxis": "y",
			"chartType": {
				"value": "bar",
				"label": "Bar Chart"
			},
			"labelSize": 14,
			"labelColor": "#ffffff",
			"labelFont": "roboto condensed",
			"chartBorderColor": "#ffffff",
			"chartBorderWidth": 0.5,
			"chartLogoSize": 75,
			"playerMargin": 0,
			"lighterLogos": false,
			"darkMode": null,
			"texture": null,
			"textureSize": 2,
			"textureOpacity": 13,
			"matchupRankBackground": "#00000000",
			"rankBackground": "#000000",
			"boxMargin": 0,
			"teamBackground": "gradient",
			"teamColor": "#3538e3",
			"actualHeaderColor": "#00000000",
			"rankWidth": 40,
			"rankMargin2": 0,
			"boxRankOutlineSize": 0,
			"borderSize": 2,
			"headerBorderSize": 2,
			"headerBorderColor": "#ffffff",
			"boxImageBackground": "none",
			"logoDropShadow": 0,
			"footerHeight": 30,
			"footerSpacing": 0,
			"footerMargin": 0,
			"footerFont": "roboto condensed",
			"footerSize": 20,
			"footerFontColor": "#ffffff",
			"footerBackground": "#000000",
			"footerOutlineSize": 0,
			"footerOutlineColor": "#000000",
			"hasFooter": false,
			"footerText": "",
			"footerAlignment": "center",
			"tierBackground": "#00000000",
			"statHighlight": false,
			"topHighlight": "#0eff00",
			"bottomHighlight": "#ff0000",
			"subtitleSizeNews": 31,
			"subtitleMarginNews": 15,
			"subtitleSpacingNews": 0,
			"subtitleFontNews": "roboto condensed",
			"matchupStyle": {
				"value": "style-1",
				"label": "Style 1"
			},
			"imageShadowColor": "#000000",
			"imageShadowSize": 20,
			"descriptionSize": 25,
			"descriptionMargin": 0,
			"descriptionSpacing": 0,
			"descriptionFont": "roboto condensed",
			"descriptionFontColor": "#ffffff",
			"descriptionOutlineColor": "#000000",
			"descriptionOutlineSize": 2,
			"descriptionTransform": "none",
			"comparisonRadius": 10,
			"comparisonMargin": 20,
			"comparisonBorderSize": 2,
			"comparisonBorderColor": "#ffffff",
			"labelsSize": 20,
			"labelsMargin": 6,
			"labelsSpacing": 0,
			"labelsFont": "roboto condensed",
			"labelsFontColor": "#ffffff",
			"labelsBackground": "#000000",
			"labelsTransform": "none",
			"boxGlowWidth": 0,
			"boxGlowColor": "#ffffff",
			"lineHeightNews": 0,
			"lineHeightTextNews": 1.3
		},
		"teamoverrides": [],
		"draggables": [
			{
				"boxWidth": 456,
				"xvalue": "0",
				"yvalue": "0",
				"width": "auto",
				"height": "auto"
			}
		],
		"floatingTexts": [],
		"date": "2024-06-05T03:26:04.037Z",
		"name": [],
		"stat": [],
		"savedTitle": "",
		"_id": "665fdaccbde6acc4f0a159e1"
	}
];
