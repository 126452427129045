import Draggable from 'react-draggable';
import {Resizable} from 're-resizable';
import useSettingsStore from '../../stores/settings.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';
import useComparisonStore from '../../stores/posts/comparison.store';

export default function ComparisonFormat(props) {
	const { leftName, rightName, leftTeam, rightTeam, leftStats, rightStats, leftImage, rightImage, statLabels } = useComparisonStore();
	const { settings } = useSettingsStore();
	const { individuallyEdit } = useGlobalStore();
	const { sport } = useUserStore();

	let leftBoxImage, leftBoxBackground, leftDirection, leftImagy;
	let leftTeamy = leftTeam.toUpperCase();
	let leftTeamObj;
	if (leftTeamy) {
		leftTeamObj = props.getTeamObj(leftTeamy, sport);
		leftDirection = leftTeamObj?.direction;
		if (leftTeamObj) {
			leftBoxImage = (
				<div
					className={`boximage`}
					style={{backgroundImage: leftTeamObj?.logo, backgroundSize: settings.logoSizeGrid === 100 ? 'cover' : `${settings.logoSizeGrid}%`}}></div>
			);
			leftBoxBackground = props.getBoxBackground(leftTeamObj, true, leftDirection);
		} else {
			leftBoxBackground = 'transparent';
		}
	}
	if (leftImage) {
		leftImagy = props.getImg(leftImage)[0];
	}

	let rightBoxImage, rightBoxBackground, rightDirection, rightImagy;
	let rightTeamy = rightTeam.toUpperCase();
	let rightTeamObj;
	if (rightTeamy) {
		rightTeamObj = props.getTeamObj(rightTeamy, sport);
		rightDirection = rightTeamObj?.direction;
		if (rightTeamObj) {
			rightBoxImage = (
				<div
					className={`boximage`}
					style={{backgroundImage: rightTeamObj?.logo, backgroundSize: settings.logoSizeGrid === 100 ? 'cover' : `${settings.logoSizeGrid}%`}}></div>
			);
			rightBoxBackground = props.getBoxBackground(rightTeamObj, true, rightDirection);
		} else {
			rightBoxBackground = 'transparent';
		}
	}
	if (rightImage) {
		rightImagy = props.getImg(rightImage)[0];
	}
	return (
		<div className='comparisonSplit'>
			{leftTeamObj && (<div className='comparisonLeft' style={{background: leftBoxBackground}}>
			{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
				<div className={`box drag-container candrag${individuallyEdit}`} style={{backgroundColor: leftTeamObj?.primary}}>
					<div className={`boximagecontainer`}>{leftBoxImage}</div>
					{leftImagy && (
						<div className='playerImageContainer'>
							<Draggable disabled={!individuallyEdit} onStop={() => props.setDraggables()}>
								<Resizable
									lockAspectRatio={true}
									enable={{
										top: individuallyEdit,
										right: individuallyEdit,
										bottom: individuallyEdit,
										left: individuallyEdit,
										topRight: individuallyEdit,
										bottomRight: individuallyEdit,
										bottomLeft: individuallyEdit,
										topLeft: individuallyEdit,
									}}>
									<img
										src={leftImagy}
										draggable='false'
										alt=''
										className='playerimage'></img>
								</Resizable>
							</Draggable>
						</div>
					)}
				</div>
				{leftName && 
					<div className='leftName'
						onClick={(e) => {
							props.selectSettings('name');
						}}>
							{leftName}
					</div>
				}
				{leftStats && (
					<div className='comparisonStatGroup'>
						{leftStats.map((text, i) => {
							return (
								<span
									className='comparisonStat leftStat'
									key={i}
									onClick={(e) => {
										props.selectSettings('stat');
									}}>
									{text}
								</span>
							);
						})}
					</div>
				)}
			</div>)}
			{rightTeamObj && (<div className='comparisonRight' style={{background: rightBoxBackground}}>
			{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
				<div className={`box drag-container candrag${individuallyEdit}`} style={{backgroundColor: rightTeamObj?.primary}}>
					<div className={`boximagecontainer`}>{rightBoxImage}</div>
					{rightImagy && (
						<div className='playerImageContainer'>
							<Draggable disabled={!individuallyEdit} onStop={() => props.setDraggables()}>
								<Resizable
									lockAspectRatio={true}
									enable={{
										top: individuallyEdit,
										right: individuallyEdit,
										bottom: individuallyEdit,
										left: individuallyEdit,
										topRight: individuallyEdit,
										bottomRight: individuallyEdit,
										bottomLeft: individuallyEdit,
										topLeft: individuallyEdit,
									}}>
									<img
										src={rightImagy}
										draggable='false'
										alt=''
										className='playerimage'></img>
								</Resizable>
							</Draggable>
						</div>
					)}
				</div>
				{rightName && 
					<div className='rightName'
						onClick={(e) => {
							props.selectSettings('name');
						}}>
							{rightName}
					</div>
				}
				{rightStats && (
					<div className='comparisonStatGroup'>
						{rightStats.map((text, i) => {
							return (
								<span
									className='comparisonStat rightStat'
									key={i}
									onClick={(e) => {
										props.selectSettings('stat');
									}}>
									{text}
								</span>
							);
						})}
						<div className='comparisonStatLabels'>
							{statLabels.map((text, i) => {
								return (
									<span
										className='statLabels'
										style={{backgroundColor: text === '' ? 'transparent' : settings.labelsBackground}}
										key={i}
										onClick={(e) => {
											props.selectSettings('labels');
										}}>
										{text === '' ? ' ' : text}
									</span>
								);
							})}
						</div>
					</div>
				)}
			</div>)}
		</div>
	);
}
