import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import useUserStore from '../stores/user.store';

export default function Lock(props) {
	const { lockedSettings, setLockedSettings } = useUserStore();

	const toggleLockedSetting = (setting) => {
		let lockedSettingsCopy = [...lockedSettings];
		const index = lockedSettingsCopy.indexOf(setting);
		if (index >= 0) {
			lockedSettingsCopy.splice(index, 1);
			setLockedSettings(lockedSettingsCopy);
		} else {
			lockedSettingsCopy.push(setting);
			setLockedSettings(lockedSettingsCopy);
		}
		localStorage.setItem('lockedSettings', JSON.stringify(lockedSettingsCopy));
	};

	return (
		<span className='flexRow'>
			{props.title && <h5>{props.title}</h5>}
			<Tippy content={lockedSettings.includes(props.setting) ? `Unlock ${props.title ?? props.tooltip ?? ''}` : `Lock ${props.title ?? props.tooltip ?? ''}`}>
				<span
					className={!props.tooltip ? '' : props.lockedSettings.includes(props.setting) ? 'blackBackground2' : 'blackBackground'}
					onClick={() => toggleLockedSetting(props.setting)}>
					<i className={props.lockedSettings.includes(props.setting) ? `fa-solid fa-lock` : `fa-solid fa-lock-open`}></i>
				</span>
			</Tippy>
		</span>
	);
}
