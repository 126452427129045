import axios from 'axios';
import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {toast} from 'react-toastify';
import useModalStore from '../../stores/modal.store';
import useUserStore from '../../stores/user.store';
import useGlobalStore from '../../stores/global.store';

export default function FolderModal(props) {
	const [name, setName] = useState('');
	const { toggleModal } = useModalStore();
	const { folders, setFolders } = useUserStore();
	const { renameFolder, setRenameFolder } = useGlobalStore();

	useEffect(() => {
		if (renameFolder.label) {
			setName(renameFolder.label);
		}
	}, []);

	const save = (e) => {
		e.preventDefault();
		toggleModal('folder');
		if (renameFolder.value) {
			axios
				.put(`/folders/rename/${renameFolder.value}`, {
					name
				})
				.then((response) => {
					if (response.status === 200) {
						toast.success('Folder Updated!', {autoClose: 1500});
					}
					let foldersCopy = [...folders];
					let existingFolder = foldersCopy.find((folder) => {
						return folder._id === renameFolder.value;
					});
					existingFolder.name = name;
					setFolders(foldersCopy);
					setRenameFolder({});
				});
		} else {
			axios
			.post(`/folders/save`, {
				email: props.user.email,
				user_id: props.user._id,
				name,
			})
			.then((response) => {
				if (response.status === 200) {
					toast.success('Folder Saved!', {autoClose: 1500});
				}
				setFolders([...folders, response.data[0]]);
			});
		}
	};

	const close = () => {
		setRenameFolder({});
		toggleModal('folder');
	}

	return (
		<Modal
			isOpen={true}
			onRequestClose={() => close()}>
			<div className='modal'>
				<div className='modal-dialog'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>{renameFolder ? 'Rename Folder' : 'Create Folder'}</h5>
							<span
								className='close-modal'
								onClick={() => close()}>
								X
							</span>
						</div>
						<div className='modal-body'>
							<form onSubmit={save}>
								<div className='form-group'>
									<label className='form-label'>Name</label>
									<div className='input-group'>
										<input
											type='text'
											required
											maxLength='25'
											autoFocus
											className='form-control'
											value={name}
											onChange={(e) => {
												setName(e.target.value);
											}}
										/>
									</div>
								</div>
								<button
									type='submit'
									className='btn btn-primary btn-block'>
									Save
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}
