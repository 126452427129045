export const colorOptions = [
	{value: 'none', label: 'None'},
	{value: 'single', label: 'Single Color'},
	{value: 'primary', label: 'Team Primary Color'},
	{value: 'secondary', label: 'Team Secondary Color'},
	{value: 'primaryDarker', label: 'Team Primary Darker'},
	{value: 'secondaryDarker', label: 'Team Secondary Darker'},
	{value: 'brighter', label: 'Brightest Team Color'},
	{value: 'darker', label: 'Darkest Team Color'},
];
