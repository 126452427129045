export const fiveSettings = {
	direction: 'to left',
	headerSize: 20,
	gradientPerc: 30,
	nameSize: 25,
	statSize: 12,
	logoOrder: -2,
	logoOpacity: 100,
	logoSize: 30,
	duplicateLogoSize: 385,
	headerMargin: 0,
	headerSpacing: 0,
	nameMargin: 2,
	headerFont: 'roboto bold',
	nameFont: 'roboto condensed',
	statFont: 'league gothic',
	headerColor: '#000000',
	headerFontColor: '#ffffff',
	watermarkFont: 'exo',
	watermarkSize: 28,
	watermarkWidth: 82,
	statMargin: 5,
	watermarkColor: '#ffffff',
	nameShadow: 0,
	borderColor: '#ffffff',
	nameSpacing: -3,
	statSpacing: 0,
	statShadow: 0,
	type: 'post',
	logoPosition: 292,
	overlap: false,
	statMargin2: -7,
	lineHeight: 0.9500000000000001,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 100,
	headerSizeNews: 20,
	headerFontNews: 'roboto condensed',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 100,
	gradientPercNews: 50,
	directionNews: 'to bottom',
	nameColorNews: '#ffffff',
	logoSizeNews: 500,
	orientationNews: 'leftright',
	borderRadius: 5,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	duplicate: true,
	logoShadow: 10,
	nameMargin2: -8,
	watermark: 'BSBL\nSTAT\nPAGE',
	postWidth: 1080,
	postHeight: 1080,
	postWidthNews: 800,
	postHeightNews: 800,
	tierFont: 'roboto condensed',
	tierColor: '#ffffff',
	tierSize: 75,
	nameTransform: 'uppercase',
	pointsSize: 9,
	lighterLogos: false,
};
