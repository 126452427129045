export const quick8 = {
	type: 'post',
	headerSize: 20,
	gradientPerc: 61,
	gradientPerc1: 0,
	gradientPerc2: 100,
	direction: 'to right',
	nameSize: 18,
	statSize: 18,
	logoOrder: 1,
	logoOpacity: 79,
	logoSize: 46,
	headerMargin: 0,
	headerSpacing: 0,
	nameMargin: 4,
	headerFont: 'montserrat',
	nameFont: 'league gothic',
	statFont: 'bebas neue',
	headerColor: '#82973a',
	headerFontColor: '#ffffff',
	watermark: '',
	watermarkFont: 'roboto condensed',
	watermarkSize: 30,
	watermarkWidth: 75,
	statMargin: 3,
	watermarkColor: '#ffffff',
	nameShadow: 1,
	borderColor: '#ffffff',
	nameSpacing: 0,
	statSpacing: 0,
	statShadow: 0,
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 132,
	headerSizeNews: 20,
	headerFontNews: 'graduate',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 94,
	gradientPercNews: 70,
	gradientPerc1News: 53,
	gradientPerc2News: 93,
	directionNews: 'to bottom',
	nameColorNews: '#ffffff',
	logoSizeNews: 360,
	orientation: 'leftright',
	orientationNews: 'leftright',
	orientationMatchup: 'topbottom',
	borderRadius: 16,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	paneSplitMatchup: [80, 20],
	duplicate: true,
	logoShadow: 3,
	nameMargin2: 10,
	postWidth: 799.26,
	postHeight: 799.26,
	postWidthNews: 799.26,
	postHeightNews: 799.26,
	tierFont: 'metropolis',
	tierColor: '#ffffff',
	tierSize: 66,
	duplicateLogoSize: 411,
	duplicateLogoPosition: 371,
	duplicateLogoOpacity: 10,
	gridMargin: 1,
	logoFlip: true,
	scoreMinHeight: 100,
	videoDirection: 'fromright',
	videoReverse: false,
	headerOutlineColor: '#ffffff',
	nameOutlineColor: '#ffffff',
	statOutlineColor: '#ffffff',
	headerOutlineSize: 1,
	nameOutlineSize: 0,
	statOutlineSize: 0,
	headerTransform: 'uppercase',
	nameTransform: 'uppercase',
	statTransform: 'none',
	nameShadowColor: '#000000',
	statShadowColor: '#000000',
	tierTitle: 'left',
	pyramid: false,
	newsStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	postBackground: null,
	titleHeight: 10,
	subtitleFontSize: 10,
	subtitleMargin: 0,
	subtitleSpacing: 0,
	subtitleFont: 'open sans',
	subtitleFontColor: '#ffffff',
	subtitleOutlineColor: '#ffffff',
	subtitleOutlineSize: 0,
	subtitleTransform: 'none',
	backgroundColor: 'gradient',
	backgroundGradient1: '#90064e',
	backgroundGradient2: '#4429a2',
	backgroundGradientPerc: 22,
	backgroundGradientPerc1: 57,
	backgroundGradientPerc2: 94,
	backgroundGradientDirection: 'to top',
	postColumns: null,
	gridColumns: null,
	tierSplit: 8,
	fivelessBreak: 8,
	helmets: false,
	rankSize: 26,
	rankFont: 'roboto condensed',
	rankFontColor: '#ffffff',
	rankMargin: 0,
	rankSpacing: 0,
	rankOutlineSize: 2,
	rankOutlineColor: '#000000',
	logoSizeGrid: 100,
	logoSizeTier: 100,
	pointsSize: 20,
	pointsFont: 'roboto condensed',
	pointsFontColor: '#ffffff',
	pointsMargin: 0,
	pointsSpacing: -1.2,
	pointsOutlineSize: 0,
	pointsOutlineColor: '#000000',
	pointsBackground: '#000000',
	playerHeight: 1,
	statLineHeight: 1,
	indexAxis: 'y',
	chartType: {
		value: 'bar',
		label: 'Bar Chart',
	},
	labelSize: 14,
	labelColor: '#ffffff',
	labelFont: 'lemon milk light',
	chartBorderColor: '#ffffff',
	chartBorderWidth: 3,
	chartLogoSize: 90,
	playerMargin: 0,
	lighterLogos: false,
	texture: null,
	textureSize: 4,
	textureOpacity: 21,
	rankBackground: '#000000',
	boxMargin: 9,
	teamBackground: 'gradient',
	teamColor: '#2515b7',
	actualHeaderColor: '#00000000',
	rankWidth: 49,
	rankMargin2: 0,
	boxRankOutlineSize: 0,
	borderSize: 0,
	headerBorderSize: 0,
	darkMode: 'dark2',
	headerBorderColor: '#ffffff',
	boxImageBackground: 'none',
};
