export const baseSettings = {
	type: 'post',
	headerSize: 20,
	gradientPerc: 50,
	gradientPerc1: 0,
	gradientPerc2: 100,
	direction: 'to right',
	nameSize: 24,
	statSize: 23,
	logoOrder: 0,
	logoOpacity: 100,
	logoSize: 60,
	headerMargin: 0,
	headerSpacing: 0,
	nameMargin: 0,
	headerFont: 'roboto condensed',
	nameFont: 'roboto condensed',
	statFont: 'roboto condensed',
	headerColor: '#000000',
	headerFontColor: '#ffffff',
	watermark: '',
	watermarkFont: 'roboto condensed',
	watermarkSize: 30,
	watermarkWidth: 75,
	statMargin: 0,
	watermarkColor: '#ffffff',
	nameShadow: 0,
	borderColor: '#ffffff',
	nameSpacing: 0,
	statSpacing: 0,
	statShadow: 0,
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 100,
	headerSizeNews: 20,
	headerFontNews: 'roboto condensed',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 100,
	gradientPercNews: 50,
	gradientPerc1News: 0,
	gradientPerc2News: 100,
	directionNews: 'to bottom',
	nameColorNews: '#ffffff',
	logoSizeNews: 500,
	orientation: 'leftright',
	orientationNews: 'leftright',
	orientationMatchup: 'topbottom',
	orientationHighlight: 'leftright',
	orientationLineup: 'leftright',
	borderRadius: 5,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	paneSplitMatchup: [80, 20],
	paneSplitHighlight: [50, 50],
	paneSplitLineup: [50, 50],
	duplicate: false,
	logoShadow: 0,
	nameMargin2: 10,
	postWidth: 800,
	postHeight: 800,
	postWidthNews: 800,
	postHeightNews: 800,
	tierFont: 'roboto condensed',
	tierColor: '#ffffff',
	tierSize: 75,
	duplicateLogoSize: 500,
	duplicateLogoPosition: 0,
	duplicateLogoOpacity: 10,
	gridMargin: 1,
	logoFlip: true,
	scoreMinHeight: 100,
	videoDirection: 'fromright',
	videoReverse: false,
	headerOutlineColor: '#ffffff',
	nameOutlineColor: '#ffffff',
	statOutlineColor: '#ffffff',
	headerOutlineSize: 0,
	nameOutlineSize: 0,
	statOutlineSize: 0,
	headerTransform: 'none',
	nameTransform: 'none',
	statTransform: 'none',
	nameShadowColor: '#000000',
	statShadowColor: '#000000',
	tierTitle: 'left',
	pyramid: false,
	newsStyle: {value: 'style-1', label: 'Style 1'},
	postBackground: null,
	titleHeight: 10,
	subtitleFontSize: 10,
	subtitleMargin: 0,
	subtitleSpacing: 0,
	subtitleFont: 'roboto condensed',
	subtitleFontColor: '#ffffff',
	subtitleOutlineColor: '#ffffff',
	subtitleOutlineSize: 0,
	subtitleTransform: 'none',
	backgroundColor: 'single',
	backgroundGradient1: '#013369',
	backgroundGradient2: '#D50A0A',
	backgroundGradientPerc: 0,
	backgroundGradientPerc1: 50,
	backgroundGradientPerc2: 100,
	backgroundGradientDirection: 'to bottom',
	postColumns: null,
	gridColumns: null,
	tierSplit: 8,
	fivelessBreak: 8,
	helmets: false,
	rankSize: 23,
	rankFont: 'roboto condensed',
	rankFontColor: '#ffffff',
	rankMargin: 0,
	rankSpacing: 0,
	rankOutlineSize: 2,
	rankOutlineColor: '#000000',
	logoSizeGrid: 100,
	logoSizeTier: 100,
	pointsSize: 20,
	pointsFont: 'roboto condensed',
	pointsFontColor: '#ffffff',
	pointsMargin: 0,
	pointsMargin2: 0,
	pointsSpacing: -1.2,
	pointsOutlineSize: 0,
	pointsOutlineColor: '#000000',
	matchupPointsBackground: '#00000000',
	pointsBackground: '#000000',
	playerHeight: 1,
	statLineHeight: 1,
	indexAxis: 'y',
	chartType: {value: 'bar', label: 'Bar Chart'},
	labelSize: 14,
	labelColor: '#ffffff',
	labelFont: 'roboto condensed',
	chartBorderColor: '#ffffff',
	chartBorderWidth: 0.5,
	chartLogoSize: 75,
	playerMargin: 0,
	lighterLogos: false,
	darkMode: null,
	texture: null,
	textureSize: 5,
	textureOpacity: 10,
	matchupRankBackground: '#00000000',
	rankBackground: '#000000',
	boxMargin: 0,
	teamBackground: 'gradient',
	teamColor: '#1590f7',
	actualHeaderColor: '#00000000',
	rankWidth: 40,
	rankMargin2: 0,
	boxRankOutlineSize: 0,
	borderSize: 2,
	headerBorderSize: 2,
	headerBorderColor: '#ffffff',
	boxImageBackground: 'none',
	logoDropShadow: 0,
	footerHeight: 30,
	footerSpacing: 0,
	footerMargin: 0,
	footerFont: 'roboto condensed',
	footerSize: 20,
	footerFontColor: '#ffffff',
	footerBackground: '#000000',
	footerOutlineSize: 0,
	footerOutlineColor: '#000000',
	hasFooter: false,
	footerText: '',
	footerAlignment: 'center',
	tierBackground: '#00000000',
	statHighlight: false,
	topHighlight: '#0eff00',
	bottomHighlight: '#ff0000',
	subtitleSizeNews: 20,
	subtitleMarginNews: 20,
	subtitleSpacingNews: 0,
	subtitleFontNews: 'roboto condensed',
	matchupStyle: {value: 'style-1', label: 'Style 1'},
	imageShadowColor: '#000000',
	imageShadowSize: 20,
	descriptionSize: 25,
	descriptionMargin: 0,
	descriptionSpacing: 0,
	descriptionFont: 'roboto condensed',
	descriptionFontColor: '#ffffff',
	descriptionOutlineColor: '#000000',
	descriptionOutlineSize: 2,
	descriptionTransform: 'none',
	comparisonRadius: 10,
	comparisonMargin: 20,
	comparisonBorderSize: 2,
	comparisonBorderColor: '#ffffff',
	labelsSize: 20,
	labelsMargin: 6,
	labelsSpacing: 0,
	labelsFont: 'roboto condensed',
	labelsFontColor: '#ffffff',
	labelsBackground: '#000000',
	labelsTransform: 'none',
	boxGlowWidth: 0,
	boxGlowColor: '#ffffff',
	lineHeightNews: 0,
	lineHeightTextNews: 1.3,
	imageTitleColor: '#ffffff',
	backgroundText: 'STATS',
	imageLinesColor: 'secondary',
	badgeLineHeightNews: 0,
	titleBackground: 'secondary',
	textBackground: 'secondary',
	teamContainerBackground: 'primary',
	teamBackgroundLeft: 'secondary',
	teamColorLeft: '#1590f7',
	imageStyle: {value: 'style-1', label: 'Style 1'},
	logoVerticalMargin: 0,
	logoHorizontalMargin: 0,
	logoVerticalMarginNews: 0,
	logoHorizontalMarginNews: 0,
	line1size: 100,
	line2size: 100,
	line3size: 100,
	line1sizestat: 100,
	line2sizestat: 100,
	line3sizestat: 100,
	nameAlignment: 'fill',
	statAlignment: 'fill',
	unevenTiers: false,
	footerPadding: 0,
};
