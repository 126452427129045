import moment from 'moment';
import { create } from 'zustand';

export interface PlayerObject {
    id: string;
    name: string;
    sport: string;
    team: string;
    position?: string;
    skillPosition?: boolean;
}

interface GlobalStore {
    loading: boolean;
    graphicMap: boolean;
    prefilled: boolean;
    teamAbbrs: any;
    teamOptions: any;
    playerOptions: any;
    showImage: boolean;
    editTeam: any;
    pendingDelete: any;
    pendingMove: any;
    playerIds: PlayerObject[];
    individuallyEdit: boolean;
    sizeMultiplier: number;
    statFilter: string;
    openEditors: any;
    inlineEditor: any;
    week: string;
    isDownloading: boolean;
    isPrefilling: boolean;
    forceGraphicUpdate: number;
    date: any;
    expanded: string;
    draggables: any;
    hasDraggables: boolean;
    savedPostPage: number;
    renameFolder: any;
    renamePost: any;
    debug: boolean;
    lastSidebar: string;
    setLoading: (newValue: boolean) => void;
    setGraphicMap: (newValue: boolean) => void;
    setPrefilled: (newValue: boolean) => void;
    setTeamAbbrs: (newValue: any) => void;
    setTeamOptionsStore: (newValue: any) => void;
    setPlayerOptions: (newValue: any) => void;
    setShowImage: (newValue: boolean) => void;
    setEditTeam: (newValue: any) => void;
    setPendingDelete: (newValue: any) => void;
    setPendingMove: (newValue: any) => void;
    setPlayerIds: (newValue: PlayerObject[]) => void;
    setIndividuallyEdit: (newValue: boolean) => void;
    setSizeMultiplier: (newValue: number) => void;
    setStatFilter: (newValue: string) => void;
    setOpenEditors: (newValue: any) => void;
    setInlineEditor: (newValue: any) => void;
    setWeek: (newValue: string) => void;
    setIsDownloading: (newValue: boolean) => void;
    setIsPrefilling: (newValue: boolean) => void;
    setForceGraphicUpdate: () => void;
    setForceGraphicUpdateAsync: () => Promise<void>;
    setDate: (newValue: any) => void;
    setExpanded: (newValue: string) => void;
    setDraggables: (newValue: any) => void;
    setHasDraggables: (newValue: boolean) => void;
    setSavedPostPage: (newValue: number) => void;
    setRenameFolder: (newValue: any) => void;
    setRenamePost: (newValue: any) => void;
    setDebug: (newValue: boolean) => void;
    setLastSidebar: (newValue: string) => void;
}

const useGlobalStore = create<GlobalStore>((set) => ({
    loading: false,
    graphicMap: true,
    prefilled: false,
    teamAbbrs: [],
    teamOptions: [],
    playerOptions: [],
    showImage: true,
    editTeam: null,
    pendingDelete: {},
    pendingMove: {},
    playerIds: [],
    individuallyEdit: false,
    sizeMultiplier: 1,
    statFilter: 'daily',
    openEditors: null,
    inlineEditor: null,
    week: '',
    isDownloading: false,
    isPrefilling: false,
    forceGraphicUpdate: 0,
    date: moment(),
    expanded: '',
    draggables: [],
    hasDraggables: false,
    savedPostPage: 1,
    renameFolder: {},
    renamePost: {},
    debug: true,
    lastSidebar: '',
    setLoading: (newValue: boolean) => set({ loading: newValue }),
    setGraphicMap: (newValue: boolean) => set({ graphicMap: newValue }),
    setPrefilled: (newValue: boolean) => set({ prefilled: newValue }),
    setTeamAbbrs: (newValue: any) => set({ teamAbbrs: newValue }),
    setTeamOptionsStore: (newValue: any) => set({ teamOptions: newValue }),
    setPlayerOptions: (newValue: any) => set({ playerOptions: newValue }),
    setShowImage: (newValue: boolean) => set({ showImage: newValue }),
    setEditTeam: (newValue: any) => set({ editTeam: newValue }),
    setPendingDelete: (newValue: any) => set({ pendingDelete: newValue }),
    setPendingMove: (newValue: any) => set({ pendingMove: newValue }),
    setPlayerIds: (newValue: PlayerObject[]) => set({ playerIds: newValue }),
    setIndividuallyEdit: (newValue: boolean) => set({ individuallyEdit: newValue }),
    setSizeMultiplier: (newValue: number) => set({ sizeMultiplier: newValue }),
    setStatFilter: (newValue: string) => set({ statFilter: newValue }),
    setOpenEditors: (newValue: any) => set({ openEditors: newValue }),
    setInlineEditor: (newValue: any) => set({ inlineEditor: newValue }),
    setWeek: (newValue: string) => set({ week: newValue }),
    setIsDownloading: (newValue: boolean) => set({ isDownloading: newValue }),
    setIsPrefilling: (newValue: boolean) => set({ isPrefilling: newValue }),
    setForceGraphicUpdate: () => set((state) => ({ forceGraphicUpdate: state.forceGraphicUpdate + 1 })),
    setForceGraphicUpdateAsync: async () => {
        return await new Promise<void>((resolve) => {
          set((state) => ({ forceGraphicUpdate: state.forceGraphicUpdate + 1 }));
          setTimeout(() => {
            resolve();
          }, 100);
        });
    },
    setDate: (newValue: any) => set({ date: newValue }),
    setExpanded: (newValue: string) => set({ expanded: newValue }),
    setDraggables: (newValue: any) => set({ draggables: newValue }),
    setHasDraggables: (newValue: boolean) => set({ hasDraggables: newValue }),
    setSavedPostPage: (newValue: any) => set({ savedPostPage: newValue }),
    setRenameFolder: (newValue: any) => set({ renameFolder: newValue }),
    setRenamePost: (newValue: any) => set({ renamePost: newValue }),
    setDebug: (newValue: boolean) => set({ debug: newValue }),
    setLastSidebar: (newValue: string) => set({ lastSidebar: newValue })
}));

export default useGlobalStore;
