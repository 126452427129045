import axios from 'axios';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {settingsStyles} from '../data/styles/settingsStyles';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {settingsStylesDark} from '../data/styles/settingsStylesDark';
import {Popover} from 'react-tiny-popover';
import {Menu, MenuItem, MenuButton} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import useSettingsStore from '../stores/settings.store';
import usePreferencesStore from '../stores/preferences.store';
import useModalStore from '../stores/modal.store';
import usePostStore from '../stores/posts/base-post.store';
import useWithImageStore from '../stores/posts/with-image.store';
import useUserStore from '../stores/user.store';
import useChartStore from '../stores/posts/chart.store';
import useComparisonStore from '../stores/posts/comparison.store';
import useBracketStore from '../stores/posts/bracket.store';
import useTierStore from '../stores/posts/tier.store';
import useGlobalStore from '../stores/global.store';
import useSidebarStore from '../stores/sidebar.store';

function SavedPostsMap(props) {
	const parent = props.props;
	const {
		savedPostSearch,
		setSavedPostSearch,
		savedPostFilter,
		setSavedPostFilter,
		savedUserFilter,
		setSavedUserFilter,
		savedFolderFilter,
		setSavedFolderFilter,
		settingFilter,
		setSettingFilter,
		settingValueFilter,
		setSettingValueFilter,
		multiSelect,
		setMultiSelect,
		selected,
		setSelected,
		selectedTitles,
		setSelectedTitles,
		selectedFolders,
		setSelectedFolders
	} = useSidebarStore();
	const { settings, replaceSettings } = useSettingsStore();
	const { darkMode } = usePreferencesStore();
	const { toggleModal } = useModalStore();
	const { savedPosts, folders, setFolders, currentPost } = useUserStore();
	const { setRound } = useBracketStore();
	const { setTiers } = useTierStore();
	const { setCurrentPost, setSavedPosts } = useUserStore();
	const { setNewsTitle, setNewsSubtitle, setNewsImage, setNewsTeam } = useWithImageStore();
	const { setLeftName, setRightName, setLeftTeam, setRightTeam, setLeftImage, setRightImage, setLeftStats, setRightStats, setStatLabels } = useComparisonStore();
	const { setTitle, setSubtitle, setName, setTeam, setStat, setPoints, setImage, setRanks, setFloatingTexts, setNameOverrides, setStatOverrides, setNameHighlights, setStatHighlights, setDescription, setTeamSplits } = usePostStore();
	const { setxLabel, setyLabel } = useChartStore();
	const { setForceGraphicUpdate, setPendingDelete, setPendingMove, setExpanded, setDraggables, savedPostPage, setSavedPostPage, setRenameFolder, setRenamePost, debug} = useGlobalStore();

	useEffect(() => {
		if (savedFolderFilter && savedFolderFilter.value) {
			const foundFolder = folders.find((folder) => {
				return folder._id === savedFolderFilter.value;
			});
			if (foundFolder) {
				setSavedFolderFilter({
					value: foundFolder._id,
					label: foundFolder.name
				})
			}
		}
	}, [folders]);

	const removeFromFolder = (item) => {
		let ids = [];
		if (item) {
			ids = [item._id];
		} else {
			ids = selected;
		}
		axios.put(`/folders/move`, {ids, folder: null}).then((response) => {
			if (response.status === 200) {
				const updatedPosts = savedPosts.map((post) => {
					if (ids.includes(post._id)) {
						return {...post, folder: null};
					}
					return post;
				});
				setSavedPosts(updatedPosts);
				toast.success(item ? 'Post removed from folder!' : 'Posts removed from folder!', {autoClose: 1500});
				setSelected([]);
				setSelectedTitles([]);
				setSelectedFolders([]);
				setMultiSelect(false);
			}
		}).catch((e) => {
			toast.error('Failed to remove one or more posts from a folder');
			if (debug) {
				console.log(e);
			}
		});
	};

	const loadPost = async (id) => {
		parent.reset();
		let post;
		if (parent.hideOptions) {
			post = parent.allPosts.find((post) => post._id === id);
		} else {
			post = savedPosts.find((post) => post._id === id);
		}
		if (post.settings.type !== settings.type) {
			await parent.changeType2(post.settings.type);
		}
		if (post.settings.type === 'postimage' || post.settings.type === 'gridimage') {
			if (!post.settings.paneSplit) {
				post.settings.paneSplit = post.settings.paneSplitNews;
			}
			if (!post.settings.orientation) {
				post.settings.orientation = post.settings.orientationNews;
			}
		}
		if (post.settings.type === 'news' || post.settings.type === 'highlightimage' || post.settings.type === 'lineupimage') {
			parent.resetImage(post.settings);
			replaceSettings(post.settings);
			setNewsTitle(post.newstitle);
			setNewsSubtitle(post.newssubtitle);
			setNewsImage(post.newsimage);
			setNewsTeam(post.newsteam);
			parent.sportChange(post.sport);
			setTeamSplits(post.teamsplits);
			setCurrentPost(post);
			setFloatingTexts(post.floatingTexts ? post.floatingTexts : []);
			parent.addToTeamOverrides(post.teamoverrides || []);
			if (post.settings.type === 'lineupimage') {
				setName(post.name);
				setStat(post.stat);
			}
			parent.setTeamOptions(post.sport);
		} else if (post.settings.type === 'comparison') {
			parent.resetImage(post.settings);
			replaceSettings(post.settings);
			setTitle(post.title);
			setSubtitle(post.subtitle);
			setLeftName(post.leftName);
			setRightName(post.rightName);
			setLeftTeam(post.leftTeam);
			setRightTeam(post.rightTeam);
			setLeftImage(post.leftImage);
			setRightImage(post.rightImage);
			setLeftStats(post.leftStats);
			setRightStats(post.rightStats);
			setStatLabels(post.statLabels);
			parent.sportChange(post.sport);
			setTeamSplits(post.teamsplits);
			setCurrentPost(post);
			setFloatingTexts(post.floatingTexts);
			parent.addToTeamOverrides(post.teamoverrides || []);
			parent.setTeamOptions(post.sport);
		} else {
			replaceSettings(post.settings);
			setTitle(post.title);
			setSubtitle(post.subtitle);
			setTeam(post.team);
			setName(post.name);
			setStat(post.stat);
			setPoints(post.points ? post.points : []);
			setImage(post.image);
			parent.sportChange(post.sport);
			setTeamSplits(post.teamsplits ? post.teamsplits : []);
			setCurrentPost(post);
			setRanks(post.ranks ? post.ranks : []);
			setFloatingTexts(post.floatingTexts ? post.floatingTexts : []);
			setxLabel(post.xLabel ? post.xLabel : '');
			setyLabel(post.yLabel ? post.yLabel : '');
			setNameOverrides(post.nameOverrides ? post.nameOverrides : []);
			setStatOverrides(post.statOverrides ? post.statOverrides : []);
			setNameHighlights(post.nameHighlights ? post.nameHighlights : []);
			setStatHighlights(post.statHighlights ? post.statHighlights : []);
			setDescription(post.description ? post.description : '');
			parent.addToTeamOverrides(post.teamoverrides || []);
			parent.setTeamOptions(post.sport);
			if (post.settings.type === 'bracket') {
				setRound('round32', post.round32);
				setRound('round16',post.round16);
				setRound('round8',post.round8);
				setRound('round4',post.round4);
				setRound('round2',post.round2);
				setRound('round1',post.round1);
			} else if (post.settings.type === 'tier') {
				if (post.tiers) {
					setTiers(post.tiers);
				} else {
					setTiers({
						0: 1,
						1: 2,
						2: 3,
						3: 4,
						4: 5,
						5: 6,
						6: 7,
						7: 8,
						8: 9,
						9: 10
					});
				}
			}
			if (post.settings.type.includes('image')) {
				parent.resetImage(post.settings);
				setNewsImage(post.newsimage);
			}
		}
		if (post.settings.type.includes('post') || post.settings.type.includes('grid') || post.settings.type === 'bracket' || post.settings.type === 'scores') {
			if (post.team.includes('#')) {
				let filteredTeam = post.team.filter((value) => value !== '#');
				setTeam(filteredTeam);
			}
		}
		if (post.draggables && post.draggables.length) {
			setDraggables(post.draggables);
			parent.moveDraggables();
		} else {
			parent.resetDraggables();
		}
		window.dispatchEvent(new Event('resize'));
		// force update to get text fit to work properly
		setForceGraphicUpdate();
		if (document.documentElement.clientWidth <= 950) {
			setExpanded('');
		}
	};

	const postButton = (item, i) => {
		let title = item.title ? item.title : item.newstitle ? item.newstitle + ' - ' + item.newsteam : 'Untitled';
		if (item.settings.type === 'matchupimage') {
			title = `${item.team.join(' - ')} Matchup`;
		}
		if (item.savedTitle) {
			title = item.savedTitle;
		}
		if (parent.hideOptions) {
			title += ' ' + item.email;
		}
		return (
			<div
				key={i}
				className='savedpostdiv'>
				<button
					className={currentPost._id === item._id ? 'newButton' : parent.hideOptions ? 'newButtonSecondary Full' : 'newButtonSecondary'}
					id={item._id}
					onClick={() => {
						loadPost(item._id);
					}}>
					{title}
				</button>
				{!parent.hideOptions && !multiSelect && (
					<Menu
						menuButton={
							<MenuButton>
								<i className='fa-solid fa-ellipsis-vertical'></i>
							</MenuButton>
						}>
						{folders.length > 0 && (
							<MenuItem
								onClick={() => {
									setPendingMove({item});
									toggleModal('move-folder');
								}}>
								Move to Folder
							</MenuItem>
						)}
						<MenuItem
							onClick={() => {
								setRenamePost(item);
								toggleModal('rename');
							}}>
							Rename
						</MenuItem>
						{item.folder && <MenuItem onClick={() => removeFromFolder(item)}>Remove From Folder</MenuItem>}
						<MenuItem
							onClick={() => {
								setPendingDelete({item});
								toggleModal('delete-post');
							}}>
							Delete
						</MenuItem>
					</Menu>
				)}
				{multiSelect && (
					<input
						type='checkbox'
						id={'multiCheckbox' + {i}}
						checked={selected.includes(item._id)}
						onChange={() => {
							if (selected.includes(item._id)) {
								setSelected(selected.filter(id => id !== item._id));
								setSelectedTitles(selectedTitles.filter(tit => tit !== title));
								setSelectedFolders(prevIds => {
									const updatedIds = [...prevIds];
									const index = updatedIds.indexOf(item.folder || 0);
									if (index !== -1) {
										updatedIds.splice(index, 1);
									}
									return updatedIds;
								});
							} else {
								setSelected([...selected, item._id]);
								setSelectedTitles([...selectedTitles, title]);
								setSelectedFolders([...selectedFolders, item.folder || 0]);
							}
						}}
				/>
				)}
			</div>
		);
	};

	let postList;
	if (parent.hideOptions) {
		postList = parent.allPosts;
	} else {
		postList = savedPosts;
	}
	const filteredSavedPosts = postList.filter((post) => {
		let title = post.title ? post.title : post.newstitle ? post.newstitle + ' - ' + post.newsteam : 'Untitled';
		if (post.settings.type === 'matchupimage') {
			title = `${post.team.join(' - ')} Matchup`;
		}
		if (post.savedTitle) {
			title = post.savedTitle;
		}
		if (savedPostSearch) {
			if (!title.toLowerCase().includes(savedPostSearch.toLowerCase())) {
				return false;
			}
		}
		if (savedPostFilter && savedPostFilter.length) {
			let typesFilter = savedPostFilter
				.filter((x) => x.type === 'type')
				.map((x) => {
					return x.value;
				});
			let sportsFilter = savedPostFilter
				.filter((x) => x.type === 'sport')
				.map((x) => {
					return x.value;
				});
			if (!typesFilter[0]) typesFilter = [post.settings.type];
			if (!sportsFilter[0]) sportsFilter = [post.sport];
			if (!typesFilter.includes(post.settings.type) || !sportsFilter.includes(post.sport)) {
				return false;
			}
		}
		if (parent.hideOptions && savedUserFilter && savedUserFilter.length) {
			const emails = savedUserFilter.map((x) => x.value);
			if (!emails.includes(post.email)) {
				return false;
			}
		}
		if (parent.hideOptions && settingFilter && settingValueFilter) {
			if (JSON.stringify(post.settings[settingFilter.value]) !== JSON.stringify(settingValueFilter.actualValue)) {
				return false;
			}
		}
		if (savedFolderFilter) {
			if (post.folder !== savedFolderFilter.value) {
				return false;
			}
		} else {
			if (post.folder && !parent.hideOptions) {
				return false;
			}
		}
		return true;
	});
	let pages = [];
	let pageHeight = 800;
	if (document.querySelector('.DashboardContent')) {
		pageHeight = document.querySelector('.DashboardContent').offsetHeight - 320;
	}
	if (document.documentElement.clientWidth <= 950) {
		pageHeight-= 125;
	}
	let maxPerPage = Math.floor(pageHeight / 27);
	let totalPages = Math.ceil(filteredSavedPosts.length / maxPerPage);
	const setPage = (e) => {
		setSavedPostPage(Number(e.target.getAttribute('data-page-num')));
	};
	const filterPosts = (e) => {
		setSavedPostSearch(e.target.value);
		setSavedPostPage(1);
	};
	const removeSearch = () => {
		setSavedPostSearch('');
	};
	const filterType = (selectedOption) => {
		setSavedPostFilter(selectedOption);
		setSavedPostPage(1);
	};
	const filterUsers = (selectedOption) => {
		setSavedUserFilter(selectedOption);
		setSavedPostPage(1);
	};
	const filterFolder = (selectedOption) => {
		setSavedFolderFilter(selectedOption);
		setSavedPostPage(1);
	};
	const filterSetting = (selectedOption) => {
		setSettingValueFilter(null);
		setSettingFilter(selectedOption);
		setSavedPostPage(1);
	};
	const filterSettingValue = (selectedOption) => {
		setSettingValueFilter(selectedOption);
		setSavedPostPage(1);
	};
	for (var i = 1; i < totalPages + 1; i++) {
		pages.push(
			<span
				key={i}
				className={`${savedPostPage === i ? 'page-selected' : 'page-not-selected'}`}
				data-page-num={i}
				onClick={setPage}>
				{i}
			</span>
		);
	}
	const postTypes = postList.map((sp) => sp.settings.type);
	const uniqueTypes = [...new Set(postTypes)];
	let mappedUsers = [];
	let mappedSettings = [];
	let mappedSettingValues = [];
	if (parent.hideOptions) {
		const postUsers = postList.map((sp) => sp.email);
		const uniqueUsers = [...new Set(postUsers)];
		let frequencies = {};
		for (let val of uniqueUsers) {
			frequencies[val] = 0;
		}
		postList.map((val) => frequencies[val.email]++);
		const sortedUniqueUsers = uniqueUsers.sort((a, b) => {
			return frequencies[b] - frequencies[a];
		});
		sortedUniqueUsers.map((x) => {
			return mappedUsers.push({value: x, label: x});
		});
		const uniqueKeys = new Set();
		postList.forEach(post => {
			Object.keys(post.settings).forEach(key => uniqueKeys.add(key));
		});
		for (let val of uniqueKeys) {
			mappedSettings.push({value: val, label: val});
		}
		if (settingFilter) {
			const uniqueValues = new Map();
			postList.forEach(post => {
			  if (post.settings && post.settings[settingFilter.value] !== undefined) {
				const value = post.settings[settingFilter.value];
				const serializedValue = JSON.stringify(value);
				if (!uniqueValues.has(serializedValue)) {
				  uniqueValues.set(serializedValue, value);
				}
			  }
			});
		  
			for (let [serializedValue, actualValue] of uniqueValues) {
			  mappedSettingValues.push({
				value: serializedValue,
				label: JSON.stringify(actualValue),
				actualValue: actualValue
			  });
			}
		  }
	}

	let mappedTypes = [];
	if (parent.user.plan === 'all') {
		const postSports = postList.map((sp) => sp.sport);
		const uniqueSports = [...new Set(postSports)];
		uniqueSports.map((x) => {
			if (x) {
				return mappedTypes.push({value: x, label: x.toUpperCase(), type: 'sport'});
			}
			return null;
		});
	}
	let labels = {
		post: 'List',
		postimage: 'List With Image',
		grid: 'Grid',
		gridimage: 'Grid With Image',
		tier: 'Tiers',
		bracket: 'Bracket',
		scores: 'Scores',
		matchupimage: 'Matchup',
		news: 'News/Quote',
		chart: 'Chart',
		highlightimage: 'Highlight',
		lineupimage: 'Lineup',
		comparison: 'Comparison',
	};
	uniqueTypes.map((x) => {
		return mappedTypes.push({value: x, label: labels[x], type: 'type'});
	});
	let mappedFolders = folders.map((folder) => {
		return {value: folder._id, label: folder.name};
	});
	const page = savedPostPage;
	let savedPostsSlice = filteredSavedPosts.slice(page * maxPerPage - maxPerPage, page * maxPerPage);
	if (!savedPostsSlice.length && filteredSavedPosts.length) {
		setSavedPostPage(savedPostPage - 1);
	}
	let shouldShowRemoveFromFolder = false;
	if (selectedFolders.length > 0) {
		const firstFolder = selectedFolders[0];
		shouldShowRemoveFromFolder = selectedFolders.every((folder) => folder === firstFolder && folder !== 0);
	}
	return (
		<div className='savedPostsLists'>
			<div className='sidePanelHeader'>
				<h5>Saved Posts</h5>
				<div style={{display: 'flex'}}>
					{!parent.hideOptions && postList.length > 4 && (<div className="form-check form-switch">
						<input
							className={`form-check-input ${multiSelect ? 'custom-switch-checked' : 'custom-switch-unchecked'}`}
							type="checkbox"
							role="switch"
							id="flexSwitchCheckDefault"
							checked={multiSelect}
							onChange={(e) => {
								setSelected([]);
								setSelectedTitles([]);
								setSelectedFolders([]);
								setMultiSelect(!multiSelect);
							}}/>
						<label
							className="form-check-label"
							style={!multiSelect || selected.length === 0 ? {marginRight: '29px'} : {}}
							htmlFor="flexSwitchCheckDefault">
								Multi-select
						</label>
					</div>
					)}
					{!parent.hideOptions && multiSelect && selected.length > 0 && (
						<Menu
							menuButton={
								<MenuButton>
									<i className='fa-solid fa-ellipsis-vertical'></i>
								</MenuButton>
							}>
							{folders.length > 0 && (
								<MenuItem
									onClick={() => {
										setPendingMove({selected});
										toggleModal('move-folder');
										setSelected([]);
										setSelectedTitles([]);
										setSelectedFolders([]);
									}}>
									Move All To Folder
								</MenuItem>
							)}
							{shouldShowRemoveFromFolder && (
								<MenuItem
									onClick={() => removeFromFolder()}>
										Remove All From Folder
								</MenuItem>
							)}
							<MenuItem
								onClick={() => {
									setPendingDelete({selected, selectedTitles});
									toggleModal('delete-post');
									setSelected([]);
									setSelectedTitles([]);
									setSelectedFolders([]);
								}}>
								Delete All
							</MenuItem>
						</Menu>
					)}
				</div>
			</div>
			<span style={{position: 'relative'}}>
				{postList.length > maxPerPage && (
					<input
						className='savedPostFilter'
						placeholder='Search'
						type='text'
						value={savedPostSearch}
						onChange={filterPosts}
					/>
				)}
				{savedPostSearch && (
					<svg
						onClick={removeSearch}
						className='removeSearch css-tj5bde-Svg'
						height='20'
						width='20'
						viewBox='0 0 20 20'>
						<path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
					</svg>
				)}
			</span>
			{postList.length > maxPerPage && (
				<Select
					id='typeDropdown'
					instanceId='types'
					value={savedPostFilter}
					onChange={(selectedOption) => filterType(selectedOption)}
					options={mappedTypes}
					styles={darkMode ? settingsStylesDark : settingsStyles}
					placeholder='Filter'
					isMulti
				/>
			)}
			{parent.hideOptions && (
				<Select
					id='userDropdown'
					instanceId='users'
					value={savedUserFilter}
					onChange={(selectedOption) => filterUsers(selectedOption)}
					options={mappedUsers}
					styles={darkMode ? settingsStylesDark : settingsStyles}
					placeholder='User'
					isMulti
				/>
			)}
			{parent.hideOptions && (
				<Select
					id='settingDropdown'
					instanceId='setting'
					value={settingFilter}
					onChange={(selectedOption) => filterSetting(selectedOption)}
					options={mappedSettings}
					styles={darkMode ? settingsStylesDark : settingsStyles}
					placeholder='Setting'
					isClearable
				/>
			)}
			{parent.hideOptions && settingFilter && (
				<Select
					id='settingValueDropdown'
					instanceId='settingValue'
					value={settingValueFilter}
					onChange={(selectedOption) => filterSettingValue(selectedOption)}
					options={mappedSettingValues}
					styles={darkMode ? settingsStylesDark : settingsStyles}
					placeholder='Setting Value'
					isClearable
				/>
			)}
			{!parent.hideOptions && folders.length > 0 && (
				<span className='folderDropdown'>
					<Select
						id='folderDropdown'
						instanceId='folders'
						value={savedFolderFilter}
						onChange={(selectedOption) => filterFolder(selectedOption)}
						options={mappedFolders}
						styles={darkMode ? settingsStylesDark : settingsStyles}
						placeholder='Folder'
						isClearable
					/>
					{savedFolderFilter && (
						<Menu
							menuButton={
								<MenuButton>
									<i className='fa-solid fa-ellipsis-vertical'></i>
								</MenuButton>
							}>
							<MenuItem
								onClick={() => {
									setRenameFolder(savedFolderFilter);
									toggleModal('folder');
								}}>
								Rename Folder
							</MenuItem>
							<MenuItem
								onClick={() => {
									if (filteredSavedPosts.length > 0) {
										toast.error('Folder must be empty before deletion', {autoClose: 3000});
									} else {
										axios.delete(`/folders/delete/${savedFolderFilter.value}`).then((response) => {
											if (response.status === 200) {
												const updatedFolders = folders.filter((folder) => folder._id !== savedFolderFilter.value);
												setFolders(updatedFolders);
												toast.success('Folder Deleted!', {autoClose: 1500});
												setSavedFolderFilter(null);
											}
										});
									}
								}}>
								Delete Folder
							</MenuItem>
						</Menu>
					)}
				</span>
			)}
			{parent.hideOptions && (
				<span className='numResults'>
					{filteredSavedPosts.length}/{postList.length}
				</span>
			)}
			{postList.length === 0 && <span className='postFilterResults'>No Saved Posts</span>}
			{postList.length > 0 && filteredSavedPosts.length === 0 && <span className='postFilterResults'>No Results Found</span>}
			{postList.length > 0 &&
				savedPostsSlice.map((item, i) => {
					return postButton(item, i);
				})}
			{pages.length > 1 && <span className='savedPostPages'>{pages}</span>}
		</div>
	);
}

export default function SavedPosts(props) {
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [pendingTitle, setPendingTitle] = useState('');
	const [pendingFolder, setPendingFolder] = useState(null);
	const { toggleModal } = useModalStore();
	const { settings } = useSettingsStore();
	const { sport, folders } = useUserStore();
	const { darkMode } = usePreferencesStore();
	const { newstitle, newssubtitle, newsteam, newsimage } = useWithImageStore();
	const { name, stat, title, subtitle, team, image, points, ranks, floatingTexts, nameOverrides, statOverrides, nameHighlights, statHighlights, description, teamsplits } = usePostStore();
	const { xLabel, yLabel } = useChartStore();
	const { leftName, leftImage, leftStats, rightName, rightImage, rightStats, leftTeam, rightTeam, statLabels } = useComparisonStore();
	const { round32, round16, round8, round4, round2, round1 } = useBracketStore();
	const { tiers } = useTierStore();
	const { savedPosts, setSavedPosts, teamOverrides, setCurrentPost } = useUserStore();
	const { draggables } = useGlobalStore();

	const savePost = () => {
		if (settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage') {
			axios
				.post(`/posts/savePost`, {
					email: props.user.email,
					user_id: props.user._id,
					sport: sport,
					newstitle,
					newssubtitle: newssubtitle,
					newsimage: newsimage,
					newsteam: newsteam,
					settings,
					teamoverrides: teamOverrides.filter((override) => !override.isGlobal),
					teamsplits,
					draggables,
					floatingTexts: floatingTexts,
					date: moment(),
					name: settings.type === 'lineupimage' ? name : [],
					stat: settings.type === 'lineupimage' ? stat : [],
					savedTitle: pendingTitle ?? null,
					folder: pendingFolder ? pendingFolder.value : null,
				})
				.then((response) => {
					if (response.status === 200) {
						toast.success('Post Saved!', {autoClose: 3000});
					}
					setSavedPosts([...savedPosts, response.data[0]]);
					setCurrentPost(response.data[0])
					setPendingTitle('');
					setPendingFolder(null);
					setPopoverOpen(false);
				});
		} else if (settings.type === 'comparison') {
			axios
				.post(`/posts/savePost`, {
					email: props.user.email,
					user_id: props.user._id,
					sport: sport,
					settings,
					teamoverrides: teamOverrides.filter((override) => !override.isGlobal),
					teamsplits,
					draggables,
					floatingTexts: floatingTexts,
					date: moment(),
					savedTitle: pendingTitle ?? null,
					title,
					subtitle,
					leftName: leftName,
					rightName: rightName,
					leftTeam: leftTeam,
					rightTeam: rightTeam,
					leftImage: leftImage,
					rightImage: rightImage,
					leftStats: leftStats,
					rightStats: rightStats,
					statLabels: statLabels,
					folder: pendingFolder ? pendingFolder.value : null,
				})
				.then((response) => {
					if (response.status === 200) {
						toast.success('Post Saved!', {autoClose: 3000});
					}
					setSavedPosts([...savedPosts, response.data[0]]);
					setCurrentPost(response.data[0])
					setPendingTitle('');
					setPendingFolder(null);
					setPopoverOpen(false);
				});
		} else if (settings.type === 'bracket') {
			axios
				.post(`/posts/savePost`, {
					email: props.user.email,
					user_id: props.user._id,
					title,
					subtitle,
					team: team,
					name: name,
					stat: stat,
					image: image,
					sport: sport,
					settings,
					round32: round32,
					round16: round16,
					round8: round8,
					round4: round4,
					round2: round2,
					round1: round1,
					teamoverrides: teamOverrides.filter((override) => !override.isGlobal),
					teamsplits,
					draggables,
					floatingTexts: floatingTexts,
					date: moment(),
					savedTitle: pendingTitle ?? null,
					folder: pendingFolder ? pendingFolder.value : null,
				})
				.then((response) => {
					if (response.status === 200) {
						toast.success('Post Saved!', {autoClose: 3000});
					}
					setSavedPosts([...savedPosts, response.data[0]]);
					setCurrentPost(response.data[0])
					setPendingTitle('');
					setPendingFolder(null);
					setPopoverOpen(false);
				});
		} else if (settings.type === 'tier') {
			axios
				.post(`/posts/savePost`, {
					email: props.user.email,
					user_id: props.user._id,
					title,
					subtitle,
					team: team,
					name: name,
					stat: stat,
					image: image,
					sport: sport,
					settings,
					tiers: tiers,
					teamoverrides: teamOverrides.filter((override) => !override.isGlobal),
					teamsplits,
					draggables,
					floatingTexts: floatingTexts,
					date: moment(),
					nameOverrides: nameOverrides,
					statOverrides: statOverrides,
					nameHighlights: nameHighlights,
					statHighlights: statHighlights,
					savedTitle: pendingTitle ?? null,
					folder: pendingFolder ? pendingFolder.value : null,
				})
				.then((response) => {
					if (response.status === 200) {
						toast.success('Post Saved!', {autoClose: 3000});
					}
					setSavedPosts([...savedPosts, response.data[0]]);
					setCurrentPost(response.data[0])
					setPendingTitle('');
					setPendingFolder(null);
					setPopoverOpen(false);
				});
		} else {
			let payload = {
				email: props.user.email,
				user_id: props.user._id,
				title,
				subtitle,
				team: team,
				name: name,
				stat: stat,
				points: points,
				image: image,
				sport: sport,
				settings,
				teamoverrides: teamOverrides.filter((override) => !override.isGlobal),
				teamsplits,
				draggables,
				floatingTexts: floatingTexts,
				nameOverrides: nameOverrides,
				statOverrides: statOverrides,
				nameHighlights: nameHighlights,
				statHighlights: statHighlights,
				date: moment(),
				savedTitle: pendingTitle ?? null,
				folder: pendingFolder ? pendingFolder.value : null,
			};
			if (settings.type.includes('image') && newsimage) {
				payload.newsimage = newsimage;
			}
			if (settings.type === 'scores' || settings.type.includes('post') || settings.type === 'matchupimage') {
				payload.ranks = ranks;
			}
			if (settings.type === 'matchupimage') {
				payload.description = description;
			}
			if (settings.type === 'chart') {
				payload.xLabel = xLabel;
				payload.yLabel = yLabel;
			}
			axios.post(`/posts/savePost`, payload).then((response) => {
				if (response.status === 200) {
					toast.success('Post Saved!', {autoClose: 3000});
				}
				setSavedPosts([...savedPosts, response.data[0]]);
				setCurrentPost(response.data[0])
				setPendingTitle('');
				setPendingFolder(null);
				setPopoverOpen(false);
			});
		}
	};

	const folderOptions = folders.map((folder) => {
		return {value: folder._id, label: folder.name};
	});
	folderOptions.unshift({value: null, label: 'None'});
	return (
		<div className='savedPosts'>
			<SavedPostsMap props={props} />
			{!props.hideOptions && (
				<button
					className='newButton'
					style={{marginTop: '8px', width: '-webkit-fill-available'}}
					onClick={() => toggleModal('folder')}>
					Create Folder
				</button>
			)}
			{!props.hideOptions && (
				<span
					className='relativeBtn'
					style={{height: '25px', marginTop: '6px'}}>
					<button
						className='newButton'
						onClick={savePost}>
						Save new post
					</button>
					<Popover
						isOpen={popoverOpen}
						reposition={true}
						containerStyle={{'z-index': '1000'}}
						onClickOutside={() => setPopoverOpen(false)}
						positions={['bottom', 'top', 'right', 'left']}
						content={
							<div className='popover-content' onClick={(e) => e.stopPropagation()}>
								<h5>Save As...</h5>
								<textarea
									type='text'
									rows='1'
									className='settingsInput'
									style={{height: '32px'}}
									value={pendingTitle}
									placeholder='Title'
									onChange={(e) => {
										setPendingTitle(e.target.value);
									}}
								/>
								{folders.length > 0 && (<span style={{display: 'block', marginTop: '5px', marginBottom: '20px'}}>
									<h5>Folder (optional)</h5>
									<Select
										value={pendingFolder}
										onChange={(selectedOption) => {
											setPendingFolder(selectedOption);
										}}
										options={folderOptions}
										isSearchable={false}
										styles={darkMode ? settingsStylesDark : settingsStyles}
									/>
								</span>)}
								<button
									className='newButton'
									onClick={savePost}>
									Save
								</button>
							</div>
						}>
						<button
							className='chevron'
							onClick={() => setPopoverOpen(!popoverOpen)}>
							<i className='fas fa-chevron-down no-click'></i>
						</button>
					</Popover>
				</span>
			)}
		</div>
	);
}
