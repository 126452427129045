export const autoPosts = [
	{
		sport: 'mlb',
		type: 'daily',
		id: 'mlb/daily/bat',
		specialEnd: 'end3',
		title: 'BATTING LEADERS',
		subtitle: 'subtitle14',
		size: 5,
	},
	{
		sport: 'mlb',
		type: 'daily',
		id: 'mlb/daily/pitch',
		specialEnd: 'end3',
		title: 'PITCHING LEADERS',
		subtitle: 'subtitle14',
		size: 5,
	},
	// {
	// 	sport: 'mlb',
	// 	type: 'daily',
	// 	id: 'mlb/fandaily/bat',
	// 	specialEnd: 'date3',
	// 	title: 'BATTING LEADERS',
	// 	subtitle: 'subtitle14',
	// 	size: 5,
	// },
	// {
	// 	sport: 'mlb',
	// 	type: 'daily',
	// 	id: 'mlb/fandaily/pitch',
	// 	specialEnd: 'date3',
	// 	title: 'PITCHING LEADERS',
	// 	subtitle: 'subtitle14',
	// 	size: 5,
	// },
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/hits',
		title: 'HITS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/runs',
		title: 'RUNS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/homeruns',
		title: 'HOME RUNS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/rbis',
		title: 'RBIs',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/avg',
		title: 'BATTING AVG',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/obp',
		title: 'OBP',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/slg',
		title: 'SLG',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/ops',
		title: 'OPS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/war',
		title: 'WAR',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/sb',
		title: 'STOLEN BASES',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'batting',
		id: 'mlb/season/bb',
		title: 'WALKS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'pitching',
		id: 'mlb/season/era',
		title: 'ERA',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'pitching',
		id: 'mlb/season/wins',
		title: 'WINS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'pitching',
		id: 'mlb/season/strikeouts',
		title: 'STRIKEOUTS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'pitching',
		id: 'mlb/season/whip',
		title: 'WHIP',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'pitching',
		id: 'mlb/season/warp',
		title: 'WAR',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'pitching',
		id: 'mlb/season/innings',
		title: 'Innings Pitched',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'reliever',
		id: 'mlb/season/relERA',
		title: 'RELIEVER ERA',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'reliever',
		id: 'mlb/season/saves',
		title: 'SAVES',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'reliever',
		id: 'mlb/season/holds',
		title: 'HOLDS',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'reliever',
		id: 'mlb/season/K9',
		title: 'K/9',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'reliever',
		id: 'mlb/season/relWHIP',
		title: 'RELIEVER WHIP',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'mlb',
		type: 'power',
		id: 'standings/mlb',
		title: 'STANDINGS',
		subtitle: 'subtitle0',
		size: 36,
	},
	{
		sport: 'mlb',
		type: 'power',
		id: 'mlb/power',
		specialEnd: 'week',
		title: 'POWER RANKINGS',
		button: 'POWER RANKINGS MLB.com',
		subtitle: 'subtitle19',
		size: 30,
	},
	{
		sport: 'mlb',
		type: 'power',
		id: 'cbspower/mlb',
		specialEnd: 'article',
		endOptional: true,
		title: 'POWER RANKINGS',
		button: 'POWER RANKINGS CBS',
		subtitle: 'subtitle17',
		size: 30,
	},
	{
		sport: 'mlb',
		type: 'power',
		id: 'espnpower/mlb',
		specialEnd: 'article',
		endOptional: true,
		title: 'POWER RANKINGS',
		button: 'POWER RANKINGS ESPN',
		subtitle: 'subtitle1',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'daily',
		id: 'nba/daily',
		specialEnd: 'end2',
		title: 'DAILY LEADERS',
		subtitle: 'subtitle14',
		size: 5,
	},
	// {
	// 	sport: 'nba',
	// 	type: 'daily',
	// 	id: 'nba/playoffs',
	// 	specialEnd: 'date',
	// 	title: 'DAILY LEADERS',
	// 	subtitle: 'subtitle14',
	// 	size: 5,
	// },
	{
		sport: 'nba',
		type: 'daily',
		id: 'nba/daily/0',
		specialEnd: 'end2',
		title: 'POINTS',
		subtitle: 'subtitle14',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'daily',
		id: 'nba/daily/3',
		specialEnd: 'end2',
		title: 'ASSISTS',
		subtitle: 'subtitle14',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'daily',
		id: 'nba/daily/6',
		specialEnd: 'end2',
		title: 'REBOUNDS',
		subtitle: 'subtitle14',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'daily',
		id: 'nba/daily/2',
		specialEnd: 'end2',
		title: 'STEALS',
		subtitle: 'subtitle14',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'daily',
		id: 'nba/daily/1',
		specialEnd: 'end2',
		title: 'BLOCKS',
		subtitle: 'subtitle14',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'daily',
		id: 'nba/daily/17',
		specialEnd: 'end2',
		title: '3-POINTERS MADE',
		subtitle: 'subtitle14',
		size: 10,
	},
	// {
	// 	sport: 'nba',
	// 	type: 'daily',
	// 	id: 'nba/playoffs/15',
	// 	specialEnd: 'date',
	// 	title: 'POINTS',
	// 	subtitle: 'subtitle14',
	// 	size: 10,
	// },
	// {
	// 	sport: 'nba',
	// 	type: 'daily',
	// 	id: 'nba/playoffs/11',
	// 	specialEnd: 'date',
	// 	title: 'ASSISTS',
	// 	subtitle: 'subtitle14',
	// 	size: 10,
	// },
	// {
	// 	sport: 'nba',
	// 	type: 'daily',
	// 	id: 'nba/playoffs/10',
	// 	specialEnd: 'date',
	// 	title: 'REBOUNDS',
	// 	subtitle: 'subtitle14',
	// 	size: 10,
	// },
	// {
	// 	sport: 'nba',
	// 	type: 'daily',
	// 	id: 'nba/playoffs/12',
	// 	specialEnd: 'date',
	// 	title: 'STEALS',
	// 	subtitle: 'subtitle14',
	// 	size: 10,
	// },
	// {
	// 	sport: 'nba',
	// 	type: 'daily',
	// 	id: 'nba/playoffs/13',
	// 	specialEnd: 'date',
	// 	title: 'BLOCKS',
	// 	subtitle: 'subtitle14',
	// 	size: 10,
	// },
	// {
	// 	sport: 'nba',
	// 	type: 'daily',
	// 	id: 'nba/playoffs/8',
	// 	specialEnd: 'date',
	// 	title: '3-POINTERS MADE',
	// 	subtitle: 'subtitle14',
	// 	size: 10,
	// },
	{
		sport: 'nba',
		type: 'traditional',
		id: 'nba/ptspergame',
		rookie: 'nba/30/Regular+Season/PerGame/Rookie',
		title: 'POINTS PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'traditional',
		id: 'nba/astpergame',
		rookie: 'nba/23/Regular+Season/PerGame/Rookie',
		title: 'ASSISTS PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'traditional',
		id: 'nba/rebpergame',
		rookie: 'nba/22/Regular+Season/PerGame/Rookie',
		title: 'REBOUNDS PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'traditional',
		id: 'nba/stlpergame',
		rookie: 'nba/25/Regular+Season/PerGame/Rookie',
		title: 'STEALS PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'traditional',
		id: 'nba/blkpergame',
		rookie: 'nba/26/Regular+Season/PerGame/Rookie',
		title: 'BLOCKS PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'additional',
		id: 'nba/3pmpergame',
		rookie: 'nba/14/Regular+Season/PerGame/Rookie',
		title: '3PM PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'additional',
		id: 'nba/ftmpergame',
		rookie: 'nba/17/Regular+Season/PerGame/Rookie',
		title: 'FTM PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'additional',
		id: 'nba/ftpercentage',
		rookie: 'nba/19/Regular+Season/PerGame/Rookie',
		title: 'FT% Leaders',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'additional',
		id: 'nba/topergame',
		rookie: 'nba/24/Regular+Season/PerGame/Rookie',
		title: 'TURNOVERS PER GAME',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'additional',
		id: 'nba/dbldbl',
		rookie: 'nba/33/Regular+Season/PerGame/Rookie',
		title: 'DOUBLE DOUBLES',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'additional',
		id: 'nba/trpdbl',
		rookie: 'nba/34/Regular+Season/PerGame/Rookie',
		title: 'TRIPLE DOUBLES',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nba',
		type: 'power',
		id: 'standings/nba',
		title: 'Standings',
		subtitle: 'subtitle0',
		size: 32,
	},
	{
		sport: 'nba',
		type: 'power',
		id: 'nba/power',
		specialEnd: 'week',
		title: 'POWER RANKINGS',
		button: 'POWER RANKINGS NBA.com',
		subtitle: 'subtitle2',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'power',
		id: 'cbspower/nba',
		specialEnd: 'article',
		endOptional: true,
		title: 'POWER RANKINGS',
		button: 'POWER RANKINGS CBS',
		subtitle: 'subtitle17',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'power',
		id: 'espnpower/nba',
		specialEnd: 'article',
		endOptional: true,
		title: 'POWER RANKINGS',
		button: 'POWER RANKINGS ESPN',
		subtitle: 'subtitle1',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/teampoints',
		title: 'POINTS PER GAME',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/teamthrees',
		title: '3PM PER GAME',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/teamrebounds',
		title: 'REBOUNDS PER GAME',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/teamassists',
		title: 'ASSISTS PER GAME',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/teamsteals',
		title: 'STEALS PER GAME',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/teamblocks',
		title: 'BLOCKS PER GAME',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/teamturnovers',
		title: 'TURNOVERS PER GAME',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'team',
		id: 'nba/differential',
		title: 'POINT DIFFERENTIAL',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'opponent',
		id: 'nba/opppoints',
		title: 'PTS PER GAME ALLOWED',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'opponent',
		id: 'nba/oppthrees',
		title: '3PM PER GAME ALLOWED',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'opponent',
		id: 'nba/opprebounds',
		title: 'REB PER GAME ALLOWED',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'opponent',
		id: 'nba/oppassists',
		title: 'AST PER GAME ALLOWED',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'opponent',
		id: 'nba/oppsteals',
		title: 'STL PER GAME ALLOWED',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'opponent',
		id: 'nba/oppblocks',
		title: 'BLK PER GAME ALLOWED',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'nba',
		type: 'other',
		id: 'nba/odds/MVP',
		title: 'MVP Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'nba',
		type: 'other',
		id: 'nba/odds/Rookie',
		title: 'ROTY Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'mlb',
		type: 'other',
		id: 'mlb/odds/AL%20MVP',
		title: 'AL MVP Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'mlb',
		type: 'other',
		id: 'mlb/odds/NL%20MVP',
		title: 'NL MVP Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'mlb',
		type: 'other',
		id: 'mlb/odds/AL%20Cy%20Young',
		title: 'AL Cy Young Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'mlb',
		type: 'other',
		id: 'mlb/odds/NL%20Cy%20Young',
		title: 'NL Cy Young Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'nba',
		type: 'other',
		id: 'draft/nba',
		specialEnd: 'year/article',
		title: 'NBA DRAFT',
		subtitle: 'subtitle15',
		size: 100,
	},
	{
		sport: 'ncaa',
		type: 'daily',
		id: 'ncaa/daily',
		specialEnd: 'date',
		title: 'Daily Leaders',
		subtitle: 'subtitle14',
		size: 5,
	},
	{
		sport: 'ncaa',
		type: 'daily',
		id: 'ncaa/weekly/pass',
		title: 'Passing Yards',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'ncaa',
		type: 'daily',
		id: 'ncaa/weekly/rush',
		title: 'Rushing Yards',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'ncaa',
		type: 'daily',
		id: 'ncaa/weekly/rec',
		title: 'Receiving Yards',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'ncaa',
		type: 'qbstats',
		id: 'ncaa/passyards',
		specialEnd: 'cfb',
		title: 'Passing Yards',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'qbstats',
		id: 'ncaa/passtd',
		specialEnd: 'cfb',
		title: 'Passing TDs',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'qbstats',
		id: 'ncaa/compperc',
		specialEnd: 'cfbWeek',
		title: 'Comp. Percentage',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'qbstats',
		id: 'ncaa/passrating',
		specialEnd: 'cfbWeek',
		title: 'Passer Rating',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'qbstats',
		id: 'ncaa/rushingyards/QB',
		specialEnd: 'cfb',
		title: 'Rushing Yards',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'qbstats',
		id: 'ncaa/rushingtds/QB',
		specialEnd: 'cfb',
		title: 'Rushing TDs',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'rbstats',
		id: 'ncaa/rushingyards/RB',
		specialEnd: 'cfb',
		title: 'Rushing Yards',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'rbstats',
		id: 'ncaa/rushingtds/RB',
		specialEnd: 'cfb',
		title: 'Rushing TDs',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'rbstats',
		id: 'ncaa/rushaverage/RB',
		specialEnd: 'cfbWeek',
		title: 'Yards per Attempt',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'rbstats',
		id: 'ncaa/receptions/RB',
		specialEnd: 'cfb',
		title: 'Receptions',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'rbstats',
		id: 'ncaa/receivingyards/RB',
		specialEnd: 'cfb',
		title: 'Receiving Yards',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'rbstats',
		id: 'ncaa/totaltd/RB',
		specialEnd: 'cfb',
		title: 'Total TDs',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'wrstats',
		id: 'ncaa/receptions/WR,ATH',
		specialEnd: 'cfb',
		title: 'Receptions',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'wrstats',
		id: 'ncaa/receivingyards/WR,ATH',
		specialEnd: 'cfb',
		title: 'Receiving Yards',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'wrstats',
		id: 'ncaa/receivingtds/WR,ATH',
		specialEnd: 'cfb',
		title: 'Receiving TDs',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'wrstats',
		id: 'ncaa/yardspercatch/WR,ATH',
		specialEnd: 'cfbWeek',
		title: 'Yards per Catch',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'testats',
		id: 'ncaa/receptions/TE',
		specialEnd: 'cfb',
		title: 'Receptions (TE)',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'testats',
		id: 'ncaa/receivingyards/TE',
		specialEnd: 'cfb',
		title: 'Receiving Yards (TE)',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'testats',
		id: 'ncaa/receivingtds/TE',
		specialEnd: 'cfb',
		title: 'Receiving TDs (TE)',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'testats',
		id: 'ncaa/yardspercatch/TE',
		specialEnd: 'cfbWeek',
		title: 'Yards per Catch (TE)',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'idpstats',
		id: 'ncaa/sacks',
		specialEnd: 'cfb',
		title: 'Sacks',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'idpstats',
		id: 'ncaa/tackles',
		specialEnd: 'cfb',
		title: 'Tackles',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'idpstats',
		id: 'ncaa/interceptions',
		specialEnd: 'cfb',
		title: 'Interceptions',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'idpstats',
		id: 'ncaa/pd',
		specialEnd: 'cfb',
		title: 'Passes Defended',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'otherstats',
		id: 'ncaa/intsthrown',
		specialEnd: 'cfb',
		title: 'Interceptions Thrown',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'otherstats',
		id: 'ncaa/sacked',
		specialEnd: 'cfb',
		title: 'Times Sacked',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/teamyards',
		title: 'Team Yards',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/teampass',
		title: 'Team Pass Yards',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/teamrush',
		title: 'Team Rush Yards',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/teampts',
		title: 'Team Points',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/oppyards',
		title: 'Yards Allowed',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/opppass',
		title: 'Pass Yards Allowed',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/opprush',
		title: 'Rush Yards Allowed',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamstats',
		id: 'ncaa/opppts',
		title: 'Points Allowed',
		subtitle: 'subtitle4',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'ballstats',
		id: 'ncaa/points',
		specialEnd: 'cbb',
		title: 'Pts per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'ballstats',
		id: 'ncaa/assists',
		specialEnd: 'cbb',
		title: 'Ast per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'ballstats',
		id: 'ncaa/rebounds',
		specialEnd: 'cbb',
		title: 'Reb per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'ballstats',
		id: 'ncaa/steals',
		specialEnd: 'cbb',
		title: 'Stl per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'ballstats',
		id: 'ncaa/blocks',
		specialEnd: 'cbb',
		title: 'Blk per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamballstats',
		id: 'ncaa/teamstats/offensive/avgPoints/4/0',
		title: 'Points per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamballstats',
		id: 'ncaa/teamstats/offensive/avgThreePointFieldGoalsMade/4/8',
		title: '3PM per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamballstats',
		id: 'ncaa/teamstats/offensive/avgAssists/4/13',
		title: 'Assists per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamballstats',
		id: 'ncaa/teamstats/general/avgRebounds/0/1',
		title: 'Rebounds per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamballstats',
		id: 'ncaa/teamstats/defensive/avgSteals/2/2',
		title: 'Steals per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamballstats',
		id: 'ncaa/teamstats/defensive/avgBlocks/2/3',
		title: 'Blocks per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'teamballstats',
		id: 'ncaa/teamstats/offensive/avgPoints/6/0',
		title: 'Differential',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'standings/mens-college-basketball/group',
		specialEnd: 'cbb',
		title: 'Standings',
		button: 'CBB Div. Standings',
		subtitle: 'subtitle0',
		size: 15,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'standings/college-football/group',
		specialEnd: 'cfb',
		title: 'Standings',
		button: 'CFB Div. Standings',
		subtitle: 'subtitle0',
		size: 20,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'ncaa/appoll/cfb',
		title: 'AP Poll',
		button: 'AP Football',
		subtitle: 'subtitle7',
		size: 25,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'ncaa/playoffrankings',
		title: 'CFB Playoff Rankings',
		button: 'Playoff Ranks',
		subtitle: 'subtitle14',
		size: 25,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'ncaa/all-rankings/first',
		title: 'Team Rankings',
		button: 'Team Rankings 1/2',
		subtitle: 'subtitle17',
		size: 68,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'ncaa/all-rankings/second',
		title: 'Team Rankings',
		button: 'Team Rankings 2/2',
		subtitle: 'subtitle17',
		size: 68,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'ncaa/appoll/cbb',
		title: 'AP Poll',
		button: 'AP Basketball',
		subtitle: 'subtitle14',
		size: 25,
	},
	{
		sport: 'ncaa',
		type: 'appoll',
		id: 'ncaa/heisman',
		title: 'Heisman Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	// {
	// 	sport: 'nhl',
	// 	type: 'daily',
	// 	id: 'nhl/playoffs/skaters',
	// 	specialEnd: 'date',
	// 	title: 'Top Skaters',
	// 	subtitle: 'subtitle14',
	// 	size: 5,
	// },
	// {
	// 	sport: 'nhl',
	// 	type: 'daily',
	// 	id: 'nhl/playoffs/G',
	// 	specialEnd: 'date',
	// 	title: 'Top Goalies',
	// 	subtitle: 'subtitle14',
	// 	size: 5,
	// },
	{
		sport: 'nhl',
		type: 'daily',
		id: 'nhl/daily/skater',
		specialEnd: 'end',
		title: 'Top Skaters',
		subtitle: 'subtitle14',
		size: 5,
	},
	{
		sport: 'nhl',
		type: 'daily',
		id: 'nhl/daily/goalie',
		specialEnd: 'end',
		title: 'Top Goalies',
		subtitle: 'subtitle14',
		size: 5,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/points',
		rookie: 'nhl/rookie/skater/points/desc',
		title: 'Points',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/goals',
		rookie: 'nhl/rookie/skater/goals/desc',
		title: 'Goals',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/assists',
		rookie: 'nhl/rookie/skater/assists/desc',
		title: 'Assists',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/plus',
		rookie: 'nhl/rookie/skater/plusMinus/desc',
		title: 'Plus-Minus',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/sog',
		rookie: 'nhl/rookie/skater/shots/desc',
		title: 'Shots',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/all/skater/pointsPerGame/desc',
		rookie: 'nhl/rookie/skater/pointsPerGame/desc',
		title: 'Points per game',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/ppg',
		title: 'Power-play Goals',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/ppa',
		title: 'Power-play Assists',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'offensive',
		id: 'nhl/gwg',
		title: 'Game-winning goals',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'goalie',
		id: 'nhl/gaa',
		rookie: 'nhl/rookie/goalie/goalsAgainstAverage/asc',
		title: 'Goals agst Avg',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'goalie',
		id: 'nhl/saves',
		rookie: 'nhl/rookie/goalie/savePct/desc',
		title: 'Save Percentage',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'goalie',
		id: 'nhl/wins',
		rookie: 'nhl/rookie/goalie/wins/desc',
		title: 'Wins',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'goalie',
		id: 'nhl/shutouts',
		rookie: 'nhl/rookie/goalie/shutouts/desc',
		title: 'Shutouts',
		subtitle: 'subtitle0',
		size: 10,
	},
	{
		sport: 'nhl',
		type: 'team',
		id: 'nhl/teamgoals',
		title: 'Goals For',
		subtitle: 'subtitle0',
		size: 32,
	},
	{
		sport: 'nhl',
		type: 'team',
		id: 'nhl/teamga',
		title: 'Goals Against',
		subtitle: 'subtitle0',
		size: 32,
	},
	{
		sport: 'nhl',
		type: 'team',
		id: 'nhl/teamsave',
		title: 'Save Percentage',
		subtitle: 'subtitle0',
		size: 32,
	},
	{
		sport: 'nhl',
		type: 'team',
		id: 'nhl/teampp',
		title: 'Team Power-play %',
		subtitle: 'subtitle0',
		size: 32,
	},
	{
		sport: 'nhl',
		type: 'team',
		id: 'nhl/teamshutouts',
		title: 'Team Shutouts',
		subtitle: 'subtitle0',
		size: 32,
	},
	{
		sport: 'nhl',
		type: 'power',
		id: 'standings/nhl',
		title: 'Standings',
		subtitle: 'subtitle0',
		size: 37,
	},
	{
		sport: 'nhl',
		type: 'power',
		id: 'espnpower/nhl',
		specialEnd: 'article',
		endOptional: true,
		title: 'Power Rankings',
		button: 'Power Rankings ESPN',
		subtitle: 'subtitle1',
		size: 32,
	},
	{
		sport: 'nhl',
		type: 'power',
		id: 'cbspower/nhl',
		specialEnd: 'article',
		endOptional: true,
		title: 'Power Rankings',
		button: 'Power Rankings CBS',
		subtitle: 'subtitle17',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/studs',
		specialEnd: 'week',
		title: 'Studs',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'slate',
		id: 'nfl/weekly/studs/TNF',
		specialEnd: 'week',
		title: 'Thursday Night',
		subtitle: 'subtitle27',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'slate',
		id: 'nfl/weekly/studs/Friday',
		specialEnd: 'week',
		title: 'Friday',
		subtitle: 'subtitle27',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'slate',
		id: 'nfl/weekly/studs/Saturday',
		specialEnd: 'week',
		title: 'Saturday',
		subtitle: 'subtitle27',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'slate',
		id: 'nfl/weekly/studs/Morning',
		specialEnd: 'week',
		title: 'Sunday Morning',
		subtitle: 'subtitle27',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'slate',
		id: 'nfl/weekly/studs/Afternoon',
		specialEnd: 'week',
		title: 'Saturday Afternoon',
		subtitle: 'subtitle27',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'slate',
		id: 'nfl/weekly/studs/SNF',
		specialEnd: 'week',
		title: 'Sunday Night',
		subtitle: 'subtitle27',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'slate',
		id: 'nfl/weekly/studs/MNF',
		specialEnd: 'week',
		title: 'Monday Night',
		subtitle: 'subtitle27',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/duds',
		specialEnd: 'week',
		title: 'Duds',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/waivers',
		specialEnd: 'week',
		title: 'Top Scorers',
		button: 'Top Waivers',
		subtitle: 'subtitle25',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/QB',
		specialEnd: 'week',
		title: 'Top Quarterbacks',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/RB',
		specialEnd: 'week',
		title: 'Top Running Backs',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/WR',
		specialEnd: 'week',
		title: 'Top Wide Receivers',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/TE',
		specialEnd: 'week',
		title: 'Top Tight Ends',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/DST',
		specialEnd: 'week',
		title: 'Top D/STs',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/K',
		specialEnd: 'week',
		title: 'Top Kickers',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/1',
		title: 'Passing Yards',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/2',
		title: 'Rushing Yards',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'fantasyleaders',
		id: 'nfl/weekly/3',
		title: 'Receiving Yards',
		subtitle: 'subtitle7',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/passingyards',
		title: 'Passing Yards',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/passingtds',
		title: 'Passing TDs',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/compperc',
		title: 'Comp. Percentage',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/passerrating',
		title: 'Passer Rating',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/passyardsperatt',
		title: 'Yards per Attempt',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/rushingyards/QB',
		title: 'Rushing Yards',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/rushingtds/QB',
		title: 'Rushing TDs',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/intsthrown',
		title: 'Interceptions',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/badrating',
		title: 'Lowest Passer Rating',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/sacked',
		title: 'Times Sacked',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'qbstats',
		id: 'nfl/sackyards',
		title: 'Sack Yards Lost',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'rbstats',
		id: 'nfl/rushingyards',
		specialEnd: 'positions',
		title: 'Rushing Yards',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'rbstats',
		id: 'nfl/rushingtds',
		specialEnd: 'positions',
		title: 'Rushing TDs',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'rbstats',
		id: 'nfl/rushingattempts',
		specialEnd: 'positions',
		title: 'Rushing Attempts',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'rbstats',
		id: 'nfl/rushingaverage',
		specialEnd: 'positions',
		title: 'Yards per Attempt',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'rbstats',
		id: 'nfl/yardsaftercontact',
		specialEnd: 'positions',
		title: 'Yards after Contact',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'rbstats',
		id: 'nfl/brokentackles/RB',
		title: 'Broken Tackles',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'rbstats',
		id: 'nfl/scrimmage',
		specialEnd: 'positions',
		title: 'Scrimmage Yards',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'recstats',
		id: 'nfl/receptions',
		specialEnd: 'positions',
		title: 'Receptions',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'recstats',
		id: 'nfl/receivingyards',
		specialEnd: 'positions',
		title: 'Receiving Yards',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'recstats',
		id: 'nfl/receivingtds',
		specialEnd: 'positions',
		title: 'Receiving TDs',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'recstats',
		id: 'nfl/targets',
		specialEnd: 'positions',
		title: 'Targets',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'recstats',
		id: 'nfl/yardsperreception',
		specialEnd: 'positions',
		title: 'Yards per Reception',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'recstats',
		id: 'nfl/bigrec',
		specialEnd: 'positions',
		title: '20+ Yard Plays',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'recstats',
		id: 'nfl/yardsaftercatch',
		specialEnd: 'positions',
		title: 'Yards After Catch',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/sacks',
		title: 'Sacks',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/tackles',
		title: 'Tackles',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/interceptions',
		title: 'Interceptions',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/tfl',
		title: 'Tackles for Loss',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/ff',
		title: 'Forced Fumbles',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/pd',
		title: 'Passes Defended',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/qbhits',
		title: 'QB Hits',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/qbpressures',
		title: 'QB Pressures',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'idpstats',
		id: 'nfl/ratingallowed',
		title: 'Pass Rating Allowed',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'otherstats',
		id: 'nfl/fumbles',
		title: 'Rushing Fumbles',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'otherstats',
		id: 'nfl/combtd',
		title: 'Total TDs',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'otherstats',
		id: 'nfl/kickyds',
		title: 'Kick Return Yards',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'otherstats',
		id: 'nfl/puntyds',
		title: 'Punt Return Yards',
		subtitle: 'subtitle5',
		size: 10,
		tikTokExpand: true,
	},
	{
		sport: 'nfl',
		type: 'otherstats',
		id: 'nfl/snapcount',
		title: 'RB Snap Counts',
		subtitle: 'subtitle5',
		size: 30,
	},
	{
		sport: 'nfl',
		type: 'otherstats',
		id: 'nfl/targetshare',
		title: 'Target Share',
		subtitle: 'subtitle5',
		size: 30,
	},
	{
		sport: 'nfl',
		type: 'otherstats',
		id: 'nfl/nextGenStats',
		title: 'Fastest Ball Carriers',
		subtitle: 'subtitle5',
		size: 10,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranks/QB',
		title: 'QB Rankings',
		button: 'QB Rankings NFL.com',
		subtitle: 'subtitle8',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflrankspros/PPR/QB',
		specialEnd: 'week',
		title: 'QB Rankings',
		button: 'QB Rankings FantasyPros',
		subtitle: 'subtitle10',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranksespn/ppr/QB',
		specialEnd: 'week',
		title: 'QB Rankings',
		button: 'QB Rankings ESPN',
		subtitle: 'subtitle1',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'combined',
		id: 'nfl/combinedranks/PPR/QB',
		specialEnd: 'week',
		title: 'QB Rankings',
		button: 'QB Rankings Combined',
		subtitle: 'subtitle7',
		size: 20,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranks/RB',
		title: 'RB Rankings',
		button: 'RB Rankings NFL.com',
		subtitle: 'subtitle9',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflrankspros/PPR/RB',
		specialEnd: 'week',
		title: 'RB Rankings',
		button: 'RB Rankings FantasyPros',
		subtitle: 'subtitle6',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranksespn/ppr/RB',
		specialEnd: 'week',
		title: 'RB Rankings',
		button: 'RB Rankings ESPN',
		subtitle: 'subtitle11',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'combined',
		id: 'nfl/combinedranks/PPR/RB',
		specialEnd: 'week',
		title: 'RB Rankings',
		button: 'RB Rankings Combined',
		subtitle: 'subtitle7',
		size: 20,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranks/WR',
		title: 'WR Rankings',
		button: 'WR Rankings NFL.com',
		subtitle: 'subtitle9',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflrankspros/PPR/WR',
		specialEnd: 'week',
		title: 'WR Rankings',
		button: 'WR Rankings FantasyPros',
		subtitle: 'subtitle6',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranksespn/ppr/WR',
		specialEnd: 'week',
		title: 'WR Rankings',
		button: 'WR Rankings ESPN',
		subtitle: 'subtitle11',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'combined',
		id: 'nfl/combinedranks/PPR/WR',
		specialEnd: 'week',
		title: 'WR Rankings',
		button: 'WR Rankings Combined',
		subtitle: 'subtitle7',
		size: 20,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranks/TE',
		title: 'TE Rankings',
		button: 'TE Rankings NFL.com',
		subtitle: 'subtitle9',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflrankspros/PPR/TE',
		specialEnd: 'week',
		title: 'TE Rankings',
		button: 'TE Rankings FantasyPros',
		subtitle: 'subtitle6',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranksespn/ppr/TE',
		specialEnd: 'week',
		title: 'TE Rankings',
		button: 'TE Rankings ESPN',
		subtitle: 'subtitle11',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'combined',
		id: 'nfl/combinedranks/PPR/TE',
		specialEnd: 'week',
		title: 'TE Rankings',
		button: 'TE Rankings Combined',
		subtitle: 'subtitle7',
		size: 20,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranks/DEF',
		title: 'D/ST Rankings',
		button: 'D/ST Rankings NFL.com',
		subtitle: 'subtitle8',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflrankspros/PPR/DST',
		specialEnd: 'week',
		title: 'D/ST Rankings',
		button: 'D/ST Rankings FantasyPros',
		subtitle: 'subtitle10',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranksespn/ppr/DEF',
		specialEnd: 'week',
		title: 'D/ST Rankings',
		button: 'D/ST Rankings ESPN',
		subtitle: 'subtitle1',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'combined',
		id: 'nfl/combinedranks/PPR/DEF',
		specialEnd: 'week',
		title: 'D/ST Rankings',
		button: 'D/ST Rankings Combined',
		subtitle: 'subtitle7',
		size: 20,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranks/K',
		title: 'K Rankings',
		button: 'K Rankings NFL.com',
		subtitle: 'subtitle8',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflrankspros/PPR/K',
		specialEnd: 'week',
		title: 'K Rankings',
		button: 'K Rankings FantasyPros',
		subtitle: 'subtitle10',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'pprranks',
		id: 'nfl/nflranksespn/ppr/K',
		specialEnd: 'week',
		title: 'K Rankings',
		button: 'K Rankings ESPN',
		subtitle: 'subtitle1',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'combined',
		id: 'nfl/combinedranks/PPR/K',
		specialEnd: 'week',
		title: 'K Rankings',
		button: 'K Rankings Combined',
		subtitle: 'subtitle7',
		size: 20,
	},
	{
		sport: 'nfl',
		type: 'stdranks',
		id: 'nfl/nflrankspros/STD/RB',
		specialEnd: 'week',
		title: 'RB Rankings',
		button: 'RB Rankings FantasyPros',
		subtitle: 'subtitle12',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'stdranks',
		id: 'nfl/nflranksespn/standard/RB',
		specialEnd: 'week',
		title: 'RB Rankings',
		button: 'RB Rankings ESPN',
		subtitle: 'subtitle13',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'stdranks',
		id: 'nfl/nflrankspros/STD/WR',
		specialEnd: 'week',
		title: 'WR Rankings',
		button: 'WR Rankings FantasyPros',
		subtitle: 'subtitle12',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'stdranks',
		id: 'nfl/nflranksespn/standard/WR',
		specialEnd: 'week',
		title: 'WR Rankings',
		button: 'WR Rankings ESPN',
		subtitle: 'subtitle13',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'stdranks',
		id: 'nfl/nflrankspros/STD/TE',
		specialEnd: 'week',
		title: 'TE Rankings',
		button: 'TE Rankings FantasyPros',
		subtitle: 'subtitle12',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'stdranks',
		id: 'nfl/nflranksespn/standard/TE',
		specialEnd: 'week',
		title: 'TE Rankings',
		button: 'TE Rankings ESPN',
		subtitle: 'subtitle13',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyseason',
		id: 'nfl/season/qb',
		title: 'QB Fantasy Points',
		subtitle: 'subtitle5',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyseason',
		id: 'nfl/season/rb',
		title: 'RB Fantasy Points',
		subtitle: 'subtitle5',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'fantasyseason',
		id: 'nfl/season/wr',
		title: 'WR Fantasy Points',
		subtitle: 'subtitle5',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'fantasyseason',
		id: 'nfl/season/te',
		title: 'TE Fantasy Points',
		subtitle: 'subtitle5',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyseason',
		id: 'nfl/season/dst',
		title: 'D/ST Fantasy Points',
		subtitle: 'subtitle5',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyseason',
		id: 'nfl/season/k',
		title: 'K Fantasy Points',
		subtitle: 'subtitle5',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyproj',
		id: 'nfl/projections/qb',
		title: 'QB Projected Pts',
		subtitle: 'subtitle7',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyproj',
		id: 'nfl/projections/rb',
		title: 'RB Projected Pts',
		subtitle: 'subtitle7',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'fantasyproj',
		id: 'nfl/projections/wr',
		title: 'WR Projected Pts',
		subtitle: 'subtitle7',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'fantasyproj',
		id: 'nfl/projections/flex',
		title: 'Flex Projected Pts',
		subtitle: 'subtitle7',
		size: 50,
	},
	{
		sport: 'nfl',
		type: 'fantasyproj',
		id: 'nfl/projections/te',
		title: 'TE Projected Pts',
		subtitle: 'subtitle7',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyproj',
		id: 'nfl/projections/dst',
		title: 'D/ST Projected Pts',
		subtitle: 'subtitle7',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'fantasyproj',
		id: 'nfl/projections/k',
		title: 'K Projected Pts',
		subtitle: 'subtitle7',
		size: 25,
	},
	{
		sport: 'nfl',
		type: 'powerranks',
		id: 'standings/nfl',
		title: 'Standings',
		subtitle: 'subtitle5',
		size: 40,
	},
	{
		sport: 'nfl',
		type: 'powerranks',
		id: 'nfl/playoffs',
		title: 'Playoff Picture',
		subtitle: 'subtitle5',
		size: 22,
	},
	{
		sport: 'nfl',
		type: 'powerranks',
		id: 'nfl/power',
		title: 'Power Rankings',
		button: 'Power Rankings NFL.com',
		subtitle: 'subtitle8',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'powerranks',
		id: 'cbspower/nfl',
		specialEnd: 'article',
		endOptional: true,
		title: 'Power Rankings',
		button: 'Power Rankings CBS',
		subtitle: 'subtitle17',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'powerranks',
		id: 'espnpower/nfl',
		specialEnd: 'article',
		endOptional: true,
		title: 'Power Rankings',
		button: 'Power Rankings ESPN',
		subtitle: 'subtitle1',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/teamyards',
		title: 'Team Yards',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/teampass',
		title: 'Team Pass Yards',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/teamrush',
		title: 'Team Rush Yards',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/teampts',
		title: 'Team Points',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/oppyards',
		title: 'Yards Allowed',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/opppass',
		title: 'Pass Yards Allowed',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/opprush',
		title: 'Rush Yards Allowed',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/opppts',
		title: 'Points Allowed',
		subtitle: 'subtitle4',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'teamstats',
		id: 'nfl/turnovers',
		title: 'Turnover Margin',
		subtitle: 'subtitle5',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/allowed/qb',
		title: 'Fantasy Pts Allowed',
		button: 'QB Pts Allowed',
		textsubtitle: 'To Opposing Quarterbacks',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/allowed/rb',
		title: 'Fantasy Pts Allowed',
		button: 'RB Pts Allowed',
		textsubtitle: 'To Opposing Running Backs',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/allowed/wr',
		title: 'Fantasy Pts Allowed',
		button: 'WR Pts Allowed',
		textsubtitle: 'To Opposing Wide Receivers',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/allowed/te',
		title: 'Fantasy Pts Allowed',
		button: 'TE Pts Allowed',
		textsubtitle: 'To Opposing Tight Ends',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/allowed/k',
		title: 'Fantasy Pts Allowed',
		button: 'K Pts Allowed',
		textsubtitle: 'To Opposing Kickers',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/allowed/dst',
		title: 'Fantasy Pts Allowed',
		button: 'D/ST Pts Allowed',
		textsubtitle: 'To Opposing D/STs',
		size: 32,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/weekly/perfect',
		specialEnd: 'week',
		title: 'Perfect Team',
		subtitle: 'subtitle7',
		size: 9,
	},
	{
		sport: 'nfl',
		type: 'gridstats',
		id: 'nfl/weekly/worst',
		specialEnd: 'week',
		title: 'Worst Team',
		subtitle: 'subtitle7',
		size: 9,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'nfl/waivers',
		title: 'Waiver Rankings',
		subtitle: 'subtitle10',
		size: 10,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'nfl/injuries',
		specialEnd: 'week',
		title: 'Injury Status',
		textsubtitle: 'Fantasy players owned over 5%',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'articles/pff',
		specialEnd: 'article',
		title: '',
		button: 'PFF Article',
		subtitle: 'subtitle20',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'articles/cbs',
		specialEnd: 'article',
		title: '',
		button: 'CBS Article',
		subtitle: 'subtitle17',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'articles/nfl',
		specialEnd: 'article',
		title: '',
		button: 'NFL Article',
		subtitle: 'subtitle8',
		size: 100,
	},
	{
		sport: 'ncaa',
		type: 'other',
		id: 'articles/random',
		specialEnd: 'article',
		title: 'Article',
		button: 'Article',
		subtitle: 'subtitle1',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'draft/nfl',
		specialEnd: 'year/article',
		title: 'NFL DRAFT',
		subtitle: 'subtitle15',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'draftbyteam/nfl',
		specialEnd: 'year/article',
		title: ' DRAFT PICKS',
		button: 'DRAFT BY TEAM',
		subtitle: 'subtitle3',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'draftbydiv',
		specialEnd: 'year/article',
		title: ' DRAFT PICKS',
		button: 'DRAFT BY DIVISION',
		subtitle: 'subtitle3',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'other',
		id: 'draft/available/nfl',
		title: 'BEST AVAILABLE',
		subtitle: 'subtitle1',
		size: 100,
	},
	{
		sport: 'nfl',
		type: 'odds',
		id: 'nfl/odds/MVP',
		title: 'MVP Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'odds',
		id: 'nfl/odds/Offensive%20Player',
		title: 'OPOY Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'odds',
		id: 'nfl/odds/Defensive%20Player',
		title: 'DPOY Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'odds',
		id: 'nfl/odds/Comeback%20Player',
		title: 'CPOY Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'odds',
		id: 'nfl/odds/Offensive%20Rookie',
		title: 'OROY Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'nfl',
		type: 'odds',
		id: 'nfl/odds/Defensive%20Rookie',
		title: 'DROY Odds',
		subtitle: 'subtitle24',
		size: 5,
	},
	{
		sport: 'mlb',
		type: 'team',
		id: 'mlb/teamhomeruns',
		title: 'TEAM HOME RUNS',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'mlb',
		type: 'team',
		id: 'mlb/teamavg',
		title: 'TEAM BATTING AVG',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'mlb',
		type: 'team',
		id: 'mlb/teamhits',
		title: 'TEAM HITS',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'mlb',
		type: 'team',
		id: 'mlb/teamruns',
		title: 'TEAM RUNS',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'mlb',
		type: 'team',
		id: 'mlb/teamera',
		title: 'TEAM ERA',
		subtitle: 'subtitle0',
		size: 30,
	},
	{
		sport: 'mlb',
		type: 'team',
		id: 'mlb/teamk',
		title: 'TEAM STRIKEOUTS',
		subtitle: 'subtitle0',
		size: 30,
	},
];
