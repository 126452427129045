import {useState} from 'react';
import useSettingsStore from '../stores/settings.store';
import useModalStore from '../stores/modal.store';
import useUserStore from '../stores/user.store';
import useGlobalStore from '../stores/global.store';
import useSidebarStore from '../stores/sidebar.store';

function SavedSettingsMap(props) {
	const parent = props.props;
	const {savedSettingPage, setSavedSettingPage} = useSidebarStore();
	const { allSettings } = useUserStore();

	const setPage = (e) => {
		setSavedSettingPage(Number(e.target.getAttribute('data-page-num')));
	};

	let pages = [];
	let pageHeight = document.querySelector('.DashboardContent').offsetHeight - 220;
	if (document.documentElement.clientWidth <= 950) {
		pageHeight-= 125;
	}
	let maxPerPage = Math.floor(pageHeight / 27);
	let totalPages = Math.ceil(allSettings.length / maxPerPage);
	for (var i = 1; i < totalPages + 1; i++) {
		pages.push(
			<span
				key={i}
				className={`${savedSettingPage === i ? 'page-selected' : 'page-not-selected'}`}
				data-page-num={i}
				onClick={setPage}>
				{i}
			</span>
		);
	}
	return (
		<div className='savedPostsLists'>
			{allSettings.length > 0 && (
				<SavedSettingButtons
					savedSettingPage={savedSettingPage}
					props={parent}
				/>
			)}
			{pages.length > 1 && <span className='savedPostPages'>{pages}</span>}
		</div>
	);
}

function SavedSettingButtons(props) {
	const { settings, replaceSettings } = useSettingsStore();
	const { toggleModal } = useModalStore();
	const { setSelectedSettings, selectedSettings, allSettings } = useUserStore();
	const { setPendingDelete, setExpanded } = useGlobalStore();

	const parent = props.props;
	const page = props.savedSettingPage;
	let pageHeight = document.querySelector('.DashboardContent').offsetHeight - 220;
	if (document.documentElement.clientWidth <= 950) {
		pageHeight-= 125;
	}
	let maxPerPage = Math.floor(pageHeight / 27);
	let savedSettingSlice = allSettings.slice(page * maxPerPage - maxPerPage, page * maxPerPage);

	const loadSettings = async (id) => {
		const foundSettings = allSettings.find((settings) => settings._id === id);
		foundSettings.settings.type = settings.type;
		replaceSettings(foundSettings.settings);
		setSelectedSettings(foundSettings._id);
		parent.resetImage(foundSettings.settings);
		if (document.documentElement.clientWidth <= 950) {
			setExpanded('');
		}
	};

	const openDeleteSettings = (e, item) => {
		e.stopPropagation();
		setPendingDelete({item});
		toggleModal('delete-settings');
	};

	return savedSettingSlice.map((item, i) => {
		return (
			<div
				key={i}
				className='savedpostdiv'>
				<button
					className={`${selectedSettings === item._id ? 'newButton' : 'newButtonSecondary'}`}
					id={item._id}
					onClick={() => {
						loadSettings(item._id);
					}}>
					{item.title ? item.title : 'Default Settings'}
				</button>
				<button
					className='deleteButton'
					onClick={(e) => {
						openDeleteSettings(e, item);
					}}>
					<i className='fa-solid fa-trash-can'></i>
				</button>
			</div>
		);
	});
}

export default function SavedSettings(props) {
	const { toggleModal } = useModalStore();
	const { teamOverrides } = useUserStore();

	return (
		<div className='savedPosts'>
			<SavedSettingsMap props={props} />
			<button
				className='newButton'
				onClick={props.resetSettings}>
				Reset Settings
			</button>
			<button
				className='newButton'
				onClick={() => toggleModal('quick-settings')}>
				Quick Settings
			</button>
			{teamOverrides.length > 0 && teamOverrides.filter((override) => override.isGlobal).length > 0 && (
				<button
					className='newButton'
					onClick={() => toggleModal('overrides')}>
					Manage Global Overrides
				</button>
			)}
			<button
				className='newButton'
				onClick={() => toggleModal('settings')}>
				Save New Settings
			</button>
		</div>
	);
}
