import {colord, extend} from 'colord';
import mixPlugin from 'colord/plugins/mix';
import useSettingsStore from '../stores/settings.store';
extend([mixPlugin]);

export default function BoxPreview(props) {
	const { settings } = useSettingsStore();
	const teamObj = props.teamObj;
	if (!teamObj || !settings) {
		return;
	}
	let height = 36;
	let nameFont = height / 30 + 'em';
	let imageWidth = height / 25 + 'em';
	// box background
	let boxbackground = teamObj[settings.teamBackground];
	let firstColor = teamObj.primary;
	let secondColor = teamObj.secondary;
	let mid = colord(firstColor).mix(secondColor).toHex();
	if (settings.teamBackground === 'gradient') {
		boxbackground = `linear-gradient(${teamObj.direction}, ${firstColor} ${teamObj.gradientPerc1}%, ${mid} ${teamObj.gradientPerc}%, ${secondColor} ${teamObj.gradientPerc2}%)`;
		if (settings.darkMode) {
			const darker = '#000000';
			let primaryColor;
			if (settings.darkMode === 'dark1') {
				primaryColor = teamObj.primary;
				boxbackground = `linear-gradient(${teamObj.direction}, ${darker} ${teamObj.gradientPerc1}%, ${primaryColor} ${teamObj.gradientPerc2}%)`;
			} else if (settings.darkMode === 'dark3') {
				primaryColor = colord(teamObj.secondary).brightness() > colord(teamObj.primary).brightness() ? teamObj.primary : teamObj.secondary;
				boxbackground = `linear-gradient(${teamObj.direction}, ${darker} ${teamObj.gradientPerc1}%, ${primaryColor} ${teamObj.gradientPerc2}%)`;
			} else if (settings.darkMode === 'dark2') {
				const blackMix = colord(teamObj.primary).mix('#000000', 0.7).toHex();
				boxbackground = `linear-gradient(${teamObj.direction}, ${teamObj.primary} ${teamObj.gradientPerc1}%, ${blackMix} ${teamObj.gradientPerc - 10}%, ${blackMix} ${teamObj.gradientPerc + 10}%, ${teamObj.primary} ${teamObj.gradientPerc2}%)`;
			} else if (settings.darkMode === 'dark4') {
				const blackMix = colord(teamObj.primary).mix('#000000', 0.7).toHex();
				boxbackground = `radial-gradient(circle, ${teamObj.primary} ${teamObj.gradientPerc1}%, ${blackMix} ${teamObj.gradientPerc2}%)`;
			}
		}
	} else if (settings.teamBackground === 'same') {
		boxbackground = settings.teamColor;
	}
	// box image background
	let boxImageBack = 'none';
	if (settings.boxImageBackground !== 'none') {
		if (settings.boxImageBackground === 'primaryDarker') {
			boxImageBack = colord(teamObj.primary).darken(0.1).toHex();
		} else {
			boxImageBack = colord(settings.boxImageBackground).isValid() ? settings.boxImageBackground : teamObj[settings.boxImageBackground];
		}
	}
	return (
		<div className='boxPreview'>
			<span className={`boxwrap`}>
				{/* aspect ratio?? */}
				<div
					style={{height, background: boxbackground, width: '400px'}}
					className={'box drag-container'}>
					<div className={`boxname`}>
						<span style={{fontSize: nameFont}}>{teamObj.abbreviation}</span>
					</div>
					{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
					{settings.logoOpacity !== 0 && (
						<div
							style={{background: boxImageBack}}
							className={settings.overlap ? `boximagecontainer movelogo postyimg` : 'boximagecontainer postyimg'}>
							<div
								className={`boximage`}
								style={{backgroundImage: teamObj.logo, minWidth: imageWidth, height: height}}></div>
						</div>
					)}
					{settings.duplicate && (
						<div className={`boximagecontainer2`}>
							{teamObj?.duplicate ? (
								<div
									className={`boximage`}
									style={{backgroundImage: teamObj?.duplicate, minWidth: imageWidth, height: height}}></div>
							) : (
								<div
									className={`boximage`}
									style={{backgroundImage: teamObj.logo, minWidth: imageWidth, height: height}}></div>
							)}
						</div>
					)}
				</div>
			</span>
		</div>
	);
}
