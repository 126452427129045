import Draggable from 'react-draggable';
import {Resizable} from 're-resizable';
import useSettingsStore from '../../stores/settings.store';
import usePostStore from '../../stores/posts/base-post.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';
import useBracketStore from '../../stores/posts/bracket.store';
import { colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
extend([mixPlugin]);

export default function BracketFormat(props) {
	const { settings } = useSettingsStore();
	const {
		team,
		name,
		stat,
		image,
		teamsplits
	} = usePostStore();
	const { individuallyEdit, setEditTeam, debug } = useGlobalStore();
	const { sport } = useUserStore();
	const { setRound } = useBracketStore();
	const bracketStore = useBracketStore();

	const advanceTeam = (e, i, round, total, side) => {
		if (individuallyEdit) {
			return;
		}
		// move transform from one playerimage to next
		try {
			if (round !== 'round1') {
				let currentRound = Number(round.replace('round', ''));
				let next = currentRound / 2;
				let nextRound = 'round' + next;
				let currentTeams = team;
				let currentNames = name;
				let currentStats = stat;
				let currentImages = image;
				let nextTeams = bracketStore[nextRound].team;
				let nextNames = bracketStore[nextRound].name;
				let nextStats = bracketStore[nextRound].stat;
				let nextImages = bracketStore[nextRound].image;
				if (currentRound !== total) {
					currentTeams = bracketStore[round].team;
					currentNames = bracketStore[round].name;
					currentStats = bracketStore[round].stat;
					currentImages = bracketStore[round].image;
				}
				while (nextTeams.length < next) {
					nextTeams.push('');
				}
				while (nextNames.length < next) {
					nextNames.push('');
				}
				while (nextStats.length < next) {
					nextStats.push('');
				}
				while (nextImages.length < next) {
					nextImages.push('');
				}
				let insert = Math.floor(i / 2);
				if (side === 'rightside' && insert === 1 && currentRound === 2) {
					insert = 0;
					i = 2;
				}
				if (currentTeams[i]) {
					nextTeams.splice(insert, 1, currentTeams[i]);
					nextNames.splice(insert, 1, currentNames[i]);
					nextStats.splice(insert, 1, currentStats[i]);
					nextImages.splice(insert, 1, currentImages[i]);
					if (next === 32) {
						setRound('round32', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 16) {
						setRound('round16', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 8) {
						setRound('round8', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 4) {
						setRound('round4', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 2) {
						setRound('round2', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					} else if (next === 1) {
						setRound('round1', {
							team: nextTeams,
							name: nextNames,
							stat: nextStats,
							image: nextImages
						});
					}
					const target = e.target;
					const parent = target.closest('.drag-container');
					const dragel = parent.querySelector('.react-draggable');
					if (dragel) {
						const transform = dragel.style.transform;
						const width = dragel.style.width;
						const height = dragel.style.height;
						setTimeout(() => {
							const newBox = document.querySelectorAll(`.round${next}span > #box${insert} > .playerImageContainer > .react-draggable`)[0];
							if (newBox) {
								newBox.style.transform = transform;
								newBox.style.width = width;
								newBox.style.height = height;
							}
						});
					}
				}
			}
		} catch (e) {
			if (debug) {
				console.log(e);
			}
		}
	};

	let rounds, total;
	if (team.length <= 4) {
		rounds = ['round4', 'round2', 'round1', 'round2', 'round4'];
		total = 4;
	} else if (team.length > 4 && team.length <= 8) {
		rounds = ['round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8'];
		total = 8;
	} else if (team.length > 8 && team.length <= 16) {
		rounds = ['round16', 'round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8', 'round16'];
		total = 16;
	} else if (team.length > 16 && team.length <= 32) {
		rounds = ['round32', 'round16', 'round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8', 'round16', 'round32'];
		total = 32;
	} else if (team.length > 32) {
		rounds = ['round64', 'round32', 'round16', 'round8', 'round4', 'round2', 'round1', 'round2', 'round4', 'round8', 'round16', 'round32', 'round64'];
		total = 64;
	}
	return rounds.map((round, j) => {
		let teams = team;
		let names = name;
		let stats = stat;
		let images = image;
		let n = Number(round.replace('round', ''));
		if (n !== total) {
			teams = bracketStore[round].team;
			names = bracketStore[round].name;
			stats = bracketStore[round].stat;
			images = bracketStore[round].image;
		}
		let indices = [];
		let idx = rounds.indexOf(round);
		while (idx !== -1) {
			indices.push(idx);
			idx = rounds.indexOf(round, idx + 1);
		}
		let side;
		let mapthis = [...teams];
		while (mapthis.length < n) {
			mapthis.push('');
		}
		const [firsthalf, secondhalf] = [mapthis.slice(0, n / 2), mapthis.slice(n / 2, total)];
		if (j === indices[0] && indices[1]) {
			side = 'leftside';
			mapthis = firsthalf;
		} else if (j === indices[1]) {
			side = 'rightside';
			mapthis = secondhalf;
		} else {
			side = 'middle';
		}
		return (
			<div
				key={j}
				className={`${round} ${side}`}>
				{mapthis.map((t, i) => {
					if (side === 'rightside') {
						i = i + n / 2;
					}
					let teamValues = props.getTeam(t);
					t = teamValues.team ? teamValues.team : null;
					let s = teamValues.sport ? teamValues.sport : sport;
					let team1 = teamValues.team1 ? teamValues.team1 : null;
					let team2 = teamValues.team2 ? teamValues.team2 : null;
					let sport1 = teamValues.sport1 ? teamValues.sport1 : null;
					let sport2 = teamValues.sport2 ? teamValues.sport2 : null;
					let imgsrc;
					if (images[i]) {
						imgsrc = props.getImg(images[i])[0];
					}
					let top = '';
					let bottom = '';
					if (names[i]) {
						top = '0px';
					}
					if (stats[i] === '' || !stats[i]) {
						bottom = '0px';
						if (names[i]) {
							top = '';
						}
					}
					let boximage, boxbackground, direction, teamObj;
					if (t) {
						teamObj = props.getTeamObj(t, s);
						direction = teamObj?.direction;
						if (teamObj) {
							boximage = (
								<div
									id={`image${i}`}
									className={`boximage`}
									style={{backgroundImage: teamObj?.logo}}></div>
							);
							boxbackground = props.getBoxBackground(teamObj, true, direction);
						} else {
							boxbackground = 'transparent';
						}
					}
					if (team1 && team2) {
						const teamObj1 = props.getTeamObj(team1, sport1);
						const teamObj2 = props.getTeamObj(team2, sport2);
						let existingSplit = teamsplits.find((split) => split.team === `${team1.toUpperCase()}/${team2.toUpperCase()}`);
						boximage = (
							<span className='boximagesplit'>
								<div
									className={`boximage ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[0] : 'cliptopleft'}`}
									style={{backgroundImage: teamObj1?.logo}}></div>
								<div
									className={`boximage ${existingSplit?.splitDirection ? existingSplit?.splitDirection.split('/')[1] : 'clipbottomright'}`}
									style={{backgroundImage: teamObj2?.logo}}></div>
							</span>
						);
						boxbackground = props.getTwoTeamBoxBackground(teamObj1, teamObj2, existingSplit);
					}
					const evenodd = i % 2 === 0 ? 'even' : 'odd';
					const boxBorderColor = props.findColor(settings.borderColor, teamObj);
					const boxGlowColor = props.findColor(settings.boxGlowColor, teamObj);
					return (
						<span
							key={'key' + j + i}
							className={`${round}span roundspan`}>
							<div
								id={`box${i}`}
								style={{
									background: boxbackground,
									borderColor: boxBorderColor,
									boxShadow: `0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}, inset 0px 0px ${settings.boxGlowWidth}px ${settings.boxGlowWidth / 5}px ${boxGlowColor}`
								}}
								className={`box ${evenodd} candrag${individuallyEdit} drag-container`}
								onClick={(e) => advanceTeam(e, i, round, total, side)}>
								{individuallyEdit && Number(round.replace('round', '')) === total && (
									<span
										className={`edit-box ${side}`}
										onClick={(e) => {
											e.stopPropagation();
											setEditTeam(i);
										}}>
										<i className='fas fa-pencil-alt no-click'></i>
									</span>
								)}
								<div className={`boximagecontainer`}>{boximage}</div>
								{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
								{names[i] && (
									<div
										id={`boxname${i}`}
										className={`boxname` + (settings.nameShadow === 0 ? ' no-shadow' : '')}
										onClick={(e) => {
											props.selectSettings('name');
										}}>
										<span id={`spanname${i}`} contentEditable={props.homePage} suppressContentEditableWarning={true} style={names[i] && names[i].includes('##') ? {whiteSpace: 'pre'} : {whiteSpace: 'nowrap'}}>
											{names[i].split('##').join('\n')}
										</span>
									</div>
								)}
								{stats[i] && (
									<div
										id={`boxstat${i}`}
										style={{minWidth: null}}
										className={`boxstat` + (settings.statShadow === 0 ? ' no-shadow' : '')}
										onClick={(e) => {
											props.selectSettings('stat');
										}}>
										<span contentEditable={props.homePage} suppressContentEditableWarning={true} style={names[i] && names[i].includes('##') ? {whiteSpace: 'pre'} : {whiteSpace: 'nowrap'}}>
											{stats[i].split('##').join('\n')}
										</span>
									</div>
								)}
								{imgsrc && (
									<div
										className='playerImageContainer'
										style={{top: top, bottom: bottom}}>
										<Draggable disabled={!individuallyEdit} onStop={() => props.setDraggables()}>
											<Resizable
												lockAspectRatio={true}
												enable={{
													top: individuallyEdit,
													right: individuallyEdit,
													bottom: individuallyEdit,
													left: individuallyEdit,
													topRight: individuallyEdit,
													bottomRight: individuallyEdit,
													bottomLeft: individuallyEdit,
													topLeft: individuallyEdit,
												}}>
												<img
													src={imgsrc}
													draggable='false'
													alt=''
													className='playerimage'></img>
											</Resizable>
										</Draggable>
									</div>
								)}
								<span className={`bracket-lines ${evenodd}`} style={{
									borderColor: boxBorderColor
								}}></span>
							</div>
						</span>
					);
				})}
			</div>
		);
	});
}
