export const quick1 = {
	direction: 'to left',
	headerSize: 20,
	gradientPerc: 50,
	nameSize: 19,
	statSize: 19,
	logoOrder: 0,
	logoOpacity: 100,
	logoSize: 45,
	headerMargin: 6,
	headerSpacing: 0,
	nameMargin: 3,
	headerFont: 'the bold one',
	nameFont: 'roboto bold',
	statFont: 'roboto condensed',
	headerColor: '#000000',
	headerFontColor: '#ffffff',
	watermark: '',
	watermarkFont: 'exo',
	watermarkSize: 28,
	watermarkWidth: 80,
	statMargin: 0,
	watermarkColor: '#ffffff',
	nameShadow: 0,
	borderColor: '#ffffff',
	nameSpacing: 0,
	statSpacing: -2,
	statShadow: 0,
	type: 'post',
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 100,
	headerSizeNews: 20,
	headerFontNews: 'roboto condensed',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 100,
	gradientPercNews: 50,
	directionNews: 'to bottom',
	nameColorNews: '#ffffff',
	logoSizeNews: 500,
	orientationNews: 'leftright',
	borderRadius: 25,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	duplicate: true,
	logoShadow: 0,
	nameMargin2: 10,
	postWidth: 680,
	postHeight: 680,
	postWidthNews: 680,
	postHeightNews: 680,
	tierFont: 'roboto condensed',
	tierColor: '#ffffff',
	tierSize: 75,
	duplicateLogoSize: 500,
	duplicateLogoPosition: 0,
	duplicateLogoOpacity: 10,
	orientation: 'leftright',
	orientationMatchup: 'topbottom',
	paneSplitMatchup: [80, 20],
	gridMargin: 1,
	logoFlip: true,
	scoreMinHeight: 100,
	videoDirection: 'fromright',
	videoReverse: false,
	headerOutlineColor: '#6a2424',
	nameOutlineColor: '#ffffff',
	statOutlineColor: '#ffffff',
	headerOutlineSize: 0,
	nameOutlineSize: 0,
	statOutlineSize: 0,
	headerTransform: 'none',
	nameTransform: 'none',
	statTransform: 'none',
	nameShadowColor: '#000000',
	statShadowColor: '#000000',
	gradientPerc1: 0,
	gradientPerc2: 100,
	gradientPerc1News: 0,
	gradientPerc2News: 100,
	tierTitle: 'left',
	pyramid: false,
	newsStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	postBackground: null,
	titleHeight: 10,
	subtitleFontSize: 10,
	subtitleFont: 'roboto condensed',
	subtitleFontColor: '#ffffff',
	subtitleMargin: -6,
	subtitleSpacing: 0,
	subtitleTransform: 'none',
	subtitleOutlineColor: '#ffffff',
	subtitleOutlineSize: 0,
	backgroundColor: 'gradient',
	backgroundGradient1: '#013369',
	backgroundGradient2: '#D50A0A',
	backgroundGradientPerc: 0,
	backgroundGradientPerc1: 50,
	backgroundGradientPerc2: 100,
	backgroundGradientDirection: 'to bottom',
	postColumns: null,
	gridColumns: null,
	tierSplit: 8,
	fivelessBreak: 8,
	helmets: false,
	rankSize: 43,
	rankFont: 'jersey',
	rankFontColor: '#ffffff',
	rankMargin: -3,
	rankWidth: 43,
	rankSpacing: 0,
	rankOutlineSize: 0,
	rankOutlineColor: '#000000',
	logoSizeGrid: 100,
	logoSizeTier: 100,
	pointsSize: 20,
	pointsFont: 'roboto condensed',
	pointsFontColor: '#ffffff',
	pointsMargin: 0,
	pointsSpacing: -1.2,
	pointsOutlineSize: 0,
	pointsOutlineColor: '#000000',
	pointsBackground: '#000000',
	playerHeight: 1,
	statLineHeight: 1,
	indexAxis: 'y',
	chartType: {
		value: 'bar',
		label: 'Bar Chart',
	},
	labelSize: 14,
	labelColor: '#ffffff',
	labelFont: 'roboto condensed',
	chartBorderColor: '#ffffff',
	chartBorderWidth: 0.5,
	chartLogoSize: 75,
	playerMargin: 0,
	lighterLogos: false,
	texture: 'grainy',
	textureSize: 5,
	textureOpacity: 19,
	rankBackground: 'transparent',
	boxMargin: 15,
	teamBackground: 'primary',
	teamColor: '#1590f7',
	boxRankOutlineSize: 0,
};
